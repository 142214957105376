import React from "react";
import logo from "./light.svg";

interface Props {
  width?: number | string;
}
export function DWSLogotypeLight({ width = "100%" }: Props) {
  return (
    <img
      src={logo}
      alt="digital wholesale solutions"
      width={width}
      style={{ maxWidth: "100px", marginBottom: "32px" }}
    />
  );
}
