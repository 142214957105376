import React, { useEffect, useState, useMemo, memo } from "react";
import {
  debounce,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  menuItemClasses,
  paperClasses,
  Select,
  selectClasses,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { SxStyleSheet } from "../themes/_base";

export interface Option {
  id: number | string;
  value?: boolean | string | number | string[];
  label?: string;
  disabled?: boolean;
}

interface Props {
  data: Option[];
  ctrClass?: any;
  label?: string;
  name?: string;
  onChange?: any;
  value?: Option["value"];
  defaultValue?: Option["value"];
  multiple?: boolean;
  helperText?: string;
  error?: boolean;
  hasSearch?: boolean;
  searchList?: Option[];
  searchDebounce?: number;
  multiline?: boolean;
  hasNextPage?: boolean;
  infiniteScroll?: boolean;
  nextPage?: any;
  searchOn?: any;
}

const SelectDropdown = memo(
  ({
    ctrClass,
    label,
    name,
    data,
    onChange = () => null,
    value,
    defaultValue,
    multiple,
    error,
    helperText,
    multiline = false,
    hasSearch = false,
    infiniteScroll = false,
    hasNextPage,
    nextPage = () => null,
    searchOn = () => null,
    searchList,
    searchDebounce = 300,
    ...props
  }: Props) => {
    const theme = useTheme();
    const styles = getStyles(theme);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchFunc = useMemo(() => debounce(searchOn, searchDebounce), []);
    const fetchNextPage = useMemo(() => debounce(nextPage, 50), [nextPage]);
    const [search, setSearch] = useState("");
    const [options, setOptions] = React.useState<any>([]);

    useEffect(() => {
      if (Boolean(search) && searchList && searchList?.length > 0) {
        setOptions(searchList);
      } else {
        setOptions(data);
      }
    }, [data, searchList, search]);

    useEffect(() => {
      searchFunc(search);
    }, [search, searchFunc]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setSearch(val);
    };

    const onScroll = (e: any) => {
      const buffer = 15;
      const scrolled = e.target.scrollHeight - e.target.scrollTop;
      const total = e.target.clientHeight + buffer;
      const hitBottom = scrolled <= total;
      if (hitBottom && hasNextPage) {
        fetchNextPage();
      }
    };

    const renderOption = (item: any) => {
      return (
        <MenuItem
          value={item?.value}
          key={item?.id}
          sx={styles.listValue}
          disabled={item?.disabled}
        >
          {`${item?.label}`}
        </MenuItem>
      );
    };
    return (
      <FormControl
        fullWidth
        size="small"
        sx={multiline ? { ...styles.multiline, ...ctrClass } : ctrClass}
        error={error}
        {...props}
      >
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          variant="standard"
          labelId={name}
          id={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onClose={() => setSearch("")}
          label={label}
          name={name}
          multiple={multiple && data?.length > 1}
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "left",
            },
            disablePortal: false,
            // getContentAnchorEl: null,
            sx: styles.body,
            autoFocus: false,
            MenuListProps: {
              onScroll: infiniteScroll ? onScroll : undefined,
              onFocus: (e: React.FocusEvent<HTMLUListElement>) => {
                if (!hasNextPage) return;
                const el = e.currentTarget;
                if (el?.nodeName === "UL") {
                  el.style.height = el.clientHeight - 2 + "px";
                }
              },
            },
          }}
        >
          {hasSearch && (
            <TextField
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Search..."
              data-cy="search_component"
              size="small"
              autoFocus
              fullWidth
              value="search"
              onChange={handleSearch}
              sx={styles.searchItem}
            />
          )}
          {options?.map((item: any) => {
            return renderOption(item);
          })}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

export default SelectDropdown;

const getStyles = (theme: Theme): SxStyleSheet => ({
  body: {
    // "& > .MuiPaper-root ul": { // todo mui
    [`& .${paperClasses.root} ul`]: {
      maxHeight: 500,
      overflowY: "auto",
      paddingTop: "0px",
    },
  },
  listValue: {
    // "&.MuiListItem-root.Mui-selected": {  // todo mui
    // [`& .${listItemClasses.root}.${listItemClasses.selected}`]: {
    [`& .${menuItemClasses.root}.${menuItemClasses.selected}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  multiline: {
    // "& .MuiSelect-selectMenu": { // todo mui
    [`& .${selectClasses.select}`]: {
      height: "1.1876em",
    },
  },
  searchItem: {
    position: "sticky",
    top: "0px",
    backgroundColor: "white!important",
    zIndex: 100,
    padding: "8px",
    "&:hover": {
      backgroundColor: "white!important",
    },
  },
});
