import React from "react";

import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { Asset, PACSTAC } from "../../../../../types";
import {
  useRequestPac,
  useCancelDisconnection,
} from "../../../../../hooks/assets";
import { formattedDate } from "../../../../../helpers/functions";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  asset: Asset;
  code: PACSTAC;
  type?: "pac" | "stac";
};

const Component = ({ asset, code, type = "pac" }: Props) => {
  const classes = useStyles();
  const mutation = useCancelDisconnection(asset);
  const requestPac = useRequestPac(asset);

  const hasEtfError =
    code.error === "Error getting vfd etf info for cli " + code.mnp_id;

  return (
    <Paper className={classes.paper} elevation={4}>
      <Box p={1.5}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs>
            <Typography variant="h4">
              {type?.toUpperCase()} Code Requested
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography paragraph={true} variant="caption">
              {type?.toUpperCase()} Code
            </Typography>
            <Typography variant="h4">{code.pac || "-"}</Typography>
          </Grid>
          <Grid item xs>
            <Typography paragraph={true} variant="caption">
              Expiry date
            </Typography>
            <Typography variant="h4">
              {formattedDate(code.pac_expiry_date ?? code.expiry_date)}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography paragraph={true} variant="caption">
              Early Termination Fees
            </Typography>
            <Typography variant="h4">
              {!hasEtfError ? (
                `£${code.etf}`
              ) : (
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">N/A</Typography>

                  <Button
                    color="primary"
                    onClick={(event) => {
                      requestPac.mutate({});
                    }}
                    size="small"
                    variant="text"
                    disabled={requestPac.isLoading}
                  >
                    {requestPac.isLoading ? "Retrying.." : "Retry"}
                  </Button>
                </Box>
              )}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography paragraph={true} variant="caption">
              Port Out Date
            </Typography>
            <Typography variant="h4">
              {formattedDate(code.port_out_date ?? code.port_date) ?? "N/A"}
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display="flex" flexDirection="row-reverse">
              <Button
                color="primary"
                disabled={mutation.isLoading}
                onClick={() => mutation.mutate({ id: code.request_id })}
                startIcon={
                  mutation.isLoading ? <CircularProgress size={12} /> : null
                }
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Component;
