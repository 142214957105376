import React, { useState } from "react";
import { postcodeRegEx } from "../../organisms/AddressPicker/helpers/isValidPostcode";
import { Box, Button, TextField } from "@mui/material";
import { ChooseAddressDialog } from "../../organisms/AddressPicker/ChooseAddressDialog";
import { Controller, useFormContext, UseFormSetValue } from "react-hook-form";
import {
  FetchWLR3AddressSearch,
  WLR3Address,
} from "../../organisms/AddressPicker/types";

/**
 * React Hook Form wrapper for DC Postcode Lookup
 */
interface RHFPostcodeLookupProps {
  name: string;
  required?: boolean;
  /* Required for DC API call in some scenarios */
  accountId?: string;
  api: FetchWLR3AddressSearch;
  /* Callback on address selection. Use to populate the rest of the form */
  onSelect: (address: WLR3Address, setValue: UseFormSetValue<any>) => void;
  isVfDirect?: boolean;
}

export const RHFPostcodeLookup = ({
  name,
  required,
  accountId,
  api,
  onSelect,
  isVfDirect,
}: RHFPostcodeLookupProps) => {
  const { setValue } = useFormContext();
  const [open, setOpen] = useState(false);

  return (
    <Controller
      name={name}
      rules={{
        required,
        pattern: {
          value: postcodeRegEx,
          message: "Invalid postcode",
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <>
            <Box display="flex" justifyContent="space-between">
              <TextField
                variant="standard"
                label="Postcode"
                {...field}
                helperText={
                  fieldState.error && (fieldState.error.message || "Required")
                }
                error={!!fieldState.error}
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                Choose Address
              </Button>
            </Box>
            <ChooseAddressDialog
              open={open}
              onClose={() => setOpen(false)}
              postcode={field.value}
              setAddress={(wlr3Address) => onSelect(wlr3Address, setValue)}
              api={api}
              accountId={accountId}
              isVfDirect={isVfDirect}
            />
          </>
        );
      }}
    />
  );
};
