import { AccountSettings } from "@akj-dev/affinity-platform";
import { useFormContext } from "react-hook-form";

/**
 * Determine if the current user is a VF Direct Consumer account
 */

export function useIsVfDirectConsumerAccount(accountSettings: AccountSettings) {
  const { watch } = useFormContext();
  const business_entity_type = watch("business_entity_type");
  return (
    accountSettings.can_access_vf_direct === "1" &&
    business_entity_type === "Sole Trader / Owner Manager"
  );
}
