import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClickPopover } from "../../ClickPopover";

const useStyles = makeStyles((theme: Theme) => ({
  filterTitle: {
    textTransform: "uppercase",
  },
}));

interface ContentProps {
  children: React.ReactNode;
  label: string;
  onApply: () => void;
  onClose?: () => void;
  open?: boolean;
}

function Content({ children, label, onApply, onClose, open }: ContentProps) {
  const classes = useStyles();

  if (!open) {
    return <></>;
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        onApply();
        if (typeof onClose === "function") {
          onClose();
        }
      }}
    >
      <Box p={1} style={{ width: 280 }}>
        <Box className={classes.filterTitle} mb={1}>
          Filter by {label}
        </Box>
        <Box mb={1}>{children}</Box>
        <Box display="flex">
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                onApply();
                if (typeof onClose === "function") {
                  onClose();
                }
              }}
              color="primary"
              size="small"
            >
              Apply
            </Button>
          </Box>
          <Box>
            <Button onClick={onClose} color="primary" size="small">
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

interface WrapProps {
  children: React.ReactNode;
  label: string;
  onApply: () => void;
}

export default function Wrap({ children, label, onApply }: WrapProps) {
  return (
    <Box mr={0.5}>
      <ClickPopover
        button={{
          size: "small",
          endIcon: <ExpandMoreIcon />,
          variant: "text",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        trigger={label}
      >
        <Content label={label} onApply={onApply}>
          {children}
        </Content>
      </ClickPopover>
    </Box>
  );
}
