import queryString from "query-string";
import {
  affinityTheme,
  vodaTheme,
  daisyTheme,
  dwpTheme,
  affinityFavicon,
  vodafoneFavicon,
  dwpFavicon,
  daisyFavicon,
} from "../../index";

export enum Brand {
  AFFINITY = "AFFINITY",
  VODAFONE = "VODAFONE",
  DAISY = "DAISY",
  DWS = "DWS",
}

export const parseBrandFromUrl = () => {
  const search = queryString.parse(window.location.search);

  if (typeof search.brand === "string") {
    const override = search.brand.toUpperCase();

    switch (override) {
      case "VODAFONE":
        return Brand.VODAFONE;
      case "DWS":
        return Brand.DWS;
      case "DAISY":
        return Brand.DAISY;
      case "AFFINITY":
      default:
        return Brand.AFFINITY;
    }
  }

  if (window.location.hostname.includes("vodafone")) {
    return Brand.VODAFONE;
  }

  if (
    window.location.hostname.includes("dwp") ||
    window.location.hostname.includes("digitalwholesalesolutions")
  ) {
    return Brand.DWS;
  }

  return Brand.AFFINITY;
};

const getThemeForBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return vodaTheme;
    case Brand.DAISY:
      return daisyTheme;
    case Brand.DWS:
      return dwpTheme;
    case Brand.AFFINITY:
    default:
      return affinityTheme;
  }
};

export const getTheme = () => getThemeForBrand(parseBrandFromUrl());

type BrandMeta = {
  name: string;
  documentTitle: string;
};
const getMetaForBrand: (brand: Brand) => BrandMeta = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return {
        name: "Cloud Market for Vodafone Business",
        documentTitle: "Vodafone",
      };
    case Brand.DAISY:
      return { name: "Daisy", documentTitle: "Daisy" };
    case Brand.DWS:
      return { name: "DWP Beta", documentTitle: "DWP" };
    case Brand.AFFINITY:
    default:
      return { name: "Affinity", documentTitle: "Affinity" };
  }
};

export const getBrandMeta = () => getMetaForBrand(parseBrandFromUrl());

const getFaviconForBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return vodafoneFavicon;
    case Brand.DAISY:
      return daisyFavicon;
    case Brand.DWS:
      return dwpFavicon;
    case Brand.AFFINITY:
    default:
      return affinityFavicon;
  }
};

export const getFavicon = () => getFaviconForBrand(parseBrandFromUrl());
