import { Service } from "../types";

export function findProductModification(
  service: Service,
  changes: [],
  type?: string
): Service | undefined {
  return changes.find(
    (change: any) =>
      change.type === (type || "change") &&
      change.category === service.category &&
      change.description === service.description
  );
}
