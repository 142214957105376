import React from "react";
import { UseMutationResult } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@akj-dev/shared-components";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  closeDialogOnConfirm: () => void;
  local: any;
  changes_are_locked: boolean;
  addMutation: UseMutationResult<any, unknown, any, unknown>;
  changeMutation: UseMutationResult<any, unknown, any, unknown>;
  onConfirmPress: () => void;
}

const ChangeTariffWarning = ({
  isDialogOpen,
  closeDialog,
  closeDialogOnConfirm,
  local,
  changes_are_locked,
  addMutation,
  changeMutation,
  onConfirmPress,
}: Props) => {
  return (
    <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogContent>
        <Box pt={1}>
          <Alert
            type="info"
            message=" If you change your mobile product, any existing bolt ons will be removed and should be re-added if required."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={addMutation.isLoading || changeMutation.isLoading}
          onClick={() => closeDialog()}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!local.product || changes_are_locked}
          startIcon={
            addMutation.isLoading || changeMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <DoneIcon />
            )
          }
          onClick={() => {
            onConfirmPress();
            closeDialogOnConfirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeTariffWarning;
