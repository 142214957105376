import React from "react";
import { useParams } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  Box,
  CircularProgress,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import MuiTableRow from "@mui/material/TableRow";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useThirdPartyBilling } from "../hooks/customers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    row: {
      verticalAlign: "middle",
    },
  })
);

const TableRow = withStyles({
  root: {
    "&:last-child td": {
      borderBottom: 0,
    },
  },
})(MuiTableRow);

function createData(
  name: string,
  label: string,
  data: string | null,
  helpText?: string
) {
  return { name, label, data, helpText };
}

const rows = (creditCheck: any) => {
  return [
    createData(
      "dateAndTime",
      "Date and Time",
      creditCheck?.credit_check_date_entered ?? "N/A"
    ),
    createData(
      "creditCheckResult",
      "Credit Check Result",
      creditCheck?.as_credit_check_result ?? "N/A"
    ),
    createData(
      "noOfConnectionsRequested",
      "No. of Connections Requested",
      creditCheck?.connections_requested ?? "N/A",
      "This is the total number of connections that have been requested for this Customer."
    ),
    createData(
      "allowedConnections",
      "Remaining Allowed Connections",
      creditCheck?.as_credit_check_volume ?? "N/A",
      "This is the number of remaining connections that can be requested for this customer. This is calculated against the current number of existing connections and connection requests."
    ),
  ];
};

export default function CreditCheck() {
  const classes = useStyles();
  const params: any = useParams();
  const accountId = params.accountId;

  const { isLoading, data } = useThirdPartyBilling(accountId);

  if (!isLoading) {
    return (
      <Box mt={2}>
        <Paper elevation={4} className={classes.paper}>
          <Table>
            <TableBody>
              {rows(data?.data).map((row) => {
                return (
                  <TableRow key={row.name} className={classes.row}>
                    <TableCell>
                      <Box display="flex">
                        {row.label}
                        {row.helpText && (
                          <Box pl={0.5} display="flex" alignItems="center">
                            <Tooltip
                              key={row.label}
                              title={row.helpText}
                              placement="top"
                              arrow
                            >
                              <HelpOutlineIcon fontSize="small" />
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <InputBase value={row.data} disabled />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    );
  } else {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress size={50} />
      </Box>
    );
  }
}
