import React from "react";
import { Grid, Typography } from "@mui/material";
import { Asset } from "../../../../../../types";
import { formattedDate } from "../../../../../../helpers/functions";

type Props = {
  asset: Asset;
};

const Component = ({ asset }: Props) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid container item columns={2} columnSpacing={5}>
        <Grid item>
          <Typography variant="body2" width="150px">
            Contract Signed Date
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {formattedDate(asset.start_date_ymd)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item columns={2} columnSpacing={5}>
        <Grid item>
          <Typography variant="body2" width="150px">
            Contract Term
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {asset.contract_length_in_months} Month
            {asset.contract_length_in_months > 1 ? "s" : ""}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item columns={2} columnSpacing={5}>
        <Grid item>
          <Typography variant="body2" width="150px">
            Contract End Date
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {formattedDate(asset.end_date_ymd)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item columns={2} columnSpacing={5}>
        <Grid item>
          <Typography variant="body2" width="150px">
            Disconnection Date
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {asset.product_ceased_date || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Component;
