import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemText,
  MenuList,
  Paper,
  Popper,
  styled,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaveIcon from "@mui/icons-material/Save";
import FiltersDialog from "./FiltersDialog";
import { isEmpty } from "./utils";
import { UsersFilter } from "./types";

interface Props {
  fetchHook?: (usage: string) => any;
  deleteHook?: (filter: UsersFilter) => any;
  saveHook?: (name: string) => any;
  addHook?: (filter: UsersFilter) => any;
  usage?: string;
}

const MyFilters = ({
  fetchHook = (usage: string) => null,
  deleteHook = (filter: UsersFilter) => null,
  saveHook = (name: string) => null,
  addHook = (filter: UsersFilter) => null,
  usage = "",
}: Props) => {
  const theme = useTheme();
  const usersFilters = fetchHook(usage);

  const [showSaveDialog, setSaveDialogVisible] = useState(false);
  const [showDeleteDialog, setDeleteDialogVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<UsersFilter>();
  const [filterName, setFilterName] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleCreate = () => {
    if (isEmpty(filterName)) {
      setValidationError("Required field");
    } else {
      saveHook(filterName);
      setSaveDialogVisible(false);
      setFilterName("");
    }
  };

  const handleDelete = () => {
    if (selectedFilter) {
      deleteHook(selectedFilter);
    }
    setDeleteDialogVisible(false);
  };

  const handleCancel = () => {
    setSaveDialogVisible(false);
    setDeleteDialogVisible(false);
    setValidationError("");
    setFilterName("");
  };

  const deleteFilter = (filter: UsersFilter) => {
    setDeleteDialogVisible(true);
    setSelectedFilter(filter);
  };

  const addFilter = (filter: UsersFilter) => {
    addHook(filter);
    setOpen(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFilterName(val);
  };

  const onKeyPressed = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleCreate();
    }
  };

  const myFilterDataList = [
    <List id="users-filters-menu" data-cy="my-filters-items">
      {usersFilters?.data?.length === 0 && (
        <ListItem sx={getStyles.filterItem} key={"user-filter-0"}>
          <ListItemText
            sx={getStyles.filterName}
            primary="No saved filter"
            data-cy="no-users-filters"
          />
        </ListItem>
      )}

      {usersFilters?.data?.map((filter: UsersFilter, index: any) => (
        <ListItem
          sx={getStyles.filterItem}
          key={`user-filter-${filter.id || filter.name}`}
          data-cy={"users-filter-" + index}
        >
          <FilterBlock>
            <ListItemText sx={getStyles.filterName} primary={filter.name} />
            <Button
              sx={getStyles.deleteButton}
              variant="text"
              size="small"
              color="primary"
              onClick={(e) => deleteFilter(filter)}
            >
              <DeleteIcon />
            </Button>
            <Button
              sx={getStyles.addButton}
              variant="text"
              size="small"
              color="primary"
              onClick={(e) => addFilter(filter)}
            >
              Apply
            </Button>
          </FilterBlock>
        </ListItem>
      ))}
    </List>,
  ];

  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClickButton = () => {
    setOpen(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const location = useLocation();
  const paramsCount = Array.from(new URLSearchParams(location.search)).length;
  return (
    <Ctr theme={theme}>
      <Actions>
        <ButtonGroup
          variant="contained"
          color="primary"
          size="medium"
          ref={anchorRef}
          aria-label="split button"
          data-cy="my-filters-button"
          sx={getStyles.buttonGroup}
          disableElevation
        >
          <Button
            color="primary"
            sx={getStyles.button}
            onClick={handleClickButton}
          >
            Saved Filters
          </Button>
          <Button
            color="primary"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            data-cy="bill-summary-download"
            sx={getStyles.arrowBtn}
          >
            <KeyboardArrowDownIcon fontSize="small" />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          // disablePortal
          placement="bottom-start"
          sx={getStyles.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" data-cy="my-filters-list">
                    {myFilterDataList}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => setSaveDialogVisible(true)}
          data-cy="add-filters-button"
          disabled={paramsCount === 0}
          startIcon={<SaveIcon />}
        >
          Save Filter
        </Button>
      </Actions>
      <FiltersDialog
        show={showDeleteDialog}
        title="Delete Filter"
        handleCancel={handleCancel}
        handleSubmit={handleDelete}
        cancelLabel="Cancel"
        submitLabel="Delete"
      >
        <Typography variant="subtitle1">
          Are you sure you want to delete{" "}
          <strong>{selectedFilter?.name}</strong>?
        </Typography>
      </FiltersDialog>
      <FiltersDialog
        show={showSaveDialog}
        title="Save Filter"
        handleCancel={handleCancel}
        handleSubmit={handleCreate}
        cancelLabel="Cancel"
        submitLabel="Save"
      >
        <TextField
          name="name"
          label="Filter Name"
          type="text"
          value={filterName}
          size="small"
          fullWidth
          error={Boolean(validationError)}
          helperText={validationError}
          onChange={onInputChange}
          onKeyPress={onKeyPressed}
        />
      </FiltersDialog>
    </Ctr>
  );
};

export default MyFilters;

const FilterBlock = styled("div")({
  flex: "auto",
});

const Ctr = styled("div")<{ theme: Theme }>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(2),
}));

const Actions = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "10px",
  alignItems: "center",
});

const getStyles = {
  buttonGroup: {
    height: "36.45px",
  },
  button: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  popper: {
    zIndex: 1000,
    minWidth: "190px",
  },
  filterItem: {
    minWidth: {
      md: "300px", // theme.breakpoints.up("md") ?
    },
  },
  filterName: {
    float: "left",
  },
  deleteButton: {
    float: "right",
  },
  addButton: {
    float: "right",
    fontWeight: 500,
    marginLeft: "50px",
  },
  arrowBtn: {
    padding: 0,
  },
};
