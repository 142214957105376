import React, { useRef, useState } from "react";
import { Box, Button } from "@mui/material/";
import EditIcon from "@mui/icons-material/Edit";

import { useOnClickOutside } from "../helpers/hooks";

type Props = {
  children: React.ReactNode;
  flexGrow?: number;
  input: React.ReactNode;
  onEdit?: () => void;
  open?: boolean;
  style?: Object;
  buttonLabel?: string;
  [x: string]: any;
  disabled?: boolean;
};

const Component = ({
  children,
  flexGrow,
  input,
  onEdit,
  open = false,
  style,
  buttonLabel,
  disabled,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [editing, setEdit] = useState(open);

  useOnClickOutside(ref, () => setEdit(false));

  return (
    <Box display="flex" alignItems="center">
      {editing ? (
        <Box flexGrow={1}>
          <div ref={ref}>{input}</div>
        </Box>
      ) : (
        <Box flexGrow={flexGrow || 1} style={style}>
          {children}
        </Box>
      )}
      {!editing && (
        <Box>
          <Button
            color="primary"
            onClick={() => {
              setEdit(true);
              if (onEdit) {
                onEdit();
              }
            }}
            disabled={disabled}
            size="small"
            startIcon={<EditIcon />}
            variant="text"
          >
            {buttonLabel ?? "Edit"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Component;
