export enum AppSubdomains {
  BILLING = "billing",
  CUSTOMERS = "customers",
  INSIGHT = "insight",
  SUPPORT = "support",
  SALES = "sales",
  ORDERS = "orders",
  HUB = "hubinfo",
  HOME = "",
}

export const APP_SUBDOMAINS: string[] = [
  AppSubdomains.BILLING,
  AppSubdomains.CUSTOMERS,
  AppSubdomains.HUB,
  AppSubdomains.INSIGHT,
  AppSubdomains.ORDERS,
  AppSubdomains.SALES,
  AppSubdomains.SUPPORT,
];

export enum AppBrand {
  VODAFONE = "vodafone",
  DWP = "dwp",
  DWS = "digitalwholesalesolutions",
}

export const APP_BRANDS: string[] = [
  AppBrand.VODAFONE,
  AppBrand.DWP,
  AppBrand.DWS,
];
