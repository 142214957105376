import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface Props {
  children: ReactNode;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  title: string;
}

function Main({ children, title, leftComponent, rightComponent }: Props) {
  const getContent = () => {
    return children;
  };

  return (
    <Box display="flex" flexDirection="column" paddingTop={1} paddingBottom={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginBottom={2}
      >
        <Box display="flex" m={1}>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
          {leftComponent && <Box ml={1}>{leftComponent}</Box>}
        </Box>
        {rightComponent && <Box>{rightComponent}</Box>}
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {getContent()}
      </Box>
    </Box>
  );
}

export default Main;
