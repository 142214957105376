import { useMutation, useQuery, useQueryClient } from "react-query";
import * as accountApi from "../api/account";
import * as vodafoneApi from "../api/vodafone";
import { VodafoneProcessResponse } from "../api/vodafone";
import { DCAPIError } from "../api/types";
import { customerSearch } from "../api/customers";

/**
 * Create new customer / account
 */
export function useCreateCustomer() {
  const queryClient = useQueryClient();
  return useMutation<any, any>(accountApi.create, {
    // TODO: Types. But how to do if we're not writing API types in FE codebase any more?
    onSuccess: (data: any, variables: any) => {
      // Note there's a delay on the backend between a successful account
      // creation and data becoming available to search in customers. Hence
      // this invalidation won't always have the desired effect.
      // See NewCustomer component where we try again and hope it's got through
      // by that stage of the user journey.
      // Not good but no other option currently.
      queryClient.invalidateQueries("customers");
    },
  });
}

export function useCustomers(
  search: string,
  page: number,
  limit: number,
  params: any = {},
  options = {}
) {
  let converted: any = {};
  const keysWithPrefix = ["sort", "limit", "order", "page"];
  Object.keys(params || {}).forEach(function (key) {
    const convertedKey = keysWithPrefix.includes(key) ? "_" + key : key;
    converted["customers." + convertedKey] = params[key];
  });

  return useQuery(
    ["customers", search, page, limit, params],
    () =>
      customerSearch({
        search,
        "customers._order": params["direction"],
        "customers._page": page,
        "customers._limit": limit,
        ...converted,
      }),
    options
  );
}

/**
 * Initiate credit vet
 */
export function useProcessCreditVet() {
  const queryClient = useQueryClient();
  // @ts-ignore
  return useMutation<VodafoneProcessResponse, DCAPIError>(vodafoneApi.Process, {
    onSuccess: (data: any, variables: any) => {
      queryClient.invalidateQueries("ThirdPartyBilling");
    },
  });
}

/**
 * Get credit vet result
 */
export function useThirdPartyBilling(accountId: string) {
  return useQuery<any, any>(["ThirdPartyBilling", accountId], () =>
    accountApi.ThirdPartyBilling(accountId, "VF Direct", true)
  );
}
