import { useQuery } from "react-query";
import * as api from "../api/autocomplete";

export function useAccounts(query: string, options = {}) {
  return useQuery(
    ["autocomplete", "accounts", query],
    () => api.accounts(query),
    options
  );
}

export function useCLIs(query: string, options = {}) {
  return useQuery(
    ["autocomplete", "clis", query],
    () => api.clis(query),
    options
  );
}

export function useContacts(query: string, options = {}) {
  return useQuery(
    ["autocomplete", "contacts", query],
    () => api.contacts(query),
    options
  );
}
