import React, { useState } from "react";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import config from "../config";
import { Select, MenuItem, IconButton, SelectChangeEvent } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  AuthCookies,
  setCookieValue,
  useAuth,
} from "@akj-dev/affinity-platform";
import { parseBrandFromUrl } from "../helpers/brand";

interface UATBannerProps {
  authAPIBaseUrl: string;
  setAuthAPIBaseUrl: (url: string) => void;
}
const uatOptions = [
  config.authAPIBaseUrl[process.env.REACT_APP_STAGE || ""],
  "https://pub-external-users-testenv01.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv02.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv03.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv04.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv05.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv06.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv07.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv08.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv09.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv10.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv11.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv12.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv13.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv14.uat.aurora.io/api/v2/",
  "https://pub-external-users-testenv15.uat.aurora.io/api/v2/",
];

const isVoda = parseBrandFromUrl() === "VODAFONE";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(0, 1),
    backgroundColor: isVoda ? alpha("#333333", 0.8) : "rgba(204, 50, 36, 0.8)",
    backdropFilter: "blur(4px)",
    color: theme.palette.error.contrastText,
    zIndex: 999,
  },
  el: {
    margin: theme.spacing(0, 1),
  },
  select: {
    color: theme.palette.error.contrastText,
    "&:before": {
      borderBottomColor: theme.palette.error.contrastText,
    },
    "& .MuiSelect-icon": {
      color: theme.palette.error.contrastText,
    },
  },
}));

export const UATBanner = ({
  authAPIBaseUrl,
  setAuthAPIBaseUrl,
}: UATBannerProps) => {
  const { apiBaseUrl, logout } = useAuth();
  const [visible, setVisible] = useState(true);

  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (logout) logout();
    const v = event.target.value as string;
    setAuthAPIBaseUrl(v);
    setCookieValue(AuthCookies.UAT_BANNER_AUTH_API, v);
  };

  const handleClose = () => setVisible(false);
  if (!visible) return null;
  return (
    <div className={classes.root}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        color="inherit"
        size="large"
      >
        <Close />
      </IconButton>
      <div className={classes.el}>
        UAT Build <b>{process.env.REACT_APP_GIT_SHA?.substring(0, 8)}</b>
      </div>
      {Boolean(process.env.REACT_APP_GIT_BRANCH) && (
        <div className={classes.el}>
          Branch: <b>{process.env.REACT_APP_GIT_BRANCH}</b>
        </div>
      )}
      <div className={classes.el}>Auth API:</div>
      <Select
        variant="standard"
        value={authAPIBaseUrl}
        onChange={handleChange}
        className={classes.select}
      >
        {uatOptions.map((opt) => (
          <MenuItem value={opt} key={`opt-${opt}`}>
            {opt}
          </MenuItem>
        ))}
      </Select>
      <div className={classes.el}>
        DC API: <b>{apiBaseUrl}</b>
      </div>
    </div>
  );
};
