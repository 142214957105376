import React, { useState } from "react";
import download from "downloadjs";
import { LoaderButton, StatusChip } from "@akj-dev/shared-components";
import { CloudDownload } from "@mui/icons-material";
import { useAuth } from "@akj-dev/affinity-platform";
import { customersCSV } from "../api/customers";
import { assetsCSV } from "../api/assets";
import { TableType } from "../helpers/constants";
import { formattedDate } from "../helpers/functions";

interface CSVDownloadProps {
  filterParams: any;
  table: TableType;
}

export const CSVDownload = ({ filterParams, table }: CSVDownloadProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { accountMeta } = useAuth();

  const handleCSVDownload = async () => {
    setLoading(true);
    try {
      const response =
        table === TableType.Customers
          ? await customersCSV(filterParams)
          : await assetsCSV(filterParams);

      download(
        response,
        `${encodeURIComponent(accountMeta.name.replace(/ /g, "-"))}_${TableType[
          table
        ].toLowerCase()}_${formattedDate(new Date().toISOString())}.csv`
      );
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };

  if (error) return <StatusChip type="error" message={error} />;

  return (
    <LoaderButton
      loading={loading}
      onClick={handleCSVDownload}
      variant="outlined"
      color="primary"
      endIcon={<CloudDownload />}
    >
      Download CSV
    </LoaderButton>
  );
};
