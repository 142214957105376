import React, { useState, PropsWithChildren } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

import queryString from "query-string";
import { QueryParamProvider } from "use-query-params";

import {
  AuthCookies,
  AuthProvider,
  getCookieValue,
} from "@akj-dev/affinity-platform";
import { ErrorFullScreen, LoadingFullScreen } from "../../index";

import { LoginScreen } from "../components/auth/LoginScreen";
import { UATBanner } from "../components/UATBanner";
import config from "../config";
import { MUIProviders } from "./MUIProviders";

import { StandaloneAppLayout } from "../components/layout/StandaloneAppLayout";

import "./style.css";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";

console.log(`
Git Revision: ${process.env.REACT_APP_GIT_SHA}
Stage: ${process.env.REACT_APP_STAGE}

`);

const queryCache = new QueryCache();

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      staleTime: 0,
    },
  },
});

interface IStandaloneAppWrapperProps {
  root: string;
}

export const StandaloneAppWrapper = ({
  children,
  root,
}: PropsWithChildren<IStandaloneAppWrapperProps>) => {
  const stage = process.env.REACT_APP_STAGE;

  // If in development or uat, respect any existing auth API base URL in cookie
  const [authAPIBaseUrl, setAuthAPIBaseUrl] = useState(
    (stage !== "production" &&
      getCookieValue(AuthCookies.UAT_BANNER_AUTH_API)) ||
      config.authAPIBaseUrl[process.env.REACT_APP_STAGE || ""]
  );

  const query = queryString.parse(window.location.search);
  return (
    <QueryClientProvider client={queryClient}>
      <MUIProviders>
        <AuthProvider
          authApiBaseUrl={authAPIBaseUrl}
          loginToken={(query.login_token || "") as string}
          initScreen={() => (
            <LoadingFullScreen message="Loading user session..." />
          )}
          loginScreen={(handleLogin, loading, loginError, jwtError) => (
            <>
              {(stage === "uat" || stage === "development") && (
                <UATBanner
                  authAPIBaseUrl={authAPIBaseUrl}
                  setAuthAPIBaseUrl={setAuthAPIBaseUrl}
                />
              )}
              <LoginScreen
                handleLogin={handleLogin}
                loading={loading}
                loginError={loginError}
                jwtError={jwtError}
              />
            </>
          )}
          errorScreen={(message, retry) => {
            return (
              <ErrorFullScreen
                message={`Authentication error: ${message}`}
                retry={retry}
              />
            );
          }}
        >
          {(stage === "uat" || stage === "development") && (
            <UATBanner
              authAPIBaseUrl={authAPIBaseUrl}
              setAuthAPIBaseUrl={setAuthAPIBaseUrl}
            />
          )}
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <StandaloneAppLayout root={root}>{children}</StandaloneAppLayout>
            </QueryParamProvider>
          </Router>
        </AuthProvider>
      </MUIProviders>
    </QueryClientProvider>
  );
};
