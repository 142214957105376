import { get, getAsText, post, postJSON } from "./";
import { SearchQuery, ServiceQuery } from "../screens/Assets/types";
import { Asset, Service } from "../types";

export function addProduct(asset: Asset, data: any) {
  return post("Mobile/ServiceChange", {
    change_id: data?.change_id,
    account: asset.account_id,
    clis: [asset.billing_system_pin],
    product_id: data?.product?.id,
    ...data.query,
    start_date: data.date,
    end_date: data.end_date,
  });
}

export function barsCompatibilities(asset: Asset, active_bars: Array<string>) {
  return get(`MobileProvisioning/Compatibilities/${asset.billing_system_pin}`, {
    active_bars,
  });
}

export function cancelChange(asset: Asset, change_id: number) {
  return post("Mobile/CancelChange", {
    account: asset.account_id,
    change_id,
  });
}

export const resetChange = (change_id: number) =>
  post("Mobile/ResetChange", { change_id });

export function changeProduct(asset: Asset, data: any) {
  return post("Mobile/ServiceChange", {
    change_id: data?.change_id,
    account: asset.account_id,
    clis: [asset.billing_system_pin],
    product_id: data?.product?.id,
    service_id: data?.service?.service_id,
    soc_code: data?.service?.soc_code,
    end_date: data?.end_date,
  });
}

export function checkSim(number: string, network: string) {
  return get(`SIM/IsValidSIMNumber/${number}`, {
    network,
  });
}

export function cancelDisconnection(asset: Asset, id: string) {
  return get(`MobileDisconnections/CancelDisconnectionRequest`, {
    account: asset.account_id,
    disconnection_request_id: id,
  });
}

export function disconnections(asset: Asset) {
  return get(`MobileDisconnections/GetCliDisconnections`, {
    account: asset.account_id,
    cli_pins: `["${asset.billing_system_pin}"]`,
  });
}

export function productsChanges(asset: Asset) {
  return get("Mobile/LastServiceChange", {
    account: asset.account_id,
    clis: [asset.billing_system_pin],
  });
}

export function paginate(query: SearchQuery) {
  return get("Product/Instance/Search", query);
}

export function assetsCSV(query: SearchQuery) {
  return getAsText("Product/Instance/SearchCSV", query);
}

export function paginateProducts(asset: Asset, query: ServiceQuery) {
  let params: any = {
    account: asset.account_id,
    clis: [asset.billing_system_pin],
    ...query,
    search_query: query.search_query?.replace("£", ""),
  };

  if (query.id) {
    const service = (
      asset.evo_services ?? asset.third_party_billing_services
    ).find((service: Service) => service.id === query.id);

    const change_id = asset.changes?.products.in_flight_change?.id;

    params = {
      ...params,
      change_id,
      service_id: service?.service_id,
      service_type: service?.service_type,
      soc_code: service?.soc_code,
    };
  }

  return get("Product/ServiceChangeSearch", {
    guided_sales: 1,
    limit: 10,
    offset: (params.page ?? 1) * (params.limit ?? 10) - (params.limit ?? 10),
    ...params,
  });
}

export function provisionChange(asset: Asset, params: any) {
  return post("Mobile/ProvisionChange", {
    account: asset.account_id,
    ...params,
  });
}

export function refresh(asset: Asset) {
  return get(`Product/Instance/Refresh/${asset.id}`, {
    account: asset.account_id,
    background: 1,
  });
}

export function removeProduct(asset: Asset, service: Service) {
  return post("Mobile/ServiceRemove", {
    // change_id: asset.changes.products.in_flight_change?.id,
    account_id: asset.account_id,
    clis: [asset.billing_system_pin],
    service_type: service.service_type,
    soc_code: service.soc_code,
    bolt_on_type: service.bolt_on_type,
    bolt_on_subtype: service.bolt_on_subtype,
  });
}

export function requestPac(asset: Asset) {
  return postJSON("MobileDisconnections/CreateCliPortsFromWeb", {
    account_id: asset.account_id,
    msisdn: asset.billing_system_pin,
    requested_info: "PAC",
  });
}

export function requestStac(asset: Asset) {
  return postJSON("MobileDisconnections/CreateCliPortsFromWeb", {
    account_id: asset.account_id,
    msisdn: asset.billing_system_pin,
    requested_info: "STAC",
  });
}

export function saveBars(asset: Asset, bars: any) {
  return postJSON("MobileProvisioning/SetBars", {
    account: asset.account_id,
    bars: [
      {
        mpn: asset.billing_system_pin,
        bars_on: bars
          .filter((bar: any) => bar.active)
          .map((bar: any) => bar.name),
        bars_off: bars
          .filter((bar: any) => !bar.active)
          .map((bar: any) => bar.name),
      },
    ],
  });
}

export function saveSim(asset: Asset, number: string) {
  return postJSON("SIM/ActivatePreDispatchedSIMs", {
    account_id: asset.account_id,
    pre_dispatched_sims: [
      {
        mobile_number: asset.billing_system_pin,
        sim_number: number,
      },
    ],
  });
}

export function saveUsername(asset: Asset, user: any) {
  return post(`Mobile/UpdateUsername/${asset.pin}`, {
    account: asset.account_id,
    title: user.title,
    first_name: user.forename,
    surname: user.surname,
  });
}

export function show(id: number) {
  return get(`Product/Instance/View/${id}`, {
    with: ["evo_services", "third_party_billing_services"],
    unified_billing_services: true,
  });
}
