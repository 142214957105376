import React, { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Theme,
  useTheme,
} from "@mui/material";
import { SxStyleSheet } from "../themes/_base";

export interface Props {
  show: boolean;
  title: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  cancelLabel: string;
  submitLabel: string;
  children: ReactNode;
}

export const FiltersDialog = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Dialog open={props.show} onClose={props.handleCancel}>
      <Div theme={theme}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            onClick={props.handleCancel}
            data-cy="cancel-filter-dialog-button"
          >
            {props.cancelLabel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            data-cy="confirm-filter-dialog-button"
          >
            {props.submitLabel}
          </Button>
        </DialogActions>
      </Div>
    </Dialog>
  );
};

export default FiltersDialog;

const Div = styled("div")<{ theme: Theme }>(({ theme }) => ({
  margin: theme.spacing(1),
}));

const getStyles = (theme: Theme): SxStyleSheet => ({
  dialogActions: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
});
