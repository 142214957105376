import { postJSON } from "./";

// TODO: More duped API from cpq

export type ProcessParams = {
  account_id: string;
  number_of_connections: number;
};

// we're only interested in whether the call itself succeeded
export type VodafoneProcessResponse = Partial<{ status: "success" }>;

//
/**
 * Requests credit vet to be performed against an account for a specified number of connections
 * results are retrieved from a seperate endpoint
 *
 * @param params
 * @constructor
 */
export const Process = (params: ProcessParams) =>
  postJSON("Vodafone/Process", params);
