/**
 * Form field validators
 * TODO: Lots of duplicated code here from the CPQ. Shared utils package maybe?
 * See packages/cpq/src/helpers/validation.js
 */

/**
 * Test if input is a valid UK company registration number
 * See https://www.experts-exchange.com/questions/28781440/Regex-UK-Company-Number.html
 */
export const isValidUKCompanyRegNumber = (num: string) => {
  return /^([1-9]\d{6,7}|\d{6,7}|(SC|NI|AC|FC|GE|GN|GS|IC|IP|LP|NA|NF|NL|NO|NP|NR|NZ|OC|R|RC|SA|SF|SI|SL|SO|SP|SR|SZ|ZC|)\d{6,8})$/.test(
    num
  )
    ? true
    : "Must be a valid UK company registration number";
};

/**
 * Valid UK VAT number
 * https://help.accounting.sage.com/en-gb/accounting/tax/extra-vat-numbers.html
 * 9 numbers without spacing.
 */
export const isValidVATNumber = (value: string) => {
  return /^(\d){9}$/.test(value)
    ? true
    : "Must be a valid VAT number. Format 000000000";
};

/**
 * Valid phone number
 * @param value
 * @returns {boolean|string}
 */
export const isValidPhoneNumber = (value: string) => {
  const regexp = /^(?:0|\+44)(7)([0-9 ]{9,13})$/gi;
  // Some phone numbers apparently have ten digits:
  // https://auroratarget.tpondemand.com/entity/9541-10-digit-phone-numbers-have-always
  // 22/12/20: We are now allowing 0800 numbers, they can have 10, 9 or 7 digits.
  // https://auroratarget.tpondemand.com/entity/20017-contact-cannot-use-0845
  // Allow phone numbers with UK area code (+44) to pass validation
  // https://auroratarget.tpondemand.com/entity/9534-as-fe-l-all-phone-number
  const tenDigitRegexp = /^(?:0|\+44)(1|2|8)([0-9 ]{8,13})$/gi;
  const nineDigitRegexp = /^(?:0|\+44)(1|8)([0-9 ]{7,12})$/gi;
  const sevenDigitRegexp = /^(?:0|\+44)(8)([0-9 ]{5,10})$/gi;

  return regexp.test(value) ||
    tenDigitRegexp.test(value) ||
    nineDigitRegexp.test(value) ||
    sevenDigitRegexp.test(value)
    ? true
    : "Must be a valid phone number";
};

export const isOptionalValidPhoneNumber = (val: string) =>
  val ? isValidPhoneNumber(val) : true;

/**
 * Valid general text (only certain special characters allowed).
 * Restriction of special characters wanted as part of a couple of tickets, including:
 * https://auroratarget.tpondemand.com/entity/11524-delivery-page-validation-checks,
 * https://auroratarget.tpondemand.com/entity/8950-validation-issues and
 * https://auroratarget.tpondemand.com/entity/11525-opportunity-name-validation-checks
 * Agreed with Toby to restrict accented characters 26/08/2020.
 * TODO: Can't we change something in DC to accept accented characters? We even have team members with them!
 *
 * @param value
 * @returns {boolean|string}
 */
export const isValidText = (value: string) =>
  /^[A-Za-z0-9\-'., ]+$/.test(value)
    ? true
    : "Must not contain special characters";

export const isValidMobileNumber = (value: string) => {
  // Must be an 11 digit number starting 07
  // if (!/07[0-9]{9}/.test(value)) message = "Must be a valid UK mobile number"

  return /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(value)
    ? true
    : "Must be an 11 digit mobile number";
};

export const isOptionalValidMobileNumber = (val: string) =>
  val ? isValidMobileNumber(val) : true;

/**
 * Valid UK Bank Account (8 Digits)
 * http://regexlib.com/REDetails.aspx?regexp_id=2706
 *
 * @param value
 * @returns {boolean|string}
 */
export const isValidUKBankAccount = (value: string) =>
  /^(\d){8}$/.test(value) ? true : "Must be an 8 digit account number";

/**
 * Valid UK Bank Sort Code
 * http://regexlib.com/REDetails.aspx?regexp_id=2705
 * ....or in DC's case without hyphens. Which is odd....
 * 03/06/20: As requested, now allow with or without hyphens (to be stripped out before sending to the API).
 * See: https://auroratarget.tpondemand.com/entity/8116-as-allow-xx-xx-xx-or.
 * TODO: We should add auto-hyphens to the input perhaps...
 * @param value
 * @returns {boolean|string}
 */
export const isValidSortCode = (value: string) => {
  return /^(\d){6}$/.test(value) || /^(\d){2}-(\d){2}-(\d){2}$/.test(value)
    ? true
    : "Must be a valid sort code. Format 000000 or 00-00-00";
};

/**
 * Validate Email Address for Vodafone
 * @param {string} value - The email address to validate.
 * @returns {boolean|string} - Returns true if the email is valid, otherwise returns an error message.
 */
export const isValidVfEmail = (value: string) => {
  if (value.length > 80)
    return "Email address can be no longer than 80 characters.";

  const emailRegex =
    /^(([--.0-9A-Z_a-z])+@([--.0-9A-Za-z])+(([A-Za-z]+)\.([A-Za-z])+)+)$/;

  if (!emailRegex.test(value)) {
    return "Invalid email address";
  }

  const domainParts = value.split("@")[1].split(".");
  const tld = domainParts.slice(1);
  const invalidPart = tld.find((part) => part.length < 2 || part.length > 4);

  if (invalidPart) {
    return `Please use an alternative email address as the TLD (${tld.join(
      "."
    )}) is not supported.`;
  }

  return true;
};
