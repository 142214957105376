import React from "react";
import { Grid, MenuItem } from "@mui/material";
import { IndexedCardInfo } from "../../../atoms/indexed-card-info";
import { RHFSelectField } from "../../../atoms/form-fields/RHFSelectField";
import { AccountSettings } from "@akj-dev/affinity-platform";

interface MarketingPreferencesProps {
  cardIndex: string;
  accountSettings: AccountSettings;
}
export const MarketingPreferences = ({
  cardIndex,
  accountSettings,
}: MarketingPreferencesProps) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Marketing Preferences">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <RHFSelectField
            label="Opt in to email for sales/marketing"
            name="ok_to_email"
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </RHFSelectField>
        </Grid>
        <Grid item sm={6}>
          <RHFSelectField
            label="Opt in to telephone for sales/marketing"
            name="ok_to_phone"
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </RHFSelectField>
        </Grid>
        {accountSettings.can_access_vf_direct === "1" && (
          <>
            <Grid item sm={6}>
              <RHFSelectField
                label="Opt in to SMS for sales/marketing"
                name="ok_to_sms"
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </RHFSelectField>
            </Grid>
            <Grid item sm={6}>
              <RHFSelectField
                label="Opt in to MMS for sales/marketing"
                name="ok_to_mms"
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </RHFSelectField>
            </Grid>
            <Grid item sm={6}>
              <RHFSelectField
                label="Opt in to post for sales/marketing"
                name="ok_to_post"
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </RHFSelectField>
            </Grid>
          </>
        )}
      </Grid>
    </IndexedCardInfo>
  );
};

export default MarketingPreferences;
