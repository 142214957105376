import React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { DataCard } from "@akj-dev/shared-components";

interface ProductStatusCardsProps {
  broadbandCount: number;
  ethernetCount: number;
  mobileCount: number;
  wlrCount: number;
  isLoading: boolean;
}

export default function ProductStatusCards({
  broadbandCount,
  ethernetCount,
  mobileCount,
  wlrCount,
  isLoading,
}: ProductStatusCardsProps) {
  return (
    <Grid container spacing={2}>
      {broadbandCount > 0 && (
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <DataCard
            title="Broadband"
            data={
              isLoading ? <CircularProgress size={37} /> : broadbandCount || 0
            }
          />
        </Grid>
      )}
      {ethernetCount > 0 && (
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <DataCard
            title="Ethernet"
            data={
              isLoading ? <CircularProgress size={37} /> : ethernetCount || 0
            }
          />
        </Grid>
      )}
      {mobileCount > 0 && (
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <DataCard
            title="Mobile"
            data={isLoading ? <CircularProgress size={37} /> : mobileCount || 0}
          />
        </Grid>
      )}
      {wlrCount > 0 && (
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <DataCard
            title="WLR"
            data={isLoading ? <CircularProgress size={37} /> : wlrCount || 0}
          />
        </Grid>
      )}
    </Grid>
  );
}
