import React from "react";
import { Asset } from "../../../../../types";
import {
  ClickAwayListener,
  Popper,
  Button,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import makeStyles from "@mui/styles/makeStyles";
import { UpdateUsername } from "./UpdateUsername";

type Props = {
  asset: Asset;
  hasAdminBar?: boolean;
};

const Component = ({ asset, hasAdminBar }: Props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    // workaround to prevent clickaway firing on select click
    if ((event.target as Element).nodeName !== "BODY") {
      event.stopPropagation();
      setAnchorEl(null);
    }
  };

  const handleClickEditUsername = (
    event: React.MouseEvent<HTMLElement>
    // row: Customer
  ) => {
    if (!anchorEl) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="left"
        modifiers={[
          { name: "arrow", enabled: false, options: { element: arrowRef } },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.newOppContainer}>
            <span className={classes.arrow} ref={setArrowRef} />
            <UpdateUsername asset={asset} />
          </div>
        </ClickAwayListener>
      </Popper>
      <Box display="flex" alignItems="center">
        <Typography variant="h4">{asset.user_name}</Typography>

        <Button
          color="primary"
          onClick={(event) => {
            handleClickEditUsername(event);
          }}
          size="small"
          startIcon={<EditIcon />}
          variant="text"
          disabled={
            hasAdminBar ||
            asset.status === "Disconnected" ||
            asset.status === "Terminated"
          }
        >
          Edit
        </Button>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  action: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    margin: theme.spacing(-1, 0),
  },
  newOppContainer: {
    width: 400,
  },
  popper: {
    zIndex: 1,
  },
  arrow: {
    width: "1em",
    height: "1em",
    top: "93px !important",
    right: "-0.5em",
    transform: "rotate(45deg) !important",
    position: "absolute",
    boxShadow:
      "0 10px 0 #fff, -10px 0px 0 #fff, 0px 0px 5px 1px rgb(0 0 0 / 20%)",
    background: "#fff",
  },
}));

export default Component;
