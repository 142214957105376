import { platformApiFetch } from "@akj-dev/affinity-platform";
import queryString from "query-string";
import { DCAPIError } from "./types";

function getHeaders() {
  let headers = new Headers();

  headers.append("Accept", "application/json");
  headers.append(
    "Content-type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );
  headers.append("pragma", "no-cache");
  headers.append("cache-control", "no-cache");

  return headers;
}

function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response;
}

// function blob(response: any) {
//   return response.blob();
// }

function json(response: any) {
  return response.json().then((json: any) => {
    if (json.status === "error") {
      // Messages can be various shapes of array / object too
      throw json;
    }

    if (json.status === "warning") {
      // TODO: Does the same apply here?
      throw Error(json.warning.join(", "));
    }

    return json;
  });
}

export function get<P = any>(
  url: string,
  params?: any,
  options?: any
): Promise<P | DCAPIError> {
  const query = params
    ? "?" +
      queryString.stringify(params, { arrayFormat: "bracket", skipNull: true })
    : "";

  delete options?.params;

  return platformApiFetch("ExternalServices/v1/" + url + query, {
    ...options,
    headers: {
      ...options?.headers,
      ...getHeaders(),
    },
  })
    .then(handleErrors)
    .then(json);
}

export function getAsText<P = any>(
  url: string,
  params?: any,
  options?: any
): Promise<P | DCAPIError> {
  const query = params
    ? "?" +
      queryString.stringify(params, { arrayFormat: "bracket", skipNull: true })
    : "";

  delete options?.params;

  return platformApiFetch("ExternalServices/v1/" + url + query, {
    ...options,
    headers: {
      ...options?.headers,
      ...getHeaders(),
    },
  })
    .then(handleErrors)
    .then((response) => response.text());
}

export async function post<P = any>(
  url: string,
  params: any,
  options?: any
): Promise<P | DCAPIError> {
  return platformApiFetch("ExternalServices/v1/" + url, {
    ...options,
    body: queryString.stringify(params, {
      arrayFormat: "bracket",
      skipNull: true,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      ...options?.headers,
      ...getHeaders(),
    },
    method: "POST",
  })
    .then(handleErrors)
    .then(json);
}

export async function postJSON(url: string, params: any, options?: any) {
  return platformApiFetch("ExternalServices/v1/" + url, {
    ...options,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      ...options?.headers,
      ...getHeaders(),
    },
    method: "POST",
  })
    .then(handleErrors)
    .then(json);
}

// TODO: This is a dupe from support. Port to Axios like CPQ... Wait for OpenAPI though
export function getJSON(url: string, params: any, options: any = {}) {
  const query = params ? "?" + queryString.stringify(params) : "";

  delete options.params;

  return platformApiFetch("ExternalServices/v1/" + url + query, {
    ...options,
    headers: {
      ...options.headers,
      ...getHeaders(),
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      if (data.status === "error") {
        throw Error(data.message);
      }
      return data;
    });
}
