import { get, getAsText } from ".";

export function getCustomerCount(params: any) {
  return get(`SalesPerson/GetTotalNumberAccounts`, params).then(
    (response: { data: { results: any } }) => {
      return response.data.results;
    }
  );
}

export function getCustomers(params: any) {
  return get(`SalesPerson/GetAccounts`, params).then((response: any) => {
    return response;
  });
}

export function getCustomerById(salespersonId: any, accountId: any) {
  return get(
    `SalesPerson/GetAccounts?sales_person=${salespersonId}&account_id=${accountId}`
  );
}

export function getCustomerContacts(accountId: any) {
  return get(`Account/AllAccountContacts/${accountId}`);
}

export function customerSearch(params: any) {
  return get(`Affinity/Platform/Search`, {
    "include.assets": 0,
    "include.billing": 0,
    "include.customers": 1,
    "include.orders": 0,
    "include.sales": 0,
    "include.support": 0,
    ...params,
  });
}

export function customersCSV(params: any) {
  return getAsText(`Affinity/Platform/Search/default_csv`, {
    "include.customers": 1,
    "include.assets": 0,
    ...params,
  });
}
