export const searchResults = [
  {
    accountId: "ERP76226",
    accountName: "Accelerate 4",
    address: {
      building: null,
      street: "Street",
      town: "Test",
      region: null,
      postcode: "BB18 5BF",
    },
    phone: "01484 999999",
    mobile: "07923419905",
    contact: "Chris G",
    status: "Live",
    companyType: "Public Limited Company (PLC)",
    registeredNumber: "test",
    vatNumber: null,
    vfBillingAccountNumber: "DHW183",
    numberOfEmployees: "42",
  },
  {
    accountId: "XHY11422",
    accountName: "1-2-1 Hairdressing",
    address: {
      building: null,
      street: "., Main Road",
      town: "Brighstone",
      region: "Newport",
      postcode: "PO30 4AH",
    },
    phone: "01983741121",
    mobile: null,
    contact: "1-2-1 Hairdressing",
    status: "Live",
    companyType: "Sole Trader / Owner Manager",
    registeredNumber: null,
    vatNumber: null,
    vfBillingAccountNumber: "DHW183",
    numberOfEmployees: "15",
  },
  {
    accountId: "SHA19835",
    accountName: "UNIT 1",
    address: {
      building: "Shabaz Bedding Of Oldham Ltd",
      street: "Havelock Street",
      town: "Oldham",
      region: "Lancashire",
      postcode: "OL8 1JR",
    },
    phone: "01616330411",
    mobile: null,
    contact: "Shabaz Kumar",
    status: "Live",
    companyType: "Limited Company (LTD)",
    registeredNumber: "06393825",
    vatNumber: null,
    vfBillingAccountNumber: "DHW183",
    numberOfEmployees: "67",
  },
  {
    accountId: "ATC25294",
    accountName: "Universal Tanker Solutions Ltd",
    address: {
      building: "Bold Ind Est",
      street: "12a Neills Road",
      town: "St Helens",
      postcode: "WA9 4TU",
    },
    phone: "016149410740",
    mobile: null,
    contact: "Simon Gunn",
    status: "Closed",
    companyType: "Limited Company (LTD)",
    registeredNumber: "04641757",
    vatNumber: null,
    vfBillingAccountNumber: "DHW183",
    numberOfEmployees: "101",
  },
];

export const broadbandProduct = {
  status: "Active",
  cli: "01706214778",
  name: "SoGEA 40/10 Mkt A",
  type: "SoGEA",
  careLevel: "Standard",
  broadband: {
    username: "testbb@backup-net.serv.xdsl",
    password: "0948484848493893",
    iPv4: "20.10.11.22",
    iPv4Type: "Dynamic",
  },
  siteContact: {
    siteAddress: "123 Streetname Lane, Manchester",
    postcode: "M1 234",
    name: "Sam Thistlewaite",
    email: "Samuel.thistlewaite@aurora.io",
    phoneNumber: "07123456789",
  },
  contract: {
    contractStartDate: "DD/MM/YYYY",
    contractEndDate: "DD/MM/YYYY",
    productCeasedDate: "N/A",
    contractSignedDate: "DD/MM/YYYY",
    contractTerm: "12 Months",
    billingStatus: "Live",
    costCentre1: "N/A",
    costCentre2: "N/A",
    costCentre3: "N/A",
  },
};
