import React, { useState } from "react";
import {
  Chip,
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";
import { format, parseISO } from "date-fns";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { ChevronLeft } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  useRefresh,
  useRequestPac,
  useRequestStac,
} from "../../../../../hooks/assets";
import { usePoll } from "../../../../../hooks/polling";
import { redirectToApp } from "../../../../../helpers/redirectToApp";
import { Asset, PACErrorCodes } from "../../../../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "baseline",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
    minWidth: "fit-content",
    marginRight: "16px",
    marginBottom: "24px",
    whiteSpace: "nowrap",
  },
  buttons: {
    overflowX: "auto",
    overflowY: "hidden",
    marginBottom: "24px",
  },
  button: {
    whiteSpace: "nowrap",
    minWidth: "auto",
  },
  link: {
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer",
    color: "inherit",
  },
}));

type Props = {
  asset: Asset;
  pac?: any;
  stac?: any;
};

const Component = ({ asset, pac, stac }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [shouldShowPac, showPac] = useState(false);
  const [shouldShowStac, showStac] = useState(false);

  const refresh = useRefresh(asset);
  const poll = usePoll(refresh.data?.background_id, {
    enabled: refresh.isSuccess,
    refetchInterval: (data: any) => {
      if (data?.status === "pending") {
        return 3000;
      }
      return false;
    },
    onSuccess: (data: any) => {
      if (data?.status === "complete") {
        queryClient.invalidateQueries(["assets", "" + asset.id]);
        queryClient.invalidateQueries(["asset", "" + asset.id]);
      }
    },
  });

  const requestPac = useRequestPac(asset, {
    onSuccess: () => showPac(false),
    onError: () => {},
  });
  const requestStac = useRequestStac(asset, {
    onSuccess: () => showStac(false),
  });

  const handleCancelPac = (asset: Asset) => {
    queryClient.invalidateQueries(["asset", asset.id, "disconnections"]);
    showPac(false);
    requestPac.reset();
  };
  const handleCancelStac = (asset: Asset) => {
    queryClient.invalidateQueries(["asset", asset.id, "disconnections"]);
    showStac(false);
    requestStac.reset();
  };

  const errorCodeToText = (errorCode: string) => {
    switch (errorCode) {
      case PACErrorCodes.INVALID_REQUEST:
      case PACErrorCodes.CANNOT_CREATE_REQUEST:
        return "Failed to process the request with Vodafone. Please try again.";
      case PACErrorCodes.ACCOUNT_NOT_FOUND:
        return "Failed to retrieve the customers account from Vodafone.";
      case PACErrorCodes.FAILED_TO_PROCESS:
        return "Failed to retrieve Early Termination Fees from Vodafone. Please try again.";
      case PACErrorCodes.NO_LIVE_CLI:
        return "This number is not live with Vodafone.";
      default:
        return;
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={1} className={classes.title}>
        <Box pr={0.5}>
          <Typography variant="h3">{asset.billing_system_pin}</Typography>
        </Box>
        <Chip color="primary" label={asset.type} />
      </Grid>
      <Grid item xs display="flex" justifyContent="flex-end">
        <Box display="flex" className={classes.buttons}>
          <Box display="flex" flexDirection="column">
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => refresh.mutate()}
              variant="outlined"
              startIcon={
                poll.data?.status === "pending" ? (
                  <CircularProgress size={12} />
                ) : null
              }
              disabled={
                asset.status === "Terminated" || asset.status === "Disconnected"
              }
            >
              {poll.data?.status === "pending"
                ? "Refreshing Network Attribute"
                : "Refresh Network Attribute"}
            </Button>
            {asset.attributes_last_refreshed_date && (
              <Box pt={0.1} pl={0.3}>
                <Typography variant="caption">
                  Last refresh:{" "}
                  {format(
                    parseISO(asset.attributes_last_refreshed_date),
                    "dd/MM/yyy HH:mm:ss"
                  ) || "N/A"}
                </Typography>
              </Box>
            )}
          </Box>

          <Box ml={1}>
            <Button
              color="primary"
              size="small"
              className={classes.button}
              onClick={() =>
                redirectToApp(
                  getPathForCrossAppRedirect(
                    AppSubdomains.SUPPORT,
                    `/support/tickets?account_id=${asset.account_id}&asset_id=${asset.id}&cli=${asset.billing_system_pin}&product_type=${asset.type}`
                  )
                )
              }
              variant="outlined"
            >
              Ticket History
            </Button>
          </Box>

          {asset.type === "Mobile" && (
            <React.Fragment>
              <Box ml={1}>
                <Button
                  color="primary"
                  disabled={
                    !!(pac || stac) ||
                    asset.status === "Terminated" ||
                    asset.status === "Disconnected" ||
                    requestPac.isLoading ||
                    requestStac.isLoading
                  }
                  className={classes.button}
                  onClick={() => showPac(true)}
                  size="small"
                  startIcon={
                    requestPac.isLoading ? <CircularProgress size={12} /> : null
                  }
                  variant="outlined"
                >
                  Request PAC
                </Button>

                <Dialog open={shouldShowPac} onClose={handleCancelPac}>
                  <DialogTitle>Request PAC Code</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      By submitting this request you will generate a PAC which
                      is valid for 30 days and can be used to transfer the
                      existing number to another provider.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      disabled={requestPac.isLoading}
                      onClick={() => handleCancelPac(asset)}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      disabled={requestPac.isLoading}
                      onClick={() => requestPac.mutate({})}
                      startIcon={
                        requestPac.isLoading ? (
                          <CircularProgress size={12} />
                        ) : null
                      }
                      variant="contained"
                    >
                      Request PAC
                    </Button>
                  </DialogActions>
                  {(requestPac.isError ||
                    (requestPac.isSuccess && !requestPac.data?.success)) && (
                    <Box p={1}>
                      <Typography color="error" variant="caption">
                        {errorCodeToText(requestPac.error.code) ||
                          requestPac.error.error}
                        {/* {requestPac.error.code === "FAILED_TO_PROCESS"
                          ? "Failed to retrieve Early Termination Fees from Vodafone. Please try again."
                          : "The action you have taken has failed to complete at the network. Please try again."} */}
                        <br />
                        {requestPac.error.code ===
                          PACErrorCodes.ACCOUNT_NOT_FOUND ||
                        requestPac.error.code === PACErrorCodes.NO_LIVE_CLI
                          ? "P"
                          : "If the problem continues p"}
                        {"lease raise an "}
                        <a
                          href={getPathForCrossAppRedirect(
                            AppSubdomains.SUPPORT,
                            `/support/new-ticket?account_id=${asset.account_id}&type=Incident`
                          )}
                          className={classes.link}
                        >
                          <span>incident ticket here</span>
                        </a>
                        , this will be sent to our Support Team.
                      </Typography>
                    </Box>
                  )}
                </Dialog>
              </Box>

              <Box ml={1}>
                <Button
                  color="primary"
                  disabled={
                    !!(pac || stac) ||
                    asset.status === "Terminated" ||
                    asset.status === "Disconnected" ||
                    requestPac.isLoading ||
                    requestStac.isLoading
                  }
                  className={classes.button}
                  onClick={() => showStac(true)}
                  size="small"
                  startIcon={
                    requestStac.isLoading ? (
                      <CircularProgress size={12} />
                    ) : null
                  }
                  variant="outlined"
                >
                  Request STAC
                </Button>

                <Dialog
                  open={shouldShowStac}
                  onClose={() => handleCancelStac(asset)}
                >
                  <DialogTitle>Request STAC Code</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      By submitting this request you will generate a STAC which
                      is valid for 30 days and can be used to cancel your
                      current number when switching to another provider.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {requestStac.isError ||
                      (requestStac.isSuccess && !requestStac.data?.success && (
                        <Typography color="error" variant="caption">
                          Can't get back STAC details.
                        </Typography>
                      ))}

                    <Button
                      color="primary"
                      disabled={requestStac.isLoading}
                      onClick={() => handleCancelStac(asset)}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      disabled={requestStac.isLoading}
                      onClick={() => requestStac.mutate({})}
                      startIcon={
                        requestStac.isLoading ? (
                          <CircularProgress size={12} />
                        ) : null
                      }
                      variant="contained"
                    >
                      Request STAC
                    </Button>
                  </DialogActions>
                  {(requestStac.isError ||
                    (requestStac.isSuccess && !requestStac.data?.success)) && (
                    <Box p={1}>
                      <Typography color="error" variant="caption">
                        The action you have taken has failed to complete at the
                        network, please try again.
                        <br />
                        If the problem continues please raise an{" "}
                        <a
                          href={getPathForCrossAppRedirect(
                            AppSubdomains.SUPPORT,
                            `/support/new-ticket?account_id=${asset.account_id}&type=Incident`
                          )}
                          className={classes.link}
                        >
                          <span>Incident ticket here</span>
                        </a>
                        , this will be sent to our Support Team.
                      </Typography>
                    </Box>
                  )}
                </Dialog>
              </Box>

              <Box ml={1}>
                <Button
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={() =>
                    redirectToApp(
                      getPathForCrossAppRedirect(
                        AppSubdomains.SUPPORT,
                        `/support/new-ticket?account_id=${asset.account_id}&asset_id=${asset.id}&clis[]=${asset.billing_system_pin}&product_type=${asset.type}&subtype=Disconnection Request&name=Disconnection Request`
                      )
                    )
                  }
                  variant="outlined"
                  disabled={
                    asset.status === "Terminated" ||
                    asset.status === "Disconnected"
                  }
                >
                  Disconnection
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            color="primary"
            component={Link}
            size="small"
            startIcon={<ChevronLeft />}
            to="/customers/assets"
            variant="text"
          >
            Back
          </Button>
          <Button
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            onClick={() =>
              redirectToApp(
                getPathForCrossAppRedirect(
                  AppSubdomains.SUPPORT,
                  `/support/new-ticket?account_id=${asset.account_id}&asset_id=${asset.id}&clis[]=${asset.billing_system_pin}&product_type=${asset.type}`
                )
              )
            }
            variant="contained"
          >
            Raise a Ticket
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Component;
