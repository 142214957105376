import React from "react";
import { CircularProgress, styled, Theme, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";

type TypeType = "loading" | "error" | "success" | "info";

/** A surface to show request status and information */
export interface StatusChipProps {
  /** The data attribute used for targeting this component in Cypress */
  "data-cy"?: string;
  title?: string;
  message?: string | React.ReactNode;
  type?: TypeType;
  retry?: () => void;
  // TODO: Get rid of link. It's used for one component + inappropriate:
  //  src/products/sales/src/containers/StepChoose/wlrBroadband/Resigns/ResignProductSelector.js
  link?: React.ReactNode;
  style?: React.CSSProperties;
  hasMarginBottom?: boolean;
}

export const StatusChip = ({
  title,
  message,
  type = "info",
  retry,
  link,
  style,
  "data-cy": dataCy = "StatusChip",
  hasMarginBottom = false,
}: StatusChipProps) => {
  const theme = useTheme();

  if (message || title) {
    if (typeof message !== "string" && !React.isValidElement(message)) {
      // Don't blow up if a JS object has been passed by mistake. eg. a weird DC error response.
      message = JSON.stringify(message);
    }
    return (
      <Root
        data-cy={dataCy}
        theme={theme}
        type={type}
        hasMarginBottom={hasMarginBottom}
        style={style}
      >
        <div>
          {type === "loading" && <CircularProgress color="inherit" />}
          {type === "error" && (
            <ErrorIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
          {type === "success" && (
            <CheckIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
          {type === "info" && (
            <InfoIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
        </div>
        <Message theme={theme}>
          {title && <Title>{title}</Title>}
          {message}
          {retry && <Retry onClick={retry}>Please try again</Retry>}
          {link && link}
        </Message>
      </Root>
    );
  }
  return <div />;
};

StatusChip.displayName = "StatusChip";

interface RootProps {
  theme: Theme;
  type: TypeType;
  hasMarginBottom: boolean;
}

const Root = styled("div")<RootProps>(({ theme, type, hasMarginBottom }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: 4,
  color:
    type === "loading"
      ? theme.palette.primary.dark
      : theme.palette[type].contrastText,
  background:
    type === "loading" ? theme.palette.grey["200"] : theme.palette[type].main,
  padding: theme.spacing(1, 1),
  "& a": {
    color: "inherit",
    textDecoration: "underline",
  },
  ...(hasMarginBottom && { marginBottom: 15 }),
}));

const Message = styled("div")<{ theme: Theme }>(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

const Title = styled("div")({
  fontSize: 16,
  fontWeight: "bold",
  marginBottom: 5,
});

const Retry = styled("div")({
  marginLeft: 10,
  color: "inherit",
  fontWeight: "bold",
  textDecoration: "underline",
  cursor: "pointer",
});
