import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Alert } from "@akj-dev/shared-components";

import { Asset, AttributeGroup, Disconnection } from "../../../../types";
import Header from "./components/Header";
import PAC from "./components/PAC";
import Details from "./components/Details";
import Products from "./Products";
import Attributes from "./Attributes";
import { useDisconnections } from "../../../../hooks/assets";
import Loading from "../../../../layout/Loading";

const useStyles = makeStyles((theme) => ({
  alert: {
    margin: "10px 0",
  },
}));

type Props = {
  asset: Asset;
};

const Component = ({ asset }: Props) => {
  const classes = useStyles();

  const disconnections = useDisconnections(asset);

  const pac =
    disconnections.isSuccess &&
    disconnections.data?.in_progress_disconnections?.find(
      (disconnection: Disconnection) => disconnection.is_stac === "0"
    );
  const stac =
    disconnections.isSuccess &&
    disconnections.data?.in_progress_disconnections?.find(
      (disconnection: Disconnection) => disconnection.is_stac !== "0"
    );

  const attributes =
    asset.grouped_attributes_with_values.find(
      (group: AttributeGroup) => group.name === "Bars"
    )?.attributes ?? [];

  const admin_bar = attributes.find(
    (item: any) => item.attribute && item.attribute.name === "ACDL"
  );

  const hasAdminBar = admin_bar && admin_bar.value === "1" ? true : false;

  return (
    <React.Fragment>
      <Header asset={asset} pac={pac} stac={stac} />
      <Loading
        loading={disconnections.isFetching}
        message="Checking disconnection details."
      >
        {disconnections.isSuccess && (
          <>
            {pac && <PAC asset={asset} code={pac} />}
            {stac && <PAC asset={asset} code={stac} type="stac" />}
          </>
        )}
      </Loading>
      <Details asset={asset} hasAdminBar={hasAdminBar} />
      {hasAdminBar && (
        <div className={classes.alert}>
          <Alert
            type="warning"
            message="Admin/Lost SIM bar is present. This must be removed before making changes to the number."
          ></Alert>
        </div>
      )}
      <Products asset={asset} hasAdminBar={hasAdminBar} />
      <Attributes asset={asset} />
    </React.Fragment>
  );
};

export default Component;
