import React from "react";

import { Alert } from "@akj-dev/shared-components";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

const useStyles = makeStyles((theme) => ({
  labelWrap: {
    margin: "auto",
  },
  centered: {
    display: "flex",
    alignItems: "center",
  },
  alert: {
    float: "left",
    margin: "10px 0",
    left: 0,
  },
}));

interface Props {
  active: boolean;
  compatible: boolean;
  description: string;
  label: string;
  loading: boolean;
  name: string;
  onChange: (name: string, active: boolean) => void;
  wasChanged: boolean;
  alertText: string;
  status: string | undefined;
  lastUpdate: string | undefined;
  platformUsername: string | undefined;
  type: string;
  disable: boolean;
}

const Component = ({
  active,
  compatible,
  description,
  label,
  loading,
  name,
  onChange,
  wasChanged,
  alertText,
  status,
  lastUpdate,
  platformUsername,
  type,
  disable,
}: Props) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name, event.target.checked);
  };

  const formatType = (type: string) => {
    switch (type) {
      case "add":
        return "added";
      case "remove":
        return "removed";
      case "change":
        return "changed";
      default:
        return "made an unknown change to";
    }
  };

  return (
    <>
      <Grid item xs={7}>
        <Grid container>
          <Tooltip title={description} arrow>
            <Grid container xs={8}>
              <Grid item xs={1}>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  style={{ backgroundColor: "transparent" }}
                  size="large"
                >
                  <InfoIcon />
                </IconButton>
              </Grid>
              <Grid item xs={7} className={classes.labelWrap}>
                <Typography variant="body2">{label}</Typography>
              </Grid>
            </Grid>
          </Tooltip>
          <Grid item xs={2} className={classes.centered}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  disabled={loading || !compatible || disable}
                  onChange={handleChange}
                  name={name}
                  color="primary"
                />
              }
              label={active ? "On" : "Off"}
            />
            {!loading && !compatible && (
              <div title="Incompatible" className={classes.centered}>
                <ErrorOutlineIcon fontSize="small" />
              </div>
            )}
            {wasChanged && (
              <div title="Edited" className={classes.centered}>
                <EditIcon fontSize="small" />
              </div>
            )}
          </Grid>
          <Grid item xs={2}>
            {status && status !== "completed" && (
              <Tooltip
                title={`Last request ${lastUpdate}. ${
                  platformUsername ?? "unknown user"
                } ${formatType(type)} a bar. Status: ${status}.`}
                placement="right"
                arrow
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  color={"warning"}
                  style={{ backgroundColor: "transparent" }}
                  size="large"
                >
                  <WarningAmberOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      {alertText && active && (
        <Grid item xs={12}>
          <div className={classes.alert}>
            <Alert type="error" message={alertText} />
          </div>
        </Grid>
      )}
    </>
  );
};

export default Component;
