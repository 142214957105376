export const defaultValues = {
  // Account details
  name: "",
  business_entity_type: "",
  charity_number: "",
  registered_number: "",
  employees: "",
  vat_number: "",

  // Company address 1
  company_postcode: "",
  company_building_number: "",
  company_building: "",
  company_street: "",
  company_post_town: "",
  company_locale: "",
  company_region: "",
  company_country: "United Kingdom", // Default to United Kingdom (TP67845)
  companyAddressDateFrom: "",
  company_months_at_address: "", // Hidden

  billing_address_same: true,
  residential_status: "",

  // Company address 2 (VF Direct credit check history)
  // Yes, this data structure is not ideal. No, there isn't anything we can do about it right now
  // All this is ported from existing feature
  company_postcode2: "",
  company_building_number2: "",
  company_building2: "",
  company_street2: "",
  company_post_town2: "",
  company_locale2: "",
  company_region2: "",
  company_country2: "United Kingdom", // Default to United Kingdom (TP67845)
  companyAddressDateFrom2: "",
  company_months_at_address2: "", // Hidden

  // Company address 2 (VF Direct credit check history)
  company_postcode3: "",
  company_building_number3: "",
  company_building3: "",
  company_street3: "",
  company_post_town3: "",
  company_locale3: "",
  company_region3: "",
  company_country3: "United Kingdom", // Default to United Kingdom (TP67845)
  companyAddressDateFrom3: "",
  company_months_at_address3: "", // Hidden

  // Billing address
  billing_postcode: "",
  billing_building_number: "", // TODO: Not in DC codebase?
  billing_building: "",
  billing_street: "",
  billing_post_town: "",
  billing_locale: "",
  billing_region: "",
  billing_country: "United Kingdom", // Default to United Kingdom (TP67845)

  // Contact Details
  salutation: "",
  first_name: "",
  last_name: "",
  phone: "",
  fax: "",
  mobile: "",
  email: "",
  birthdate: "",

  // Payment
  bank_name: "",
  bank_account_name: "",
  bank_account_number: "",
  bank_account_sortcode: "",
  payment_type: "DD", // Hidden

  // Bill options
  // Sometimes hidden
  ebilling_enabled: "1",
  ebilling_email: "",
  large_print_bill: "0",
  create_cost_centre_csv: "0",

  // Marketing Preferences
  ok_to_email: "0",
  ok_to_phone: "0",
  ok_to_sms: "0",
  ok_to_mms: "0",
  ok_to_post: "0",

  // Voda only?
  ofcom_rid: "",
};
