import React, { useState } from "react";
import {
  Autocomplete,
  DateRangePicker,
  FilterBar,
  FilterBarPreviewProps,
  TextField,
} from "../../../shared/filters";
import { useAccounts, useCLIs } from "../../../hooks/autocomplete";

interface FiltersProps {
  query: any;
  setQuery: (params: any) => void;
  resetQuery: () => void;
}

export default function Filters({ query, setQuery, resetQuery }: FiltersProps) {
  const [accountsQuery, searchAccounts] = useState("");
  const [clisQuery, searchClis] = useState("");

  const accounts = useAccounts(accountsQuery);
  const clis = useCLIs(clisQuery);
  const statuses = ["Live", "Terminated"];
  const resign_status = [
    { text: "Full Term Renewable", value: "full-term-renewable" },
    { text: "In-Life Renewable", value: "in-life-renewable" },
    { text: "Not Renewable", value: "not-renewable" },
  ];

  let preview: FilterBarPreviewProps[] = [];

  if (query.accounts_ids?.length > 0) {
    preview.push({
      label: "Company Name",
      values: accounts.data?.data?.results
        .filter((account: any) => query.accounts_ids?.includes(account.id))
        .map((account: any) => account.name),
      onDelete: () => setQuery({ accounts_ids: [] }),
    });
  }

  if (query.products_names?.length > 0) {
    preview.push({
      label: "Product Name",
      max: 3,
      values: query.products_names,
      onDelete: () => setQuery({ products_names: [] }),
    });
  }

  if (query.clis?.length > 0) {
    preview.push({
      label: "CLI",
      max: 3,
      values: query.clis,
      onDelete: () => setQuery({ clis: [] }),
    });
  }

  if (query.sim_serials?.length > 0) {
    preview.push({
      label: "SIM Number",
      max: 3,
      values: query.sim_serials,
      onDelete: () => setQuery({ sim_serials: [] }),
    });
  }

  if (query.statuses?.length > 0) {
    preview.push({
      label: "Status",
      values: query.statuses,
      onDelete: () => setQuery({ statuses: [] }),
    });
  }

  if (query.terms?.length > 0) {
    preview.push({
      label: "Term",
      values: query.terms,
      onDelete: () => setQuery({ terms: [] }),
    });
  }

  if (query.date_range_signed_before && query.date_range_signed_after) {
    preview.push({
      label: "Start Range",
      values: [query.date_range_signed_after, query.date_range_signed_before],
      onDelete: () =>
        setQuery({
          date_range_signed_before: undefined,
          date_range_signed_after: undefined,
        }),
    });
  }

  if (query.date_range_end_before && query.date_range_end_after) {
    preview.push({
      label: "End Range",
      values: [query.date_range_end_after, query.date_range_end_before],
      onDelete: () =>
        setQuery({
          date_range_end_before: undefined,
          date_range_end_after: undefined,
        }),
    });
  }

  if (query.vf_direct_resign_status) {
    preview.push({
      label: "Resign Status",
      values: [query.vf_direct_resign_status],
      onDelete: () => setQuery({ vf_direct_resign_status: null }),
    });
  }

  return (
    <FilterBar onReset={resetQuery} preview={preview} title="Filter">
      <Autocomplete
        defaultValue={accounts.data?.data?.results.filter((account: any) =>
          query.accounts_ids?.includes(account.id)
        )}
        loading={accounts.isLoading}
        label="Company Name"
        labelFrom="name"
        multiple
        onSearch={(query) => searchAccounts(query)}
        onSelect={(accounts) =>
          setQuery({
            accounts_ids: accounts.map((account: any) => account.id),
          })
        }
        options={accounts.data?.data?.results ?? []}
        valueFrom="id"
      />
      <TextField
        label="Product Name"
        onApply={(products_names) => setQuery({ products_names })}
        values={query?.products_names ?? []}
      />
      <Autocomplete
        defaultValue={clis.data?.data?.results.filter((cli: any) =>
          query.clis?.includes(cli.pin)
        )}
        loading={clis.isLoading}
        label="CLI"
        labelFrom="pin"
        multiple
        onSearch={(query) => searchClis(query)}
        onSelect={(clis) =>
          setQuery({
            clis: clis.map((cli: any) => cli.pin),
          })
        }
        options={clis.data?.data?.results ?? []}
        valueFrom="pin"
      />
      <TextField
        label="SIM Number"
        onApply={(sim_serials) => setQuery({ sim_serials })}
        values={query?.sim_serials ?? []}
      />
      <Autocomplete
        defaultValue={query.statuses}
        label="Status"
        multiple
        onSelect={(statuses) => setQuery({ statuses })}
        options={statuses}
      />
      <DateRangePicker
        label="Contract Start"
        onApply={(start_date_ranges: Array<String>) => {
          setQuery({
            date_range_signed_after: start_date_ranges[0],
            date_range_signed_before: start_date_ranges[1],
          });
        }}
        selection={[
          query?.date_range_signed_after,
          query?.date_range_signed_before,
        ]}
      />
      <DateRangePicker
        label="Contract End"
        onApply={(end_date_ranges: Array<String>) => {
          setQuery({
            date_range_end_after: end_date_ranges[0],
            date_range_end_before: end_date_ranges[1],
          });
        }}
        selection={[query?.date_range_end_after, query?.date_range_end_before]}
      />
      <TextField
        label="Term"
        onApply={(terms) => setQuery({ terms })}
        values={query?.terms ?? []}
      />
      <Autocomplete
        defaultValue={query.resign_status}
        label="Resign Status"
        multiple={false}
        onSelect={(resign_status) => {
          setQuery({
            vf_direct_resign_status: resign_status.value,
          });
        }}
        options={resign_status}
      />
    </FilterBar>
  );
}
