import React from "react";
import { DataTable } from "@akj-dev/shared-components";
import { formattedDate } from "../../../../../../helpers/functions";
import { Asset, Discount } from "../../../../../../types";

type Props = {
  asset: Asset;
};

const Component = ({ asset }: Props) => {
  return (
    <DataTable
      columns={[
        {
          name: "discount-soc",
          label: "Discount SOC",
        },
        {
          name: "type",
          label: "Discount Type",
        },
        {
          name: "start",
          label: "Discount Start",
        },
        {
          name: "end",
          label: "Discount End",
        },
        {
          name: "value",
          label: "Discount Value",
        },
      ]}
      count={asset.discounts?.length ?? 0}
      data={(asset.discounts || []).map((discount: Discount) => [
        discount.soc_code,
        discount.type === "F" ? "Fixed" : "Percentage",
        formattedDate(discount.start_date),
        formattedDate(discount.end_date),
        discount.type === "F" ? (
          <React.Fragment>
            &pound;
            {(parseFloat(discount.value_in_pence) / 100).toFixed(2)}
          </React.Fragment>
        ) : (
          <React.Fragment>{discount.value_in_percent}%</React.Fragment>
        ),
      ])}
      title="Discounts"
    />
  );
};

export default Component;
