import React from "react";
import {
  Button,
  FormControl,
  Grid,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import Timer from "@mui/icons-material/Timer";
import FilterBox from "./FilterBox";
import { Filter } from "../types";
import { formatDateWithPattern, parseStringToDate } from "../utils";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TimePickerToolbar } from "@mui/x-date-pickers/TimePicker/TimePickerToolbar";
import { SxStyleSheet } from "../../themes/_base";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

const DEFAULT_TIME = null;

export default function TimeFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const PATTERN = "mm:ss";

  const val1 = selection[0]?.split(" ");
  const val2 = selection[1]?.split(" ");

  const [value, setValue] = React.useState(val1?.[0] || "none");
  const [criteriaValue, setCriteriaValue] = React.useState(val2?.[0] || "none");

  const [selectedDate, handleDateChange] = React.useState<Date | null>(
    val1 ? parseStringToDate(val1?.[1], PATTERN) : DEFAULT_TIME
  );

  const [selectedCriteriaDate, handleCriteriaDateChange] =
    React.useState<Date | null>(
      val2 ? parseStringToDate(val2?.[1], PATTERN) : DEFAULT_TIME
    );

  const [criteriaShow, setCriteria] = React.useState(!!val2);

  const formatDateValue = (date: any) => {
    if (!isNaN(new Date(date)?.getDate())) {
      const dateToIso = new Date(date).toISOString();
      const formattedDate = formatDateWithPattern(dateToIso, PATTERN);
      return formattedDate;
    }
  };

  const addCriteria = () => {
    setCriteria((oldCriteria) => !oldCriteria);
    if (criteriaShow) {
      setCriteriaValue("none");
      handleCriteriaDateChange(DEFAULT_TIME);
    }
  };

  React.useEffect(() => {
    const formatTime = formatDateValue(selectedDate);
    const formatCriteriaTime = formatDateValue(selectedCriteriaDate);

    const firstConditionValues = [value, formatTime];
    const secondConditionValues = [criteriaValue, formatCriteriaTime];

    const firstCondition = firstConditionValues.join(" ");
    const secondCondition = secondConditionValues.join(" ");

    const result = [
      ...(firstCondition !== "none 00:00" ? [firstCondition] : []),
      ...(secondCondition !== "none 00:00" ? [secondCondition] : []),
    ];
    setSelection(result);
  }, [setSelection, value, selectedDate, criteriaValue, selectedCriteriaDate]);

  React.useEffect(() => {
    if (value === "equal") {
      setCriteria(false);
      setCriteriaValue("none");
      handleCriteriaDateChange(DEFAULT_TIME);
    }
  }, [setCriteria, value]);

  const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  const handleCriteriaChange = (
    event: SelectChangeEvent<{ value: unknown }>
  ) => {
    setCriteriaValue(event.target.value as string);
  };

  const renderTimePicker = (value: any, onChange: any, props?: any) => {
    return (
      <TimePicker
        ampm={false}
        autoOk={true}
        openTo="minutes"
        views={["minutes", "seconds"]}
        inputFormat={PATTERN}
        placeholder="HH:MM"
        variant="inline"
        inputVariant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        sx={styles.formInput}
        KeyboardButtonProps={{ style: styles.timeButton }}
        keyboardIcon={<Timer />}
        ToolbarComponent={(props: any) => (
          <>
            <TimePickerToolbar {...props} />
            <Typography
              sx={styles.timeToolbarLabel}
              align="center"
              variant="body2"
            >
              Select duration in hours and minutes
            </Typography>
          </>
        )}
        {...props}
      />
    );
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        disableApply={
          selection.length < 1 ||
          value === "none" ||
          (value !== "none" && selectedDate === DEFAULT_TIME) ||
          (criteriaValue === "none" && selectedCriteriaDate !== DEFAULT_TIME) ||
          (criteriaValue !== "none" && selectedCriteriaDate === DEFAULT_TIME)
        }
      >
        <div>
          <FormControl size="small" sx={styles.formSelect}>
            <Select
              variant="standard"
              native
              value={value}
              onChange={handleChange}
              inputProps={{
                name: "value",
                id: "value",
              }}
            >
              <option value="none" hidden />
              <option value="equal">=</option>
              <option value="greater_than">&gt;</option>
              <option value="less_than">&lt;</option>
            </Select>
          </FormControl>
          {renderTimePicker(selectedDate, handleDateChange)}
        </div>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            size="small"
            disabled={value === "equal" || value === "none"}
            onClick={() => addCriteria()}
            sx={styles.criteriaButton}
          >
            {!criteriaShow ? "add another criteria" : "remove criteria"}
          </Button>
        </Grid>

        {criteriaShow && (
          <div>
            <FormControl size="small" sx={styles.formSelect}>
              <Select
                variant="standard"
                native
                value={criteriaValue}
                onChange={handleCriteriaChange}
                inputProps={{
                  name: "criteriaValue",
                  id: "criteriaValue",
                }}
              >
                {value === "greater_than" ? (
                  <>
                    <option value="none" hidden />
                    <option value="less_than">&lt;</option>
                  </>
                ) : (
                  <>
                    <option value="none" hidden />
                    <option value="greater_than">&gt;</option>
                  </>
                )}
              </Select>
            </FormControl>
            {renderTimePicker(selectedCriteriaDate, handleCriteriaDateChange)}
          </div>
        )}
      </FilterBox>
    </div>
  );
}

const getStyles = (theme: Theme): SxStyleSheet => ({
  formSelect: {
    marginLeft: theme.spacing(2),
  },
  formInput: {
    marginInline: theme.spacing(2),
  },
  criteriaButton: {
    margin: theme.spacing(1),
  },
  timeToolbarLabel: {
    color: theme.palette.primary.light,
    top: "10px",
    position: "absolute",
    width: "100%",
  },
  timeButton: {
    padding: 0,
  },
});
