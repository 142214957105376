import React, { useCallback } from "react";
import { debounce } from "lodash";
import { SearchField } from "@akj-dev/shared-components";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "./Table";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { omit } from "lodash";
import PeopleIcon from "@mui/icons-material/People";
import { constants } from "../../helpers";
import { SortDirectionQueryParam } from "../../types";
import { usePaginator } from "../../hooks/assets";
import Filters from "./Filters";
import { CSVDownload } from "../../shared/CSVDownload";
import { TableType } from "../../helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
}));

const Component = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [inputSearch, setInputSearch] = React.useState(""); // input from user
  const [queryString, setQueryString] = React.useState(""); // debounced value appended to query

  const [query, setQuery] = useQueryParams({
    accounts_ids: ArrayParam,
    clis: ArrayParam,
    end_date: StringParam,
    products_names: ArrayParam,
    sim_serials: ArrayParam,
    start_date: StringParam,
    statuses: ArrayParam,
    vf_direct_resign_status: StringParam,
    terms: ArrayParam,
    date_range_signed_before: StringParam,
    date_range_signed_after: StringParam,
    date_range_end_before: StringParam,
    date_range_end_after: StringParam,
    limit: withDefault(NumberParam, constants.limit.assets),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "id"),
    direction: withDefault(SortDirectionQueryParam, "desc"),
  });

  const resetQuery = () => {
    let newQuery: { [key: string]: any } = {};

    for (let filter in query) {
      newQuery[filter] = undefined;
    }

    setQuery(newQuery);
  };

  // add query string here so it doesn't affect global search field
  const paginator = usePaginator({ query: queryString, ...query });

  const handleChangeInputSearch = (event: any) => {
    setInputSearch(event.target.value || "");
    handleDebouncedSearch(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(
    debounce((value) => {
      setQueryString(value.trim());
    }, 1000),
    []
  );

  const csvFilterParams = omit(query, ["limit", "page", "sort", "direction"]);

  return (
    <Box data-cy="Home" mt={2}>
      <Box alignItems="center" display="flex" mr={1}>
        <Box mr={1}>
          <Typography variant="h2" gutterBottom>
            Assets
          </Typography>
        </Box>
        <Box mr={1} mb={1.6}>
          <PeopleIcon className={classes.icon} fontSize="large" />
        </Box>
        <Box mr={1} mb={2}>
          {paginator.isLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant="h2">
              {paginator?.data?.pagination?.count}
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        border={1}
        borderColor={theme.palette.grey[200]}
        borderRadius="5px"
        boxShadow={2}
        display="flex"
        mb={2}
        p={1}
      >
        <Box width="100%">
          <SearchField
            onChange={handleChangeInputSearch}
            placeholder="Search"
            value={inputSearch}
          />
        </Box>
      </Box>

      <Filters query={query} setQuery={setQuery} resetQuery={resetQuery} />

      <Box mb={1} display="flex" justifyContent="flex-end">
        <CSVDownload
          filterParams={{
            query: inputSearch.length > 0 ? inputSearch : undefined,
            ...csvFilterParams,
          }}
          table={TableType.Assets}
        />
      </Box>

      <Table paginator={paginator} query={query} setQuery={setQuery} />
    </Box>
  );
};

export default Component;
