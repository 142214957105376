import React from "react";
import { Link, useParams } from "react-router-dom";
import { Divider, Paper, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Bars from "./Bars";
import ContractDetails from "./ContractDetails";
import Discounts from "./Discounts";
import { Asset } from "../../../../../types";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 1.5, 1.5),
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
  paper: {
    maxHeight: "100%",
    overflow: "auto",
  },
}));

interface PanelProps {
  children: React.ReactNode;
  name: string;
  section: string;
}

const Panel = ({ children, name, section }: PanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={section !== name}
      id={`tabpanel-${name}`}
      aria-labelledby={`tab-${name}`}
    >
      {section === name && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
};

interface RouteParams {
  section: string;
}

interface Props {
  asset: Asset;
}

const Component = ({ asset }: Props) => {
  const classes = useStyles();
  const { section = "bars" } = useParams<RouteParams>();

  return (
    <div data-cy="Attributes">
      <Paper className={classes.paper} elevation={4}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={section}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            label="Contract Details"
            value="contract"
            component={Link}
            to={`/customers/assets/${asset.id}/contract`}
          />
          <Tab
            label="Bars"
            value="bars"
            component={Link}
            to={`/customers/assets/${asset.id}/bars`}
          />
          <Tab
            label="Discounts"
            value="discounts"
            component={Link}
            to={`/customers/assets/${asset.id}/discounts`}
          />
        </Tabs>

        <Divider className={classes.divider} />

        <div className={classes.content}>
          <Panel name="contract" section={section}>
            <ContractDetails asset={asset} />
          </Panel>
          <Panel name="bars" section={section}>
            <Bars asset={asset} />
          </Panel>
          <Panel name="discounts" section={section}>
            <Discounts asset={asset} />
          </Panel>
        </div>
      </Paper>
    </div>
  );
};

export default Component;
