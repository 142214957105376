import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Error } from "@mui/icons-material";
import FullScreenCenter from "../shared/FullScreenCenter";

export interface ErrorFullScreenProps {
  message: string;
  retry?: () => any;
  variant?: "overlay";
}

export const ErrorFullScreen: React.FC<ErrorFullScreenProps> = ({
  message,
  retry,
  variant,
}) => {
  return (
    <FullScreenCenter
      style={
        variant === "overlay"
          ? { background: "rgba(0,0,0,0.5)", color: "white" }
          : undefined
      }
    >
      <Error fontSize="large" color="inherit" />
      {message && (
        <Box m={1}>
          <Typography variant="h4">{message}</Typography>
        </Box>
      )}
      {retry && (
        <Button variant="contained" onClick={retry}>
          Retry
        </Button>
      )}
    </FullScreenCenter>
  );
};
