import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  styled,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { SxStyleSheet } from "../themes/_base";

/** A card to display simple text data */
type DataCardData = {
  title: string;
  value: string | number;
};
export interface DataCardProps {
  "data-cy"?: string;
  title: string;
  data: string | number | React.ReactElement | DataCardData[];
  callToAction?: string;
  href?: string;
}

export const DataCard = ({
  "data-cy": dataCy,
  title,
  data,
  callToAction,
  href,
}: DataCardProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  let dataDisplay = <div />;

  if (typeof data === "string" || typeof data === "number") {
    dataDisplay = (
      <Typography variant="h1" component="div" align="center">
        {data}
      </Typography>
    );
  } else if (Array.isArray(data)) {
    dataDisplay = (
      <>
        {data.map((d) => (
          <Box sx={styles.item}>
            <Box>{d.value}</Box>
            <Box fontSize={12}>{d.title}</Box>
          </Box>
        ))}
      </>
    );
  } else if (React.isValidElement(data)) {
    dataDisplay = data;
  }

  return (
    <A href={href} data-cy={dataCy}>
      <Card raised sx={styles.card}>
        <CardHeader
          titleTypographyProps={{ align: "center" }}
          sx={styles.cardHeader}
          title={title}
        />
        <CardContent sx={styles.cardContent}>{dataDisplay}</CardContent>
        {!!callToAction && (
          <CardActions sx={styles.cardActions}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <div>{callToAction}</div>
              <ChevronRight fontSize="small" />
            </Box>
          </CardActions>
        )}
      </Card>
    </A>
  );
};

const A = styled("a")({
  textDecoration: "none",
});

const getStyles = (theme: Theme): SxStyleSheet => ({
  item: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: 24,
    paddingRight: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.25),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  card: {
    "&:hover $actions": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cardHeader: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  cardContent: {
    pt: theme.spacing(1),
    pb: theme.spacing(25),
    display: "flex",
    justifyContent: "center",
  },
  cardActions: {
    transition: `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
});
