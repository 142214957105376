import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
} from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { Select, TextField } from "formik-mui";
import { LoaderButton, StatusChip } from "@akj-dev/shared-components";
import { useSaveUserName } from "../../../../../hooks/assets";
import { Asset } from "../../../../../types";

interface FormValues {
  title: string;
  forename: string;
  surname: string;
}

type Props = {
  asset: Asset;
};

export const UpdateUsername = ({ asset }: Props) => {
  const saveUserName = useSaveUserName(asset);

  const validationSchema = yup.object().shape({
    forename: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    surname: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
  });

  const initialValues: FormValues = {
    title: "Mr.",
    forename: "",
    surname: "",
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    console.log(values);
    saveUserName.mutate(values);
    setSubmitting(false);
  };

  return (
    <Card>
      <CardHeader title={"Update Username"} />
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item sm={3}>
                  <Field
                    component={Select}
                    variant="outlined"
                    name="title"
                    label="Title"
                  >
                    <MenuItem value="Dr.">Dr.</MenuItem>
                    <MenuItem value="Miss.">Miss.</MenuItem>
                    <MenuItem value="Mr.">Mr.</MenuItem>
                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                    <MenuItem value="Ms.">Ms.</MenuItem>
                    <MenuItem value="Prof.">Prof.</MenuItem>
                  </Field>
                </Grid>
                <Grid item sm={4}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="forename"
                    label="First Name"
                  />
                </Grid>
                <Grid item sm={5}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="surname"
                    label="Last Name"
                  />
                </Grid>
              </Grid>{" "}
              <Box mt={1}>
                <LoaderButton
                  color="primary"
                  loading={isSubmitting || saveUserName.isLoading}
                  onClick={submitForm}
                >
                  Update
                </LoaderButton>
              </Box>
            </Form>
          )}
        </Formik>
        {saveUserName.isError && (
          <Box my={1}>
            <StatusChip
              type="error"
              message={
                (saveUserName.error as Error).message ||
                "Error updating username"
              }
            />
          </Box>
        )}
        {saveUserName.error instanceof Error && (
          <Box my={1}>
            <StatusChip
              type="error"
              message={saveUserName.error.message || "Error updating username"}
            />
          </Box>
        )}
        {saveUserName.data?.status === "success" && (
          <Box my={1}>
            <StatusChip
              type="success"
              title="Username updated"
              message={"Change will be reflected from tomorrow."}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
