import React from "react";
import { useParams } from "react-router-dom";
import { Alert } from "@akj-dev/shared-components";
import Generic from "./Generic";
import Mobile from "./Mobile";
import Loading from "../../../layout/Loading";
import { useFind } from "../../../hooks/assets";
import { productCategories } from "../../../helpers/constants";

interface RouteParams {
  id: string;
}

const Component = () => {
  const { id } = useParams<RouteParams>();
  const asset = useFind(parseInt(id));

  return (
    <div data-cy="Show">
      <Loading
        error={
          asset.isError
            ? "There was a problem fetching asset details. Please try again."
            : ""
        }
        loading={asset.isLoading}
      >
        {asset.data?.result && (
          <>
            {asset.data?.result?.type === productCategories.Mobile ? (
              <Mobile asset={asset.data?.result} />
            ) : (
              <Generic asset={asset.data?.result} />
            )}
          </>
        )}

        {!asset.data?.result && (
          <Alert message="Can't find asset." type="warning" />
        )}
      </Loading>
    </div>
  );
};

export default Component;
