import React, { ReactNode } from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  children: ReactNode;
  error?: string;
  loading: boolean;
  message?: string;
}

function Loading({ children, error, loading, message }: Props) {
  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (loading) {
    if (message) {
      return (
        <Box pb={1} display="flex">
          <Box pr={0.5}>
            <CircularProgress size={12} />
          </Box>
          <Typography variant="caption">{message}</Typography>
        </Box>
      );
    }

    return <CircularProgress />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default Loading;
