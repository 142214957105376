import hash from "object-hash";
import { post } from "../api/index";

type RequestParams = Record<string, string | number | false | null | undefined>;

/**
 * Idempotency API helpers.
 * See TP42718
 */

/**
 * Generates a unique hash for idempotent requests
 * Based on URL and request params
 */
export function getIdempotencyKey(
  endpoint: string,
  params?: RequestParams,
  identifier?: string
) {
  const hashInput = {
    endpoint,
    params,
    identifier,
  };
  const o = hash.sha1(hashInput);
  return o;
}

/**
 * Makes a backgrounded & idempotent DC API request
 * @param url
 * @param params
 * @param identifier
 */
export function postJSONBackgroundIdempotent<T>(
  url: string,
  params?: RequestParams,
  identifier?: string
): Promise<T> {
  const idempotency_key = getIdempotencyKey(url, params, identifier);
  return new Promise(async (resolve, reject) => {
    let poll: number | null = null;
    const call = async () => {
      try {
        const response: any = await post(
          url,
          {
            ...params,
            idempotency_key,
            background: 1,
          },
          false
        );
        if (response.status !== "pending") {
          if (poll !== null) clearInterval(poll);
          resolve(response);
        }
      } catch (error: any) {
        if (error.status === "error") {
          if (poll !== null) clearInterval(poll);
          reject(error);
        }
      }
    };
    // Call it once immediately
    await call();
    // Then call every 5 seconds until resolution
    poll = window.setInterval(call, 5000);
  });
}
