import React from "react";
import Typography from "@mui/material/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 650,
    },
    row: {
      verticalAlign: "top",
    },
    head: {
      fontWeight: "bold",
    },
  })
);

interface Props {
  rows: any[];
  title?: string;
}

function FieldTable({ title, rows }: Props) {
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      {title && (
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="body1" color="textPrimary">
                {title}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row: any) => (
          <TableRow key={row.name} className={classes.row}>
            <TableCell className={classes.head}>{row.label}</TableCell>
            <TableCell>{row.data}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default FieldTable;
