import React from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box, Typography } from "@mui/material";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";

interface ReferredCreditVetWarningProps {
  companyName: string;
  caseNumber: string;
}
export const ReferredCreditVetWarning = ({
  companyName,
  caseNumber,
}: ReferredCreditVetWarningProps) => (
  <>
    <Alert
      type="warning"
      message={
        <Box>
          <Typography variant="h5">Referred</Typography>
          <Typography variant="body2">
            A{" "}
            <a
              href={getPathForCrossAppRedirect(
                AppSubdomains.SUPPORT,
                `/support/tickets/${caseNumber}`
              )}
            >
              support ticket
            </a>{" "}
            has been created for Giacom to review the credit check result for{" "}
            {companyName}. Giacom will contact you to discuss the credit check.
          </Typography>
        </Box>
      }
    />
  </>
);
