import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  DataTable,
  DataTablePagination,
} from "@akj-dev/shared-components";
import { Box, Button, CircularProgress, Paper, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import makeStyles from "@mui/styles/makeStyles";
import ProductStatusCards from "./ProductStatusCards";
import {
  fetchBroadbandAssets,
  fetchWLRAssets,
  fetchMobileAssets,
  fetchEthernetAssets,
} from "../api/products";
import { formattedDate } from "../helpers/functions";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  tab: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    fontWeight: "normal",
  },
  tabPanel: {
    padding: theme.spacing(0),
  },
}));

function assetsMap(assetArray: any, accountId: String) {
  return assetArray?.data?.results?.map((row: any) => {
    return [
      <Button
        color="primary"
        component={Link}
        size="small"
        to={`/customers/assets/${row.id}`}
      >
        {row.name}
      </Button>,
      row.pin,
      row.status,
      formattedDate(row.contract_start_date_ymd),
      formattedDate(row.contract_end_date_ymd),
      row.contract_length_in_months,
      <Button
        color="primary"
        component={Link}
        size="small"
        to={`/customers/assets/${row.id}`}
      >
        View
      </Button>,
    ];
  });
}

export default function Assets() {
  const classes = useStyles();
  const [index, setIndex] = useState("");
  const handleChange = (event: any, value: any) => {
    setIndex(value);
  };
  const params: any = useParams();
  const accountId = params.accountId;

  const initialState = {
    broadbandAssets: { page: 1, limit: 10 },
    ethernetAssets: { page: 1, limit: 10 },
    mobileAssets: { page: 1, limit: 10 },
    wlrAssets: { page: 1, limit: 10 },
  };

  const [local, setLocal] = useState<any>(initialState);

  const broadbandAssets = useQuery(
    ["customer-broadband-assets", accountId, local.broadbandAssets],
    () =>
      fetchBroadbandAssets({
        ...local.broadbandAssets,
        account: accountId,
      })
  );
  const ethernetAssets = useQuery(
    ["customer-ethernet-assets", accountId, local.ethernetAssets],
    () =>
      fetchEthernetAssets({
        ...local.ethernetAssets,
        account: accountId,
      })
  );
  const mobileAssets = useQuery(
    ["customer-mobile-assets", accountId, local.mobileAssets],
    () =>
      fetchMobileAssets({
        ...local.mobileAssets,
        account: accountId,
      })
  );
  const wlrAssets = useQuery(
    ["customer-wlr-assets", accountId, local.wlrAssets],
    () =>
      fetchWLRAssets({
        ...local.wlrAssets,
        account: accountId,
      })
  );

  const isLoading =
    mobileAssets.isLoading ||
    broadbandAssets.isLoading ||
    ethernetAssets.isLoading ||
    wlrAssets.isLoading;

  const columns = [
    { name: "name", label: "Product Name" },
    { name: "cli", label: "CLI" },
    { name: "status", label: "Status" },
    { name: "contract.contractStartDate", label: "Start" },
    { name: "contract.contractEndDate", label: "End" },
    { name: "contract.contractTerm", label: "Term" },
    { name: "action", label: "" },
    // { name: "contract.productCeasedDate", label: "Cease" }
  ];

  const renderTabs = () => {
    const tabs = [];
    if (broadbandAssets.data?.results?.length > 0) {
      tabs.push(<Tab label="Broadband" value="0" className={classes.tab} />);
      if (!index) setIndex("0");
    }
    if (ethernetAssets.data?.results?.length > 0) {
      tabs.push(<Tab label="Ethernet" value="1" className={classes.tab} />);
      if (!index) setIndex("1");
    }
    if (mobileAssets.data?.results?.length > 0) {
      tabs.push(<Tab label="Mobile" value="2" className={classes.tab} />);
      if (!index) setIndex("2");
    }
    if (wlrAssets.data?.results?.length > 0) {
      tabs.push(<Tab label="WLR" value="3" className={classes.tab} />);
      if (!index) setIndex("3");
    }
    return tabs;
  };

  return (
    <Box mt={2}>
      {isLoading && (
        <Box mt={2} display="flex" justifyContent="center">
          <CircularProgress size={50} />
        </Box>
      )}
      {!isLoading && (
        <>
          <Box mb={2}>
            <ProductStatusCards
              broadbandCount={broadbandAssets.data?.pagination?.count || 0}
              ethernetCount={ethernetAssets.data?.pagination?.count || 0}
              mobileCount={mobileAssets.data?.pagination?.count || 0}
              wlrCount={wlrAssets.data?.pagination?.count || 0}
              isLoading={isLoading}
            />
          </Box>

          {!broadbandAssets.data?.results?.length &&
          !ethernetAssets.data?.results?.length &&
          !mobileAssets.data?.results?.length &&
          !wlrAssets.data?.results?.length ? (
            <Alert type="info" message="No assets found." />
          ) : (
            <Paper elevation={4}>
              <TabContext value={index || "0"}>
                <TabList
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  {renderTabs().map((tab) => tab)}
                </TabList>
                <TabPanel value="0" className={classes.tabPanel}>
                  {broadbandAssets.data?.results?.length > 0 && (
                    <DataTable
                      columns={columns}
                      data={assetsMap(broadbandAssets, accountId)}
                      noDataCTA="Sorry, no broadband products found."
                      loading={broadbandAssets.isLoading}
                      pagination={DataTablePagination.Pages}
                      count={broadbandAssets.data?.pagination.count}
                      page={local.broadbandAssets.page - 1}
                      onChangePage={(event, page) =>
                        setLocal({
                          ...local,
                          broadbandAssets: {
                            ...local.broadbandAssets,
                            page: page + 1,
                          },
                        })
                      }
                      rowsPerPage={local.broadbandAssets.limit ?? 10}
                      onChangeRowsPerPage={(event: any) =>
                        setLocal({
                          ...local,
                          broadbandAssets: {
                            ...local.broadbandAssets,
                            limit: event.target.value,
                          },
                        })
                      }
                    />
                  )}
                </TabPanel>
                <TabPanel value="1" className={classes.tabPanel}>
                  {ethernetAssets.data?.results?.length > 0 && (
                    <DataTable
                      columns={columns}
                      data={assetsMap(ethernetAssets, accountId)}
                      noDataCTA="Sorry, no ethernet products found."
                      loading={ethernetAssets.isLoading}
                      pagination={DataTablePagination.Pages}
                      count={ethernetAssets.data?.pagination.count}
                      page={local.ethernetAssets.page - 1}
                      onChangePage={(event, page) =>
                        setLocal({
                          ...local,
                          ethernetAssets: {
                            ...local.ethernetAssets,
                            page: page + 1,
                          },
                        })
                      }
                      rowsPerPage={local.ethernetAssets.limit ?? 10}
                      onChangeRowsPerPage={(event: any) =>
                        setLocal({
                          ...local,
                          ethernetAssets: {
                            ...local.ethernetAssets,
                            limit: event.target.value,
                          },
                        })
                      }
                    />
                  )}
                </TabPanel>
                <TabPanel value="2" className={classes.tabPanel}>
                  {mobileAssets.data?.results?.length > 0 && (
                    <DataTable
                      columns={columns}
                      data={assetsMap(mobileAssets, accountId)}
                      noDataCTA="Sorry, no mobile products found."
                      loading={mobileAssets.isLoading}
                      pagination={DataTablePagination.Pages}
                      count={mobileAssets.data?.pagination.count}
                      page={local.mobileAssets.page - 1}
                      onChangePage={(event, page) =>
                        setLocal({
                          ...local,
                          mobileAssets: {
                            ...local.mobileAssets,
                            page: page + 1,
                          },
                        })
                      }
                      rowsPerPage={local.mobileAssets.limit ?? 10}
                      onChangeRowsPerPage={(event: any) =>
                        setLocal({
                          ...local,
                          mobileAssets: {
                            ...local.mobileAssets,
                            limit: event.target.value,
                          },
                        })
                      }
                    />
                  )}
                </TabPanel>
                <TabPanel value="3" className={classes.tabPanel}>
                  {wlrAssets.data?.results?.length > 0 && (
                    <DataTable
                      columns={columns}
                      data={assetsMap(wlrAssets, accountId)}
                      noDataCTA="Sorry, no wlr products found."
                      loading={wlrAssets.isLoading}
                      pagination={DataTablePagination.Pages}
                      count={wlrAssets.data?.pagination.count}
                      page={local.wlrAssets.page - 1}
                      onChangePage={(event, page) =>
                        setLocal({
                          ...local,
                          wlrAssets: {
                            ...local.wlrAssets,
                            page: page + 1,
                          },
                        })
                      }
                      rowsPerPage={local.wlrAssets.limit ?? 10}
                      onChangeRowsPerPage={(event: any) =>
                        setLocal({
                          ...local,
                          wlrAssets: {
                            ...local.wlrAssets,
                            limit: event.target.value,
                          },
                        })
                      }
                    />
                  )}
                </TabPanel>
              </TabContext>
            </Paper>
          )}
        </>
      )}
    </Box>
  );
}
