import React, { useRef, useState, MouseEvent as ReactMouseEvent } from "react";
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  styled,
} from "@mui/material";

import { Filter } from "./types";

interface Props {
  filters: Filter[];
  updateFilterVisibility: (filterId: string, value: boolean) => void;
}

// was in packages/affinity-hub-fe/src/api/filter.ts
const MIN_FILTERS_COUNT = 7;

const MoreFilters = ({ filters, updateFilterVisibility }: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const onMoreFiltersClick = (event: ReactMouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  const onClickAway = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  if (filters?.length <= MIN_FILTERS_COUNT) {
    return <></>;
  }

  return (
    <Ctr ref={anchorRef}>
      <Button
        variant="contained"
        color="primary"
        onClick={onMoreFiltersClick}
        data-cy="more-filters-button"
        sx={getStyles.button}
      >
        More filters
      </Button>
      <Popper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={getStyles.popper}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <List id="filters-menu" data-cy="all-filters-items">
                  {filters?.map((filter: Filter, index: any) => (
                    <ListItem
                      sx={getStyles.filterItem}
                      key={`filter-${filter.id}`}
                      data-cy={"filter-item-" + index}
                    >
                      <FilterBlock>
                        <ListItemText
                          sx={getStyles.filterName}
                          primary={filter.name}
                        />
                        <Checkbox
                          sx={getStyles.checkbox}
                          data-cy={"filter-item-checkbox-" + index}
                          checked={filter.showToggle}
                          onChange={(e) =>
                            updateFilterVisibility(filter.id, e.target.checked)
                          }
                        />
                      </FilterBlock>
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Ctr>
  );
};

export default MoreFilters;

const Ctr = styled("div")({
  marginLeft: "auto",
});

const FilterBlock = styled("div")({
  flex: "auto",
});

const getStyles = {
  button: {
    padding: "5px 15px",
  },
  popper: {
    zIndex: 1100,
  },
  filterItem: {
    minWidth: "200px",
    height: "36px",
  },
  filterName: {
    float: "left",
  },
  checkbox: {
    float: "right",
  },
};
