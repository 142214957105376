import React, { useEffect, useState } from "react";
import {
  listItemClasses,
  MenuItem,
  styled,
  Theme,
  useTheme,
} from "@mui/material";
import FilterBox from "./FilterBox";
import { Filter, FetchResourcesHook } from "../types";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
  fetchResourcesHook: FetchResourcesHook;
}

export default function EnumFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  fetchResourcesHook,
  ...props
}: Props) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const [values, setValues] = useState(item.values || []);

  const { data: filterResponse } = fetchResourcesHook(
    item?.resources,
    "", // max 25 items loaded
    { enabled: !item.values?.length }
  );

  useEffect(() => {
    if (filterResponse?.pages?.length) {
      const filterList = filterResponse.pages?.flatMap((page: any) =>
        page?.list?.map((value: string) => value)
      );
      setValues(filterList);
    }
  }, [filterResponse]);

  const handleMultiSelect = (listItem: any) => {
    if (
      !selection.some((current: any) => current === listItem) &&
      options?.MAX_COUNT &&
      selection.length < options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else if (
      !selection.some((current: any) => current === listItem) &&
      !options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else {
      let selectionAfter = selection;
      selectionAfter = selectionAfter.filter(
        (current: any) => current !== listItem
      );
      setSelection([...selectionAfter]);
    }
  };

  const isSelected = (listItem: any) => {
    if (selection.find((current: any) => current === listItem)) {
      return true;
    }
    return false;
  };

  const valueDisplayFunc = item.valueDisplayFunc
    ? item.valueDisplayFunc
    : (val: any) => val;

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <FilterBody theme={theme} id="scrollableDiv">
          {values?.map((value: any) => {
            return (
              <MenuItem
                key={value}
                // button
                onClick={() => handleMultiSelect(value)}
                selected={isSelected(value)}
                sx={styles.filterListValue}
              >
                {valueDisplayFunc(value)}
              </MenuItem>
            );
          })}
        </FilterBody>
      </FilterBox>
    </div>
  );
}

const FilterBody = styled("div")<{ theme: Theme }>(({ theme }) => ({
  maxHeight: 200,
  overflowY: "auto",
  paddingInline: theme.spacing(2),
}));

const getStyles = (theme: Theme) => ({
  filterListValue: {
    [`& .${listItemClasses.root} .${listItemClasses.selected}`]: {
      // todo mui
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
});
