import React, { useState } from "react";
import { format, parse } from "date-fns";
import { Box, TextField } from "@mui/material";
import { DatePicker as ParentDatePicker } from "@mui/x-date-pickers/DatePicker";
import Wrap from "./Wrap";

export const DATE_PATTERN: string = "dd/MM/yyyy";
export const DC_DATE_FORMAT: string = "yyyy-MM-dd";

type Props = {
  selection: Array<string>;
  format?: string;
  label: string;
  onApply: (dates: Array<string>) => void;
};

export function DateRangePicker({ selection, onApply, ...props }: Props) {
  const val1 = selection?.[0];
  const val2 = selection?.[1];

  const [dateSelectedFrom, setDateSelectedFrom] = useState<any>(
    val1 ? parse(val1 ?? "", props.format ?? DC_DATE_FORMAT, new Date()) : null
  );
  const [dateSelectedTo, setDateSelectedTo] = useState<any>(
    val2 ? parse(val2 ?? "", props.format ?? DC_DATE_FORMAT, new Date()) : null
  );

  return (
    <Wrap
      {...props}
      onApply={() => {
        onApply([
          format(dateSelectedFrom, props.format ?? DC_DATE_FORMAT),
          format(dateSelectedTo, props.format ?? DC_DATE_FORMAT),
        ]);
      }}
    >
      <Box pb={2}>
        <ParentDatePicker
          autoFocus
          inputFormat={props.format ?? DATE_PATTERN}
          label={"From"}
          onChange={(date) => setDateSelectedFrom(date)}
          value={dateSelectedFrom}
          maxDate={dateSelectedTo}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
      </Box>
      <ParentDatePicker
        autoFocus
        inputFormat={props.format ?? DATE_PATTERN}
        label={"To"}
        onChange={(date) => setDateSelectedTo(date)}
        value={dateSelectedTo}
        minDate={dateSelectedFrom}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size="small"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
    </Wrap>
  );
}
