import React, { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box } from "@mui/material";
import { AccountDetails } from "./sections/AccountDetails";
import { CompanyAddress } from "./sections/CompanyAddress/CompanyAddress";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { FetchWLR3AddressSearch } from "../AddressPicker/types";
import { ContactDetails } from "./sections/CompanyAddress/ContactDetails";
import { BillingAddress } from "./sections/BillingAddress";
import { Payment } from "./sections/Payment";
import { BillOptions } from "./sections/BillOptions";
import MarketingPreferences from "./sections/MarketingPreferences";
import { Alert } from "../../Alert/Alert";
import { defaultValues as _defaultValues } from "./defaultValues";

export interface CreateAccountFormProps {
  /* Action to fire when form submits */
  onSubmit: (params: CreateAccountFormValues) => void;
  /* Account Settings - this form feature toggles some fields for VF Direct etc */
  accountSettings: AccountSettings;
  /* For DC address lookup API - required param in some circumstances */
  accountId?: string;
  /* DC API for address lookup */
  api: FetchWLR3AddressSearch;
  defaultValues?: CreateAccountFormValues;
}

export type CreateAccountFormValues = Partial<typeof _defaultValues>;

export const CreateAccountForm = React.forwardRef(
  (
    {
      onSubmit,
      accountSettings,
      accountId,
      api,
      defaultValues,
    }: CreateAccountFormProps,
    ref
  ) => {
    const methods = useForm({
      // Note: No validation schema here as we'd need to mirror a lot of conditional logic.
      // See validation on individual fields in sections.

      defaultValues: useMemo(() => {
        // Accept new default values from host
        return { ..._defaultValues, ...defaultValues };
      }, [defaultValues]),
    });

    useEffect(() => {
      methods.reset({
        ...methods.getValues(),
        ...defaultValues,
      });
    }, [methods, defaultValues]);

    let index = -1;

    const billing_address_same = methods.watch("billing_address_same");
    return (
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          ref={ref as any}
          id="CreateAccountForm"
        >
          <AccountDetails cardIndex={cardIndexes[++index]} />
          <CompanyAddress
            cardIndex={cardIndexes[++index]}
            accountSettings={accountSettings}
            accountId={accountId}
            api={api}
          />
          {!billing_address_same && (
            <BillingAddress
              cardIndex={cardIndexes[++index]}
              accountId={accountId}
              api={api}
            />
          )}
          <ContactDetails
            cardIndex={cardIndexes[++index]}
            accountSettings={accountSettings}
          />
          <Payment cardIndex={cardIndexes[++index]} />
          {accountSettings.as_can_add_bill_options === "1" && (
            <BillOptions cardIndex={cardIndexes[++index]} />
          )}
          <MarketingPreferences
            cardIndex={cardIndexes[++index]}
            accountSettings={accountSettings}
          />
          {Object.keys(methods.formState.errors).length > 0 && (
            <Box my={1}>
              <Alert
                type="error"
                message="Please correct the errors above to continue."
              />
            </Box>
          )}
        </form>
      </FormProvider>
    );
  }
);

const cardIndexes = ["A", "B", "C", "D", "E", "F", "G", "H"];
