import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  listItemClasses,
  MenuItem,
  styled,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import FilterBox from "./FilterBox";
import { getFilterValues } from "../utils";
import { formatDateToMonthYear } from "../utils";
import { Filter, FetchResourcesHook } from "../types";
import { SxStyleSheet } from "../../themes/_base";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
  fetchResourcesHook: FetchResourcesHook;
}

const SKIP: number = 20;

export default function IdentifierFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  fetchResourcesHook,
  ...props
}: Props) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const [search, setSearch] = useState("");

  const {
    data: filterResponse,
    fetchNextPage: fetchNextFilterPage,
    isLoading,
    hasNextPage,
  } = fetchResourcesHook(item?.resources, search, {
    getNextPageParam: (lastPage: any) => {
      const limit = lastPage?.pageSize * lastPage?.page + 1;
      return lastPage?.page < limit / lastPage?.pageSize &&
        Boolean(lastPage?.list?.length)
        ? lastPage?.pageSize + lastPage?.page * SKIP
        : undefined;
    },
  });

  const [filter, setFilter] = useState<any>();
  useEffect(() => {
    if (filterResponse?.pages?.length) {
      const filterList: any = [];

      filterResponse.pages?.forEach((page: any) => {
        page?.list?.forEach((value: any) => {
          filterList.push(value);
        });
      });

      setFilter(filterList);
    }
  }, [filterResponse]);

  const [showNotFound, setShowNotFound] = useState(false);
  useEffect(() => {
    if (search !== "") {
      getFilterValues(
        item?.resources?.slice(4) + `?search-query=${search}`
      ).then((data) => {
        if (data.list.length === 0) {
          setShowNotFound(true);
        } else {
          setShowNotFound(false);
          setFilter(data.list);
        }
      });
    }
  }, [search, setFilter, item]);

  const handleMultiSelect = (listItem: any) => {
    if (
      !selection.some((current: any) => current === listItem) &&
      options?.MAX_COUNT &&
      selection.length < options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else if (
      !selection.some((current: any) => current === listItem) &&
      !options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else {
      let selectionAfter = selection;
      selectionAfter = selectionAfter.filter(
        (current: any) => current !== listItem
      );
      setSelection([...selectionAfter]);
    }
  };

  const isSelected = (listItem: any) => {
    if (selection.find((current: any) => current === listItem)) {
      return true;
    }
    return false;
  };

  const onScroll = (e: any) => {
    const buffer = 2;
    const scrolled = e.target.scrollHeight - e.target.scrollTop;
    const total = e.target.clientHeight + buffer;
    const hitBottom = scrolled <= total;
    if (hitBottom && hasNextPage) {
      fetchNextFilterPage();
    }
  };

  const formatValue = (item: any, value: any) => {
    if (item?.name === "Bill Period") {
      return (
        <MenuItem
          key={value}
          // button
          onClick={() => handleMultiSelect(value)}
          selected={isSelected(value)}
          sx={styles.filterListValue}
        >
          {formatDateToMonthYear(value)?.toLowerCase()}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          key={value}
          // button
          onClick={() => handleMultiSelect(value)}
          selected={isSelected(value)}
          sx={styles.filterListValue}
        >
          {item.valueDisplayFunc
            ? item.valueDisplayFunc(value)
            : value === "false"
            ? "Unassigned"
            : value}
        </MenuItem>
      );
    }
  };
  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        {options.SEARCH_BAR && (
          <TextField
            size="small"
            value={search}
            sx={styles.filterSearch}
            placeholder="Search for CLI/Identifier"
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        )}
        <FilterBody
          theme={theme}
          key={item?.name}
          id="scrollableDiv"
          onScroll={onScroll}
        >
          {showNotFound || (!Boolean(filter?.length) && !isLoading) ? (
            <Loader>Nothing was found</Loader>
          ) : (
            filter?.map((value: any) => {
              return formatValue(item, value);
            })
          )}
          {isLoading ? (
            <Loader>
              <CircularProgress color="primary" />
            </Loader>
          ) : (
            <></>
          )}
        </FilterBody>
      </FilterBox>
    </div>
  );
}

const Loader = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100px",
  flex: 1,
});

const FilterBody = styled("div")<{ theme: Theme }>(({ theme }) => ({
  maxHeight: 200,
  overflowY: "auto",
  paddingInline: theme.spacing(2),
}));

const getStyles = (theme: Theme): SxStyleSheet => ({
  filterSearch: {
    paddingInline: theme.spacing(2),
  },
  filterListValue: {
    textTransform: "capitalize",
    [`& .${listItemClasses.root} .${listItemClasses.selected}`]: {
      // todo mui ?
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
});
