import React, { FormEvent, useState } from "react";
import { useQueryClient } from "react-query";

import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Alert } from "@akj-dev/shared-components";

import Bar from "./components/Bar";

import { Asset, AttributeGroup, Bar as BarType } from "../../../../../../types";
import {
  useCheckBarsCompatibilities,
  useSaveBars,
} from "../../../../../../hooks/assets";

interface Props {
  asset: Asset;
}

const Component = ({ asset }: Props) => {
  const queryClient = useQueryClient();

  // Find the Bars in the grouped_attributes_with_values
  const attributes =
    asset.grouped_attributes_with_values.find(
      (group: AttributeGroup) => group.name === "Bars"
    )?.attributes ?? [];

  const [bars, setBars] = useState(
    attributes.map((bar: BarType) => ({
      name: bar.attribute.name,
      active: bar.value === "1",
      edited: false,
    }))
  );

  const disable =
    asset.status === "Disconnected" || asset.status === "Terminated";

  const checkBarsCompatibilities = useCheckBarsCompatibilities(
    asset,
    bars.filter((bar: any) => bar.active).map((bar: any) => bar.name),
    { enabled: disable ? false : true } // don't call for dc'd or term'd assets
  );

  const saveBars = useSaveBars(asset);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    saveBars.mutate(
      bars.filter((bar: any) => bar.edited),
      {
        onSuccess: () => {
          setBars(
            attributes.map((bar: BarType) => ({
              name: bar.attribute.name,
              active: bar.value === "1",
              edited: false,
            }))
          );
          // invalidate asset to refresh bar information
          queryClient.invalidateQueries("assets");
        },
      }
    );
  };

  return (
    <form autoComplete="off" data-cy="Bars" onSubmit={handleSubmit}>
      <Grid container>
        {attributes.map((bar: BarType) => {
          const found = bars.find(
            (b: BarType) => b.name === bar.attribute.name
          );

          const compatible =
            found.active || bar.value === "1"
              ? true
              : checkBarsCompatibilities.data?.valid_extra_bars.find(
                  (name: string) => name === bar.attribute.name
                )
              ? true
              : false;

          return (
            <Grid container item xs={6}>
              <Bar
                active={found.edited ? found.active : bar.value === "1"}
                key={bar.name}
                compatible={compatible}
                description={bar.description}
                label={bar.attribute.label}
                loading={checkBarsCompatibilities.isLoading}
                name={bar.attribute.name}
                disable={disable}
                onChange={(name, active) => {
                  setBars(
                    bars.map((b: any) => {
                      let isActive = b.active;
                      let isEdited = b.edited;

                      if (b.name === name) {
                        isActive = active;
                        isEdited =
                          active !== (bar.value === "1" ? true : false);
                      }

                      return {
                        ...b,
                        active: isActive,
                        edited: isEdited,
                      };
                    })
                  );
                }}
                status={bar.last_set?.request.status}
                lastUpdate={bar.last_set?.last_update}
                platformUsername={bar.last_set?.request.platform_user_name}
                type={bar.last_set?.type}
                wasChanged={found.edited}
                alertText={
                  bar.attribute.name === "ACDL"
                    ? "Applying Admin/Lost SIM bar will suspend all services and allocations. If this number has data assigned to it for sharing across an account note that suspending this number will suspend the sharing plan and mean each number will be charged individually. Please assign a new lead   number if applicable before suspending to avoid high charges. Applying this bar will not remove any recurring charges but will make the number unusable until lifted."
                    : ""
                }
              />
            </Grid>
          );
        })}
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              {checkBarsCompatibilities.isLoading && (
                <Typography variant="caption">
                  <CircularProgress size={12} /> Checking compatibility
                </Typography>
              )}
              {checkBarsCompatibilities.isError && (
                <Alert type="error" message="Can't check bars compatibility" />
              )}
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row-reverse"
                mt={2}
              >
                <Box>
                  <Button
                    color="primary"
                    disabled={
                      saveBars.isLoading ||
                      !bars.filter((bar: any) => bar.edited).length
                    }
                    size="medium"
                    startIcon={
                      checkBarsCompatibilities.isLoading ? (
                        <CircularProgress size={16} />
                      ) : null
                    }
                    type="submit"
                    variant="contained"
                  >
                    {saveBars.isLoading ? "Saving" : "Submit Order"}
                  </Button>
                </Box>
                <Box mr={1}>
                  <Typography variant="caption">
                    Please note any network changes
                    <br />
                    may take up to 24 hours to be applied.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {saveBars.isSuccess && saveBars.data?.case_number !== "" && (
          <Grid item xs={12}>
            <Box pt={2}>
              <Alert
                type="success"
                message={`Bar change submitted. The bar configuration will change once completed. Case #${saveBars.data?.case_number} was created.`}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Component;
