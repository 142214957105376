import React from "react";
import { useQuery } from "react-query";
import { Switch, Route, useParams, NavLink } from "react-router-dom";
import { useAuth } from "@akj-dev/affinity-platform";
import { Alert } from "@akj-dev/shared-components";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CustomerDetails from "../components/CustomerDetails";
import Assets from "../components/Assets";
import Contacts from "../components/Contacts";
import Asset from "../components/Asset";
import CreditCheck from "../components/CreditCheck";
import { getCustomerById } from "../api/customers";

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  customerDetails: {
    marginTop: 20,
    marginBottom: 5,
  },
  button: {
    color: theme.palette.primary.dark,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
    },
    marginRight: 16,
  },
  toolbar: {
    padding: theme.spacing(0, 2, 0),
  },
}));

export default function SearchCustomerRoutes() {
  const classes = useStyles();
  const theme = useTheme();

  const params: any = useParams();
  const { accountMeta } = useAuth();
  const accountId = params.accountId;
  const activeButtonStyles: object = {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  };
  const customerAccount = useQuery(["customer", accountId], () =>
    getCustomerById(accountMeta.id, accountId)
  );

  const account = customerAccount?.data?.data?.results[0];

  if (customerAccount.isError) {
    return (
      <Box pt={2}>
        <Alert message="Failed to load the customer." type="error" />
      </Box>
    );
  }

  return (
    <>
      {!customerAccount.isLoading && (
        <Typography
          variant="h2"
          className={classes.customerDetails}
          gutterBottom
        >
          {`${account?.name} - ${account?.vf_billing_reference}`}
        </Typography>
      )}
      <AppBar
        position="static"
        elevation={0}
        color="transparent"
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Button
            to={`/customers/${accountId}`}
            color="primary"
            component={NavLink}
            className={classes.button}
            activeStyle={activeButtonStyles}
            isActive={(match: any) => (match ? match.isExact : false)}
            disableRipple
          >
            Details
          </Button>
          <Button
            to={`/customers/${accountId}/assets`}
            color="primary"
            component={NavLink}
            className={classes.button}
            activeStyle={activeButtonStyles}
            isActive={(match: any) => (match ? match.isExact : false)}
            disableRipple
          >
            Assets
          </Button>
          <Button
            to={`/customers/${accountId}/contacts`}
            color="primary"
            component={NavLink}
            className={classes.button}
            activeStyle={activeButtonStyles}
            isActive={(match: any) => (match ? match.isExact : false)}
            disableRipple
          >
            Contacts
          </Button>
          <Button
            to={`/customers/${accountId}/credit-check`}
            color="primary"
            component={NavLink}
            className={classes.button}
            activeStyle={activeButtonStyles}
            isActive={(match: any) => (match ? match.isExact : false)}
            disableRipple
          >
            Credit Check
          </Button>
        </Toolbar>
      </AppBar>
      <Route exact path="/customers/:accountId">
        <CustomerDetails />
      </Route>
      <Route path="/customers/:accountId/assets">
        <Switch>
          <Route exact path="/customers/:accountId/assets">
            <Assets />
          </Route>
          <Route path="/customers/:accountId/assets/:productId">
            <Asset />
          </Route>
        </Switch>
      </Route>
      <Route path="/customers/:accountId/contacts">
        <Contacts />
      </Route>
      <Route path="/customers/:accountId/credit-check">
        <CreditCheck />
      </Route>
    </>
  );
}
