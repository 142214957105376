import React, { useState, MouseEvent } from "react";
import styled from "styled-components";

import { Avatar, Menu, MenuItem } from "@mui/material";

import {
  AuthCookies,
  getCookieValue,
  useAuth,
} from "@akj-dev/affinity-platform";

import { LogoutDialog } from "../auth/LogoutDialog";
import config from "../../config";

import GlobalSearchToolbar from "./GlobalSearchToolbar";

const Outer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  height: 65px;
  width: 100%;
  padding: 0 20px;
  background: ${(p) => p.theme.palette.grey[100]};
  transition: border-color 1s;
  text-align: left;
  color: white;
  justify-content: space-between;
`;

const User = styled.div`
  display: flex;
  align-items: center;
`;

const UserMeta = styled.div`
  font-size: 14px;
  margin-right: 16px;
  font-weight: bold;
  color: ${(p) => p.theme?.palette.grey[500] || "#524b65"};
`;

const UserMetaDim = styled(UserMeta)`
  opacity: 0.5;
`;

export const AffinityNavBar = () => {
  const { accountMeta, accountSettings } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [logoutOpen, setLogoutOpen] = useState(false);

  const open = Boolean(anchorEl);
  const handleMenu = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Quick fix for TP30599 will need to come back to for a more futureproof solution
  const redirectToUsersApp = () => {
    //Create the form
    const uatBannerAuthApi = getCookieValue(AuthCookies.UAT_BANNER_AUTH_API);
    const form = document.createElement("form");
    form.method = "post";
    form.action = !uatBannerAuthApi
      ? config.usersAppUrl[process.env.REACT_APP_STAGE || ""] +
        "/login-from-refresh-token"
      : uatBannerAuthApi.replace("/api/v2/", "/login-from-refresh-token");
    form.target = "_blank";

    //Append an input to the form
    const input = document.createElement("input");
    input.type = "text";
    input.name = "refresh_token";
    input.value = getCookieValue(AuthCookies.REFRESH_TOKEN) || "";
    form.appendChild(input);

    //add form to the document
    document.body.appendChild(form);

    //submit the form to allow the redirect
    form.submit();
  };

  return (
    <Outer>
      {accountSettings.can_use_platform_global_search === "1" ? (
        <GlobalSearchToolbar />
      ) : (
        <div />
      )}
      <LogoutDialog open={logoutOpen} onClose={() => setLogoutOpen(false)} />
      <User>
        <UserMetaDim>{accountMeta.name}</UserMetaDim>
        <Avatar onClick={handleMenu} />
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {accountSettings.allow_manage_users_in_platform === "1" && (
            <MenuItem onClick={() => redirectToUsersApp()}>
              Manage Users
            </MenuItem>
          )}
          <MenuItem onClick={() => setLogoutOpen(true)}>Logout</MenuItem>
        </Menu>
      </User>
    </Outer>
  );
};
