export const limit = {
  customers: 10,
  assets: 10,
} as const;

export const sortDirections = ["asc", "desc", "none"] as const;

export const productCategories = {
  Mobile: "Mobile",
  MobileBoltOn: "MobileBoltOn",
};

export enum TableType {
  Customers,
  Assets,
}
