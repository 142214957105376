import { getErrorMessage } from "@akj-dev/affinity-platform";
import { format, parseISO } from "date-fns";

export function formattedDate(
  dateTimeString: string,
  pattern: string = "dd/MM/yyyy"
): string {
  try {
    const date = parseISO(dateTimeString);

    return format(date, pattern);
  } catch (e) {
    console.log(getErrorMessage(e));
  }

  return dateTimeString;
}

export function formattedDateTime(
  dateTimeString: string,
  pattern: string = "HH:mm dd/MM/yyyy"
): string {
  try {
    const date = parseISO(dateTimeString);

    return format(date, pattern);
  } catch (e) {
    console.log(getErrorMessage(e));
  }

  return dateTimeString;
}

export function formattedTime(
  dateTimeString: string,
  pattern: string = "HH:mm"
): string {
  try {
    const date = parseISO(dateTimeString);

    return format(date, pattern);
  } catch (e) {
    console.log(getErrorMessage(e));
  }

  return dateTimeString;
}
