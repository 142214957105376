type configProps = {
  [key: string]: any;
};

export const config: configProps = {
  sentryDSN: "", // TODO: Platform specific sentry
  // Users app /api/v2/ endpoint for login refresh token flow & direction to Kong API
  usersAppUrl: {
    development: "http://localhost:8070",
    production: "https://comms-users.cloud.market",
    "affinity-support-new-prod": "https://comms-users.cloud.market",
    uat: "https://extusers.uat.aurora.io",
  },
  authAPIBaseUrl: {
    // by process.env.REACT_APP_STAGE (set in package.json tasks)
    development: "http://localhost:8070/ExternalServices/v1/ExternalUsers/",
    uat: "https://extusers.uat.aurora.io/api/v2/",
    production: "https://comms-users.cloud.market/api/v2/",
    "affinity-support-new-prod": "https://comms-users.cloud.market/api/v2/",
  },
};

export default config;
