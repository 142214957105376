import React, { ReactNode } from "react";
import { styled, TableCell, Theme, useTheme } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import { DataTableSortOrder } from "./DataTable";
import { SxStyleSheet } from "../themes/_base";

interface SortableColumnHeaderProps {
  dir: DataTableSortOrder;
  onClick: () => void;
  children: ReactNode;
}

export const SortableColumnHeader = (props: SortableColumnHeaderProps) => {
  const theme = useTheme();
  const styles = getStyles(props.dir);

  return (
    <TableCell onClick={props.onClick} sx={styles.tableCell}>
      <InnerDiv>
        <Label theme={theme}>{props.children}</Label>
        <ArrowDownward sx={styles.icon} />
      </InnerDiv>
    </TableCell>
  );
};

const InnerDiv = styled("div")({
  display: "flex",
  alignItems: "center",
});

const Label = styled("div")<{ theme: Theme }>(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const getStyles = (dir: DataTableSortOrder): SxStyleSheet => ({
  tableCell: {
    cursor: "pointer",
  },
  icon: {
    visibility: dir === DataTableSortOrder.None ? "hidden" : "visible",
    transform: dir === DataTableSortOrder.Asc ? "rotate(180deg)" : "none",
    transition: "transform .3s",
  },
});
