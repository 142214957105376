import React from "react";
import { styled, TextField, Theme, useTheme } from "@mui/material";
import FilterBox from "./FilterBox";
import { Filter } from "../types";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

export default function TextFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const theme = useTheme();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueSelection = value.split(",");
    setSelection(valueSelection);
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <Div theme={theme}>
          <TextField
            size="small"
            name="phoneNumber"
            value={selection}
            onChange={handleInput}
            placeholder="Comma separated values"
            fullWidth
          />
        </Div>
      </FilterBox>
    </div>
  );
}

const Div = styled("div")<{ theme: Theme }>(({ theme }) => ({
  paddingInline: theme.spacing(2),
}));
