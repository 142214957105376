import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Box, CircularProgress, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import MuiTableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import { AddressFields } from "./AddressFields";
import { ActionButtons } from "./ActionButtons";
import { getCustomerById } from "../api/customers";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
  useAuth,
} from "@akj-dev/affinity-platform";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    row: {
      verticalAlign: "middle",
    },
    test: {
      "& input:disabled": {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
      },
    },
  })
);

const TableRow = withStyles({
  root: {
    "&:last-child td": {
      borderBottom: 0,
    },
  },
})(MuiTableRow);

function createData(
  name: string,
  label: string,
  data: string | null,
  isRowEditable = false
) {
  return { name, label, data, isRowEditable };
}

const rows = (account: any) => {
  return [
    createData("accountName", "Company Name", account.name),
    createData(
      "address",
      "Company Address",
      account.shipping_address_street +
        "\n" +
        account.shipping_address_postalcode,
      true
    ),
    createData(
      "billingAddress",
      "Billing Address",
      account.billing_address_street +
        "\n" +
        account.billing_address_postalcode,
      true
    ),
    createData("phoneNumber", "Phone Number", account.phone_office, true),
    createData(
      "mobileNumber",
      "Mobile Number",
      account.mobile_phone_number === "dnb_data"
        ? "N/A"
        : account.mobile_phone_number,
      true
    ),
    createData("accountType", "Company Type", account.business_entity_type),
  ];
};

export default function CustomerDetails() {
  const { accountMeta } = useAuth();
  const classes = useStyles();
  const [fields, setFields] = useState<any>({
    address: {
      building: "",
      street: "",
      town: "",
      region: "",
      postcode: "",
    },
    billingAddress: {
      building: "",
      street: "",
      town: "",
      region: "",
      postcode: "",
    },
    primaryContact: "",
    phoneNumber: "",
    mobileNumber: "",
  });
  const params: any = useParams();
  const accountId = params.accountId;

  const customerAccount = useQuery(["customer", accountId], () =>
    getCustomerById(accountMeta.id, accountId)
  );

  const account = customerAccount?.data?.data?.results[0];
  const data =
    !customerAccount.isLoading && account ? rows(account) : undefined;
  const isBillingAddressSame =
    data?.find((row) => row.name === "address")?.data ===
    data?.find((row) => row.name === "billingAddress")?.data;

  if (!customerAccount.isLoading && account !== null) {
    return (
      <Box mt={2}>
        <ActionButtons
          leftLabel="New Sale"
          leftTo={getPathForCrossAppRedirect(AppSubdomains.SALES, "/sales/new")}
          rightLabel="New Ticket"
          rightTo={getPathForCrossAppRedirect(
            AppSubdomains.SUPPORT,
            `/support/new-ticket?account_id=${account.id}`
          )}
        />
        <Paper elevation={4} className={classes.paper}>
          <Table>
            <TableBody>
              {rows(account).map((row) => {
                const isAddress = row.name === "address";
                const isBillingAddress = row.name === "billingAddress";
                if (isBillingAddress && isBillingAddressSame) return <></>;
                return (
                  <TableRow key={row.name} className={classes.row}>
                    <TableCell>
                      {isBillingAddressSame && isAddress
                        ? "Company & Billing Address"
                        : row.label}
                    </TableCell>
                    <TableCell className={classes.test}>
                      {isAddress ? (
                        <AddressFields
                          address={row.data}
                          fields={fields.address}
                          onChange={(key, value) =>
                            setFields({
                              ...fields,
                              address: {
                                ...fields.address,
                                [key]: value,
                              },
                              ...(isBillingAddressSame && {
                                billingAddress: {
                                  ...fields.billingAddress,
                                  [key]: value,
                                },
                              }),
                            })
                          }
                          isFormEditable={false}
                        />
                      ) : isBillingAddress && !isBillingAddressSame ? (
                        <AddressFields
                          address={row.data}
                          fields={fields.billingAddress}
                          onChange={(key, value) =>
                            setFields({
                              ...fields,
                              billingAddress: {
                                ...fields.billingAddress,
                                [key]: value,
                              },
                            })
                          }
                          isFormEditable={false}
                        />
                      ) : (
                        <InputBase
                          value={fields[row.name] || row.data}
                          disabled={true}
                          onChange={(event) =>
                            setFields({
                              ...fields,
                              [row.name]: event.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    );
  } else {
    return (
      <Box mt={2} display="flex" justifyContent="center">
        <CircularProgress size={50} />
      </Box>
    );
  }
}
