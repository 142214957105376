import React from "react";
import { Outer } from "../../components/navigation/SubNav/SubNavComponents";
import { ProductContainer } from "../../components/LayoutContainers";
import { Box, CircularProgress } from "@mui/material";
import { SkeletonList } from "./SkeletonList";

export const ProductLoadingScreen = () => {
  return (
    <ProductContainer>
      <Outer>
        <SkeletonList />
      </Outer>
      <Box mx={2} my={1}>
        <CircularProgress />
      </Box>
    </ProductContainer>
  );
};
