import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { affinityColors as colors } from "../themes/affinityTheme/styleData";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ChipTypeMap } from "@mui/material/Chip/Chip";

export interface ErrorChipProps extends OverridableComponent<ChipTypeMap> {
  label: ReactNode;
}

export function ErrorChip({ label, ...other }: ErrorChipProps) {
  return (
    <Chip
      avatar={
        <Avatar
          style={{
            backgroundColor: colors.affinityRedDarker.value,
            color: "white",
          }}
        >
          <CloseIcon />
        </Avatar>
      }
      label={label}
      style={{
        color: "white",
        backgroundColor: colors.affinityRedDark.value,
        margin: "0 15px",
      }}
      {...other}
    />
  );
}

ErrorChip.propTypes = {
  label: PropTypes.string,
};
