import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { TextField } from "@mui/material";

type Props = {
  afterTyping: (value?: string) => void;
  value: string;
  wait: number;
  numbersOnly?: boolean;
  [x: string]: any;
};

const Component = ({
  value,
  afterTyping,
  wait,
  numbersOnly,
  ...props
}: Props) => {
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleDebouncedChange = useMemo(
    () =>
      debounce((input: string) => {
        afterTyping(input);
      }, wait),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <TextField
      variant="standard"
      {...props}
      onChange={(event) => {
        if (numbersOnly && !/^\d+$|^$/.test(event.target.value)) return;
        setVal(event.target.value);
        handleDebouncedChange(event.target.value);
      }}
      value={val}
    />
  );
};

export default Component;
