import { get } from "./";

export function fetchMobileAssets(params: any) {
  return get(`Product/Instance/Mobile`, params);
}

export function fetchBroadbandAssets(params: any) {
  return get(`Product/Instance/Broadband`, params);
}

export function fetchEthernetAssets(params: any) {
  return get(`Product/Instance/LeasedLine`, params);
}

export function fetchWLRAssets(params: any) {
  return get(`Product/Instance/WLR`, params);
}

export function fetchProductMix(accountId: any) {
  return get(`Account/GetProductMix/${accountId}`);
}
