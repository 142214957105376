import React from "react";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer,
  SwitchRoutes,
  BrandedDocumentTitle,
  StandaloneAppWrapper,
  SubNav,
} from "@akj-dev/shared-components";
import { routes } from "./routes";
import { Route, Switch, useLocation } from "react-router-dom";
import Asset from "./screens/Assets/Show";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryCache = new QueryCache(); // possible attributes are onError and onSuccess

const queryClient = new QueryClient({
  queryCache, // Set Cache system
  defaultOptions: {
    // Default options for some queries
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30,
    },
  },
});

const AppStandaloneComponent = () => {
  const { pathname } = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <ProductContainer>
        <BrandedDocumentTitle title="Customers" />
        {pathname.includes(routes.rootPath) && (
          <SubNav routes={routes} rootTitle="Customers" />
        )}
        <ViewContainer>
          <ContentContainer>
            <Switch>
              <Route path={routes.rootPath + "/assets/:id/:section?"}>
                <Asset />
              </Route>
              <SwitchRoutes routes={routes} />
            </Switch>
          </ContentContainer>
        </ViewContainer>
      </ProductContainer>
      {process.env.NODE_ENV !== "production" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
};

export const AppStandalone = () => (
  <StandaloneAppWrapper root={routes.rootPath}>
    <AppStandaloneComponent />
  </StandaloneAppWrapper>
);
