import React, { useState } from "react";

import {
  Autocomplete,
  FilterBar,
  FilterBarPreviewProps,
  TextField,
} from "../../../shared/filters";
import { useAccounts, useContacts } from "../../../hooks/autocomplete";

interface FiltersProps {
  query: any;
  setQuery: (params: any) => void;
  resetQuery: () => void;
}

export default function Filters({ query, setQuery, resetQuery }: FiltersProps) {
  const [accountsQuery, searchAccounts] = useState("");
  const [contactsQuery, searchContacts] = useState("");

  const accounts = useAccounts(accountsQuery);
  const contacts = useContacts(contactsQuery);
  const statuses = ["Active", "Archived", "Prospect"];

  let preview: FilterBarPreviewProps[] = [];

  const filterDuplicatesByFullName = (data: any[]): any[] => {
    return data.filter((obj, index, arr) => {
      const fullName = `${obj.first_name} ${obj.last_name}`;
      const isUnique =
        index ===
        arr.findIndex((o) => `${o.first_name} ${o.last_name}` === fullName);
      return isUnique;
    });
  };

  if (query.companies_ids?.length > 0) {
    preview.push({
      label: "Company Name",
      values: accounts.data?.data?.results
        .filter((account: any) => query.companies_ids?.includes(account.id))
        .map((account: any) => account.name),
      onDelete: () => setQuery({ companies_ids: [] }),
    });
  }

  if (query.postcodes?.length > 0) {
    preview.push({
      label: "Postcode",
      max: 3,
      values: query.postcodes,
      onDelete: () => setQuery({ postcodes: [] }),
    });
  }

  if (query.primary_contacts_names?.length > 0) {
    const filteredContacts = contacts.data?.data?.results?.filter(
      (contact: any) =>
        query.primary_contacts_names?.includes(
          `${contact.first_name} ${contact.last_name}`
        )
    );
    const uniqueValues = filterDuplicatesByFullName(filteredContacts);

    preview.push({
      label: "Primary Contact",
      values: uniqueValues.map((contact: any) => contact.nice_name),
      onDelete: () => setQuery({ primary_contacts_names: [] }),
    });
  }

  if (query.statuses?.length > 0) {
    preview.push({
      label: "Status",
      values: query.statuses,
      onDelete: () => setQuery({ statuses: [] }),
    });
  }

  return (
    <FilterBar onReset={resetQuery} preview={preview} title="Filter">
      <Autocomplete
        defaultValue={accounts.data?.data?.results.filter((account: any) =>
          query.companies_ids?.includes(account.id)
        )}
        loading={accounts.isLoading}
        label="Company Name"
        labelFrom="name"
        multiple
        onSearch={(query) => searchAccounts(query)}
        onSelect={(accounts) =>
          setQuery({
            companies_ids: accounts.map((account: any) => account.id),
          })
        }
        options={accounts.data?.data?.results ?? []}
        valueFrom="id"
      />
      <TextField
        label="Postcode"
        onApply={(postcodes) => setQuery({ postcodes })}
        values={query?.postcodes ?? []}
      />
      <Autocomplete
        defaultValue={contacts.data?.data?.results.filter((contact: any) =>
          query.primary_contacts_ids?.includes(contact.id)
        )}
        loading={contacts.isLoading}
        label="Primary Contact"
        labelFrom="nice_name"
        multiple
        onSearch={(query) => searchContacts(query)}
        onSelect={(contacts) =>
          setQuery({
            primary_contacts_names: contacts.map(
              (contact: any) => `${contact.first_name} ${contact.last_name}`
            ),
          })
        }
        options={filterDuplicatesByFullName(contacts.data?.data?.results ?? [])}
        valueFrom="id"
      />
      <Autocomplete
        defaultValue={query.statuses}
        label="Status"
        multiple
        onSelect={(statuses) => setQuery({ statuses })}
        options={statuses}
      />
    </FilterBar>
  );
}
