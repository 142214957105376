import { WLR3Address } from "../types";

/**
 * Converts a WLR3 / SI formatted address into a single string.
 *
 * TODO: duplicated in packages/cpq/src/helpers/addresses.js
 *
 * @param address
 * @returns {string}
 */
export function getAddressLine(address: WLR3Address) {
  return [
    address["organisationName"],
    address["poBox"],
    address["subPremises"],
    address["premisesName"],
    [address["thoroughfareNumber"], address["thoroughfareName"]]
      .filter(Boolean)
      .join(" "),
    address["doubleDependentLocality"],
    address["locality"],
    address["postTown"],
    address["county"],
    address["postcode"],
  ]
    .filter(Boolean)
    .join(", ");
}
