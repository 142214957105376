import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material/";

interface MenuItemType {
  id: string;
  label: string;
  onClick: Function;
}

type Props = {
  children?: React.ReactNode;
  items: MenuItemType[];
  [x: string]: any;
};

const Component = ({ children, items, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item?: MenuItemType) => {
    if (item) item.onClick();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        {...props}
      >
        {children}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        {items.map((item: MenuItemType) => (
          <MenuItem key={item.id} onClick={() => handleClose(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Component;
