import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import { AuroraMonoLogo } from "./AuroraMonoLogo";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import bg from "../../images/bg.jpg";
import {
  DWSLogotypeDark,
  affinityColors as colors,
  AffinityLogotype,
} from "../../../index";
import { parseBrandFromUrl } from "../../helpers/brand";

const brand = parseBrandFromUrl();
const isAffinity = brand === "AFFINITY";

const useStyles = makeStyles((theme) => ({
  page: {
    height: "100vh",
    background: isAffinity
      ? `${colors.neutralDeepPurple.value} url(${bg})`
      : theme.palette.grey[100],
    backgroundSize: "cover",
  },
  logo: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
  },
  logoFooter: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
}));

export const ModalAuthDialog: React.FC = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.page}
    >
      <Grid item md={5} sm={8} xs={11}>
        <Card>
          <CardContent className={classes.card}>
            <Box className={classes.logo}>
              {brand === "DWS" ? (
                <DWSLogotypeDark width={350} />
              ) : (
                <AffinityLogotype
                  width={200}
                  color={
                    isAffinity
                      ? colors.neutralDeepPurple.value
                      : theme.palette.text.primary
                  }
                />
              )}
            </Box>
            {children}
          </CardContent>
        </Card>
        <Box className={classes.logoFooter}>
          {isAffinity && <AuroraMonoLogo variant="light" />}
        </Box>
      </Grid>
    </Grid>
  );
};
