import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import InlineEdit from "../../../../../components/InlineEdit";
import FreeSearch from "../../../../../components/FreeSearch";
import { Asset } from "../../../../../types";
import { useCheckSim, useSaveSim } from "../../../../../hooks/assets";
import { Cancel, Done } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

type Props = {
  asset: Asset;
  hasAdminBar?: boolean;
};

const Component = ({ asset, hasAdminBar }: Props) => {
  const classes = useStyles();
  const initialDetails = {
    sim: asset.sim_serial,
    updated: false,
  };
  const [details, updateDetails] = useState(initialDetails);
  const simValidation = useCheckSim(details.sim, asset.network?.name, {
    enabled: details.updated,
  });

  const [closed, setClosed] = useState(true);
  const saveSim = useSaveSim(asset, { onSuccess: () => setClosed(true) });

  const getSimIcon = () => {
    return (
      <Box width="24px" height="24px" display="flex" alignItems="center">
        {simValidation.isLoading || saveSim.isLoading ? (
          <CircularProgress size={16} />
        ) : saveSim.isSuccess && saveSim.data?.processed ? (
          <CheckCircleIcon color="success" />
        ) : !simValidation.data?.is_valid_sim_number || saveSim.isError ? (
          <Tooltip title="Invalid SIM number">
            <ErrorIcon color="error" />
          </Tooltip>
        ) : (
          <EditIcon />
        )}
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <InlineEdit
        onEdit={() => {
          saveSim.reset();
          simValidation.refetch();
          updateDetails(initialDetails);
        }}
        open={!closed}
        disabled={
          hasAdminBar ||
          asset.status === "Terminated" ||
          asset.status === "Disconnected"
        }
        buttonLabel="SIM Swap"
        className={classes.edit}
        input={
          <form
            onSubmit={(event) => {
              event.preventDefault();
              saveSim.mutate(details.sim);
            }}
          >
            <FreeSearch
              afterTyping={(value) => {
                saveSim.reset();
                updateDetails({
                  sim: value,
                  updated: asset.sim_serial !== value,
                });
              }}
              disabled={saveSim.isLoading}
              helperText={
                saveSim.isIdle &&
                "To perform a SIM swap, simply enter the new SIM ref and click Save Changes."
              }
              inputProps={{
                style: { minWidth: 120 },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.icons}>
                    {getSimIcon()}
                  </InputAdornment>
                ),
              }}
              name="sim_serial"
              value={details.sim}
              size="small"
              variant="outlined"
              wait={200}
              numbersOnly
              fullWidth
            />
            <div className={classes.buttons}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Done />}
                disabled={
                  saveSim.isLoading ||
                  !simValidation.data?.is_valid_sim_number ||
                  !details.updated ||
                  !details.sim
                }
                type={
                  !!simValidation.data?.is_valid_sim_number
                    ? "submit"
                    : "button"
                }
              >
                Save Changes
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<Cancel />}
                disabled={saveSim.isLoading || !details.updated}
                className={classes.discard}
                onClick={() => updateDetails(initialDetails)}
              >
                Discard Changes
              </Button>
            </div>
          </form>
        }
      >
        <Typography variant="h4">{asset.sim_serial}</Typography>
        {saveSim.isSuccess && saveSim.data?.status === "success" && (
          <Typography variant="caption">
            Please note any network changes may take up to 24 hours to be
            applied.
          </Typography>
        )}
      </InlineEdit>
      {saveSim.isError && (
        <Typography variant="caption" color="error">
          The action you have taken has failed to complete at the network,
          please try again. If the problem continues please contact the Giacom
          Support Team.
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: { maxWidth: 400 },
  edit: { flexGrow: 0, paddingRight: 16 },
  search: { width: "100%" },
  icons: { paddingLeft: 14 },
  discard: { marginLeft: 14 },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 5,
  },
}));

export default Component;
