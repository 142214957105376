import { Box } from "@mui/material";
import { styled } from "@mui/styles";

const FullScreenCenter = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100vw",
  height: "100vh",
});

export default FullScreenCenter;
