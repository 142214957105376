import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Box,
} from "@mui/material";
import { LoaderButton, Alert } from "@akj-dev/shared-components";
import { useProcessCreditVet } from "../../../hooks/customers";
import { CreditVetStatus } from "./CreditVetStatus";

interface CustomerSummaryProps {
  accountId: string;
  customerReference: string /* AKA account number for VF */;
  companyName: string;
}

export const CustomerSummary = ({
  accountId,
  customerReference,
  companyName,
}: CustomerSummaryProps) => {
  const creditVetMutation = useProcessCreditVet();
  const [connections, setConnections] = useState("0");
  const handleChangeConnections = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConnections(parseInt(e.target.value) < 0 ? "0" : e.target.value);
  };

  const handleCreditVet = () => {
    creditVetMutation.mutate({
      account_id: accountId,
      number_of_connections: connections,
    });
  };

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Required number of connections</TableCell>
          <TableCell>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <TextField
                  variant="outlined"
                  type="number"
                  value={connections}
                  onChange={handleChangeConnections}
                  margin="dense"
                  name="number_of_connections"
                />
              </Box>
              <Box>
                <LoaderButton
                  variant="contained"
                  color="primary"
                  onClick={handleCreditVet}
                  loading={creditVetMutation.isLoading}
                >
                  Credit Check
                </LoaderButton>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
        {creditVetMutation.isError ? (
          <TableRow>
            <TableCell>Credit Vet Status</TableCell>
            <TableCell>
              <Alert type="error" message={creditVetMutation.error.message} />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {creditVetMutation.data?.status === "success" ? (
              <CreditVetStatus
                accountId={accountId}
                companyName={companyName}
              />
            ) : (
              <TableRow>
                <TableCell>Credit Vet Status</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            )}
          </>
        )}

        <TableRow>
          <TableCell>Company Name</TableCell>
          <TableCell>{companyName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Account Number</TableCell>
          <TableCell>{customerReference}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
