import React, { ReactNode } from "react";
import { Card, Box, Theme, useTheme } from "@mui/material";
import { IndexedTitle } from "./IndexedTitle";
import { SxStyleSheet } from "../../themes/_base";

export interface IndexedCardInfoProps {
  index?: number | string;
  title: string;
  children: ReactNode;
}

export const IndexedCardInfo = ({
  index,
  title,
  children,
}: IndexedCardInfoProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Card sx={styles.card}>
      <Box sx={styles.box}>
        <IndexedTitle index={index} title={title} />
      </Box>
      <Box sx={styles.box}>{children}</Box>
    </Card>
  );
};

const getStyles = (theme: Theme): SxStyleSheet => ({
  card: {
    borderRadius: theme.spacing(0.25),
    borderLeft: `${theme.spacing(0.25)} solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
  },
  box: {
    padding: theme.spacing(1),
  },
});
