import styled from "styled-components";
import { Link } from "react-router-dom";
import { affinityColors as colors, vodaTheme } from "../../../../index";
import Collapse from "@mui/material/Collapse";
import { darken } from "@mui/material";
import { parseBrandFromUrl } from "../../../helpers/brand";
// TODO: Probably need some mobile collapsing stuff here...

export const Outer = styled.div`
  flex: 0 0 248px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  background: ${(p) => {
    const brand = parseBrandFromUrl();
    switch (brand) {
      case "AFFINITY":
        return colors.neutralStorm.value;
      case "DWS":
        return "#351436"; // TODO: Should this be a definition in the theme provider? What's it's general usage?
      case "VODAFONE":
      default:
        return vodaTheme.palette.primary.main;
    }
  }};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  position: relative;
  top: -65px;
  height: 100vh;
  color: ${(p) => p?.theme?.palette.common.white || "#FFFFFF"};
`;

export const CustomCollapse = styled(Collapse)`
  background: ${(p) => {
    const brand = parseBrandFromUrl();
    switch (brand) {
      case "AFFINITY":
        return p?.theme?.palette.grey[400] || "#7d788c";
      case "DWS":
        return "#662669"; // TODO: Should also go somewhere...
      case "VODAFONE":
      default:
        return darken(vodaTheme.palette.primary.main, 0.2);
    }
  }};
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
`;
