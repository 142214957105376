import { createTheme } from "@mui/material";
import { baseThemeOptions, createOverrides } from "../_base";

// See https://material-ui.com/customization/default-theme/ for available options
const baseDaisyTheme = createTheme(
  // TODO: Fix typing error
  {
    ...baseThemeOptions,
    // add Daisy specific theme options here
    palette: {
      primary: {
        main: "#022f6b",
        contrastText: "#fff",
      },
      secondary: {
        main: "#f29400",
      },
      info: {
        main: "#022f6b",
      },
      error: {
        main: "#a30115",
      },
      warning: {
        main: "#f29400",
      },
      success: {
        main: "#50b063",
        contrastText: "#fff",
      },
      background: {
        default: "#f1f1f1",
      },
      grey: {
        50: "#f8f7f9", // neutralAthensGrey
        100: "#e9e9ec", // neutralGhost
        500: "#524b65", // neutralStorm
        600: "#333333", // replacement for neutralDeepPurple
        800: "#140f1e", // neutralAbyss
      },
    },
  } as any
);

// JSS styling overrides
const overrides = {
  ...createOverrides(baseDaisyTheme),
  // add Daisy specific overrides here
};

const daisyTheme = createTheme({
  ...baseDaisyTheme,
  components: overrides,
});

export { daisyTheme };
