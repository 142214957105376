import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material/";
import { DataTable, DataTablePagination } from "@akj-dev/shared-components";
import { Asset } from "../../../types";
import { SearchQuery } from "../types";
import { formattedDate } from "../../../helpers/functions";

interface Props {
  paginator: any;
  query: SearchQuery;
  setQuery: Function;
}

const Component = ({ paginator, query, setQuery }: Props) => {
  const columns = [
    {
      name: "companyName",
      label: "Company Name",
      sortable: true,
    },
    {
      name: "vfBillingAccountNumber",
      label: "Account Number",
      sortable: true,
    },
    {
      name: "name",
      label: "Product Name",
      sortable: true,
    },
    {
      name: "cli",
      label: "CLI",
      sortable: true,
    },
    {
      name: "sim",
      label: "SIM",
      sortable: false,
    },
    {
      name: "status",
      label: "Status",
      sortable: true,
    },
    {
      name: "resign_status",
      label: "Resign Status",
      sortable: false,
    },
    {
      name: "start_date",
      label: "Start",
      sortable: true,
    },
    {
      name: "end_date",
      label: "End",
      sortable: true,
    },
    {
      name: "contract_length",
      label: "Term",
      sortable: true,
    },
    {
      name: "cease_date",
      label: "Cease",
      sortable: true,
    },
    {
      name: "id",
      label: "Action",
      sortable: true,
    },
  ];

  const viewButton = (id: number) => (
    <Button
      variant="text"
      color="primary"
      size="small"
      component={Link}
      to={`/customers/assets/${id}`}
    >
      View
    </Button>
  );

  return (
    <DataTable
      title="Results"
      columns={columns}
      count={parseInt(paginator.data?.pagination?.count ?? "0")}
      data={paginator.data?.results?.map((asset: Asset) => [
        asset.account?.name,
        asset.account?.billing_account_number,
        asset.name,
        asset.cli,
        asset.sim_serial,
        asset.status,
        asset.resign_status,
        formattedDate(asset.contract_start_date_ymd),
        formattedDate(asset.contract_end_date_ymd),
        asset.contract_length,
        asset.cease_date_ymd,
        viewButton(asset.id),
      ])}
      error={
        paginator.isError
          ? "There was a problem fetching assets. Please try again."
          : ""
      }
      loading={paginator.isLoading}
      onChangePage={(event: any, page: number) => setQuery({ page: page + 1 })}
      onChangeRowsPerPage={(event: any) =>
        setQuery({ limit: event.target.value })
      }
      onChangeSort={(state: any) =>
        setQuery({
          sort: columns[state.columnIndex]?.name,
          direction: state.dir.toLocaleLowerCase(),
        })
      }
      pagination={DataTablePagination.Pages}
      page={query.page - 1}
      rowsPerPage={query.limit}
      rowsPerPageOptions={[5, 10, 25, 100]}
    />
  );
};

export default Component;
