import React from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { redirectToApp } from "../helpers/redirectToApp";

interface ActionButtonsProps {
  leftLabel: string;
  leftTo: string;
  rightLabel: string;
  rightTo: string;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  leftLabel,
  leftTo,
  rightLabel,
  rightTo,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" mb={1}>
      <Box mr={1}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => redirectToApp(leftTo)}
        >
          {leftLabel}
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => redirectToApp(rightTo)}
        >
          {rightLabel}
        </Button>
      </Box>
    </Box>
  );
};
