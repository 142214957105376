/**
 * External Users App Auth API
 * test:
 * https://pub-external-users-testenv01.uat.aurora.io/api/v2/
 * production:
 * https://users.digitalwholesalesolutions.com/api/v2/
 */

/**
 * Account settings pulled from DC as part of the response from api/v2/jwt below.
 * These are configured in test environments at, for example:
 * http://dc-testenv02.uat.aurora.io/cgi-bin/index.cgi/Admin/Tags
 * and in prod at:
 * http://daisycentral.daisy.group/cgi-bin/index.cgi/Admin/Tags
 */
export type AccountSettings = {
  /* Can order mobile products */
  as_mobile_order_enabled?: "1" | "0";
  /* Can order hardware products */
  as_hardware_order_enabled?: "1" | "0";
  /* Can order wlr bb products */
  as_wlrbb_order_enabled?: "1" | "0";
  /* Can order ethernet products */
  as_ethernet_order_enabled?: "1" | "0";
  /* Can order Monitoring as a Service products */
  as_monitoring_order_enabled?: "1" | "0";
  /* Can order universal products */
  as_universal_enabled?: "1" | "0";
  /* Account Creation Field */
  customer_type_id?: string;
  /* Account Creation Field */
  sales_person_id?: string;
  /* Account Creation Field */
  contract_type_id?: string;
  /* Virtual reseller mobile tariff sets */
  virtual_reseller_mobile_tariff_sets?: string;
  /* Can Resign WLR  */
  as_wlrbb_resign_enabled?: "1" | "0";
  /* Can Resign Mobile */
  as_mobile_resign_enabled?: "1" | "0";
  /* Requires external approval for external user orders (Love energy approvals) */
  requires_external_approval_for_external_user_orders?: "1" | "0";
  /* Divorce sub account when a signed contract is uploaded (MIC style) */
  should_divorce_sub_accounts_when_contract_signed?: "1" | "0";
  /* Can generate contract */
  as_generate_contract?: "1" | "0";
  /* Uses Contract Contract, restricts leads/prospects, enables price books */
  dws_reseller_enabled?: "1" | "0";
  /* Can Order Blank Sim */
  as_blank_sim_enabled?: "1" | "0";
  /* Can Order Account Level Bolt Ons */
  as_account_level_bolt_ons_enabled?: "1" | "0";
  /* Must Enter Sim ref for each mobile connection */
  as_pre_dispatched_sim_required?: "1" | "0";
  /* Can add airtime credits  */
  as_airtime_credits_enabled?: "1" | "0";
  /* Can use User definable fields  */
  as_user_definable_fields_enabled?: "1" | "0";
  /* Can add cost centres  */
  as_cost_centre_enabled?: "1" | "0";
  /* Can add daisy fresh  */
  as_daisy_fresh_enabled?: "1" | "0";
  /* Cirrus Live Chat for Indirect Partners */
  as_cirrus_livechat_enabled?: "1" | "0";
  /* Can generate quotes - TP23054 */
  as_quote_generation_enabled?: "1" | "0";
  /* Allow Co Terminus mobile contract length toggle TP30511 */
  as_can_create_coterminus_contract?: "1" | "0";
  /* Can view Daisy's internal approvals on platform */
  as_supplier_approvals_enabled?: "1" | "0";
  /* Can choose a Friday when when configuring port date for a product connection */
  as_can_set_friday_ports?: "1" | "0";
  /* Should request products from DC with is_dynamic flag. To do with resellers */
  as_request_dynamic_products?: "1" | "0";
  /* Should provision orders, not do the "Generate / Upload contract" flow at the end of the cpq */
  as_provision_only?: "1" | "0";
  /* Prevent users from placing orders on existing Daisy lines */
  as_stop_daisy_line_orders?: "1" | "0";
  /* Show the "I want to receive updates" option on delivery screen */
  as_can_set_order_updates?: "1" | "0";
  /* Can make commercial pricing requests TP1187 */
  as_enable_commercial_request?: "1" | "0";
  /* Show option for residual or up front commission TP17358 */
  as_commission_type_selection_for_hardware?: "1" | "0";
  as_commission_type_selection_for_mobile?: "1" | "0";
  as_commission_type_selection_for_wlr_bb?: "1" | "0";
  /* Can see product family in product table */
  as_can_see_product_family?: "1" | "0";
  /* Enables selection of multiple delivery addresses */
  as_multiple_delivery_addresses?: "1" | "0";
  /* Shows the SIM Type input on mobile. Some user segments want this, some don't. See TP40618 */
  as_display_sim_type?: "1" | "0";

  /* DC product category ID for Logic Monitor products */
  category_id_logic_monitor?: string;
  /* DC product category ID for universal products */
  category_id_universal?: string;
  /* DC category ID for CLI Bolt-ons. Used for DWP / Affinity Orders CPQ */
  category_id_cli_bolt_ons?: string;

  /* months before end of contract we allow user to resign. should be integer but it's from DC privs and is just going to used for resign expires_in param*/
  maximum_months_remaining_for_line_resign?: string;
  maximum_months_remaining_for_mobile_resign?: string;

  /* In-development feature toggles */
  as_feature_orders_cpq?: "1" | "0";

  /* Is a Vodafone Direct user */
  can_access_vf_direct?: "1" | "0";
  /* Whether Vodafone Discounts should be shown */
  can_use_vfdirect_discount?: "1" | "0";

  as_can_add_bill_options?: "1" | "0";

  can_use_affinity_sales?: "1" | "0";
  can_use_affinity_orders?: "1" | "0";
  can_use_affinity_billing?: "1" | "0";
  can_use_affinity_insight?: "1" | "0";
  can_use_affinity_hub?: "1" | "0";
  can_use_affinity_support?: "1" | "0";
  can_use_affinity_customers?: "1" | "0";
  can_use_platform_global_search?: "1" | "0";
  ao_can_see_notifications_section?: "1" | "0";
  /* TP70007 */
  can_upload_supporting_documents?: "1" | "0";

  /* Whether user should be allowed to upload a contract */
  dc_allow_upload_contract?: "1" | "0";

  /* TP65568: Can access new co term specific date */
  as_can_access_new_coterminus?: "1" | "0";

  /* TP103806: Remove "Manage Users" from platform */
  allow_manage_users_in_platform?: "1" | "0";

  /* TP113026: Separate the Availability checker in Affinity Orders */
  can_use_availablity_check_in_AO?: "1" | "0";

  /* TP96555: Restrict Contract terms available by product in Affinity Sales */
  can_restrict_contract_terms_by_product?: "1" | "0";

  /* TP50564: Whether to display sim chip on cpq summary screen */
  show_sim_in_summary?: "1" | "0";

  /* Any other value can be defined in admin screen */
  [key: string]: string | undefined;
};

/**
 * Account meta also pulled from DC for the JWT session token response
 */
export type AccountMeta = {
  id: string;
  name: string;
  type?: "sales_person" | "account";
};

export type UserSettings = {
  platform_terms_and_conditions_confirmed?: boolean;
  platform_terms_and_conditions_confirmed_at?: string | null;
};

/**
 * Session Token Response
 * /api/v2/jwt
 */

export interface JwtResponse {
  /* Duplicate of id in AccountMeta */
  account_id?: string;
  /**
   * Only present on the sales person flow. See 23267 and Rob's explanation here:
   * https://akj-hq.slack.com/archives/GTFHJENFQ/p1614079903182100
   */
  sales_person_id?: string;
  /* Base URL for subsequent calls authed with this token */
  api_url_base: string;
  expires_local: string;
  expires_utc: string;
  jwt: string;
  meta: AccountMeta;
  settings: AccountSettings;
  user: UserSettings;
}

/**
 * Data inside the decoded Session JWT from JwtResponse
 * Note external_user_name is not easy to return directly from the jwt endpoint
 * hence we must parse it out client side from the token payload
 */
export type SessionJwtPayload = {
  exp: number;
  external_user_id: string;
  external_user_name: string;
  external_user_source: string;
  iss: string;
};

export interface LoginResponse {
  refresh_token: string;
  user_role: UserRole;
}
export type UserRole = {
  [key: string]: string | number | undefined;
  affinity_sales_can_approve_quotes?: 0 | 1;
};

export interface LogoutResponse {
  token: {
    browser_name: string;
    browser_version: string;
    created_at: string;
    deleted_at: string;
    device: string;
    id: number;
  };
}

export const authAPI = {
  /**
   * Generic API fetch
   * @param baseUrl
   * @param resource
   * @param options
   * @private
   */
  _fetch: async function <T>(
    baseUrl: string,
    resource: string,
    options: object
  ): Promise<T> {
    const response = await fetch(`${baseUrl}${resource}`, options);
    if (response.ok) {
      return (await response.json()) as Promise<T>;
      // cloud market jwt endpoint only returns a 401 without any statusText.
    } else if (response.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw new Error(response.statusText);
    }
  },

  /**
   * Get new JWT using refresh token for authenticating API requests
   *
   * Note: settings part of the response shouldn't be used? What if a user's
   * settings change before a JWT expires? They shouldn't be persisted via the
   * same mechanism as the session.
   *
   * According to Adrian it's guaranteed the shape will be this if 200
   */
  jwt: function (baseUrl: string, refreshToken: string): Promise<JwtResponse> {
    return authAPI._fetch(baseUrl, "jwt", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
  },

  /**
   * Login to the platform (i.e. external users app)
   */
  login: function (
    baseUrl: string,
    username: string,
    password: string
  ): Promise<LoginResponse> {
    return authAPI._fetch(baseUrl, "login?include_permissions=1", {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(username + ":" + password)}`,
      },
    });
  },

  /**
   * Login to the platform (i.e. external users app)
   */
  loginUsingToken: function (
    baseUrl: string,
    token: string
  ): Promise<LoginResponse> {
    return authAPI._fetch(baseUrl, "login?include_permissions=1", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  /**
   * Logout
   * Destroys the supplied refresh token and associated JWTs in the external users app
   */
  logout: function (
    baseUrl: string,
    refreshToken: string
  ): Promise<LogoutResponse> {
    return authAPI._fetch(baseUrl, "logout", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
  },
};
