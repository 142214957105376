import React from "react";
import logo from "./light.svg";

interface Props {
  width?: number | string;
}
export function VodafoneLogo({ width = "100%" }: Props) {
  return (
    <img
      src={logo}
      alt="Cloud Market for Vodafone Business"
      width={width}
      style={{ maxWidth: "300px", marginBottom: "0px" }}
    />
  );
}
