import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { DataTable } from "@akj-dev/shared-components";
import { getCustomerContacts } from "../api/customers";

export default function Contacts() {
  const params: any = useParams();
  const accountId = params.accountId;

  const customerContacts = useQuery(["customerContacts", accountId], () =>
    getCustomerContacts(accountId)
  );

  const filterCustomerContacts = (contacts: any[]): any[] =>
    contacts.filter(
      (customer: any) => !customer.is_virtual_contact && customer.active
    );

  const columns = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "address",
      label: "Address",
    },
    {
      name: "type",
      label: "Type",
    },
  ];

  return (
    <Box mt={2}>
      <DataTable
        columns={columns}
        count={
          filterCustomerContacts(customerContacts?.data?.data?.contacts ?? [])
            .length
        }
        data={filterCustomerContacts(
          customerContacts?.data?.data?.contacts ?? []
        ).map((customer: any) => [
          customer.nice_name,
          customer.primary_telephone_number,
          customer.email1,
          customer.primary_address_street +
            " " +
            customer.primary_address_postalcode,
          customer.type,
        ])}
        noDataCTA="Sorry, no contacts found."
        loading={customerContacts.isLoading}
      />
    </Box>
  );
}
