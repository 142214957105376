import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    marginRight: 8,
  },
  value: {
    color: theme.palette.text.disabled,
  },
}));

interface Props {
  title: string;
  value: string;
  variant?: string;
}

function AssetMeta({ title, value, variant }: Props) {
  const classes = useStyles();
  return (
    <Box display="flex" mr={2}>
      <Typography variant="body1" className={classes.title}>
        {title}
      </Typography>
      {variant === "chip" ? (
        <Chip label={value} color="primary" size="small" />
      ) : (
        <Typography variant="body1" className={classes.value}>
          {value}
        </Typography>
      )}
    </Box>
  );
}

export default AssetMeta;
