import React, { useEffect, useState } from "react";
import { Box, Chip, TextField as ParentTextField } from "@mui/material";
import Wrap from "./Wrap";

export interface TextFieldProps {
  label: string;
  onApply: (values: any[]) => void;
  value?: string;
  values?: string[];

  [key: string]: any;
}

export function TextField(props: TextFieldProps) {
  const [value, setValue] = useState<any>(props?.value ?? "");
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    setValues(props?.values ?? []);
  }, [props.values]);

  return (
    <Wrap
      {...props}
      onApply={() => {
        let update = values;

        if (value) {
          update.push(value);
        }

        props.onApply(update);
        setValue("");
      }}
    >
      <ParentTextField
        autoFocus
        label={props.label}
        onChange={(event) => setValue(event.target.value)}
        size="small"
        value={value}
        variant="outlined"
      />

      {values.length > 0 && (
        <Box display="flex" flexWrap="wrap" mt={1}>
          {values.map((value, index) => (
            <Box key={index} mb={0.5} mr={0.5}>
              <Chip
                label={value}
                onDelete={() =>
                  setValues(values.filter((existing) => existing !== value))
                }
                size="small"
              />
            </Box>
          ))}
        </Box>
      )}
    </Wrap>
  );
}
