import React, { ReactNode } from "react";
import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { Controller, UseControllerProps } from "react-hook-form";
import { getLabel } from "./utils/getLabel";

export interface RHFSelectFieldProps {
  name: UseControllerProps["name"];
  label: string;
  rules?: UseControllerProps["rules"];
  children: ReactNode;
  disabled?: boolean;
}

/**
 * reach-hook-form select field
 * Use inside a <FormProvider>
 */
export const RHFSelectField = ({
  name,
  label,
  rules,
  children,
  disabled = false,
}: RHFSelectFieldProps) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl
            variant="standard"
            fullWidth
            error={!!fieldState.error}
            disabled={disabled || formState.isSubmitting}
          >
            <InputLabel>{getLabel(label, rules)}</InputLabel>
            <Select {...field}>{children}</Select>
            {fieldState.error && (
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error.message || "Required"}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
