import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import NumberFormat from "react-number-format";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="£"
    />
  );
}

/**
 * Currency Input field
 * @see https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
 * @param props
 * @constructor
 */
export const CurrencyField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};
