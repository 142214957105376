import * as React from "react";

import { Box, TextField, styled, Theme, useTheme } from "@mui/material";
import FilterBox from "./FilterBox";
import { formatDateWithPattern, parseStringToDate } from "../utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const DATE_PATTERN: string = "dd/MM/yyyy";
export const DC_DATE_FORMAT: string = "yyyy-MM-dd";

export default function DateRangeFilter({
  item,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  ...props
}: any) {
  const val1 = selection?.[0];
  const val2 = selection?.[1];

  const [dateSelectedFrom, setDateSelectedFrom] = React.useState<any>(
    val1 ? parseStringToDate(val1, DATE_PATTERN) : new Date()
  );
  const [dateSelectedTo, setDateSelectedTo] = React.useState<any>(
    val2 ? parseStringToDate(val2, DATE_PATTERN) : new Date()
  );
  const theme = useTheme();

  React.useEffect(() => {
    const from = formatDateValue(dateSelectedFrom);
    const to = formatDateValue(dateSelectedTo);

    setSelection([from, to]);
  }, [setSelection, dateSelectedTo, dateSelectedFrom]);

  const formatDateValue = (date: any) => {
    if (date) {
      const dateToIso = new Date(date).toISOString();
      const formattedDate = formatDateWithPattern(dateToIso, DATE_PATTERN);
      return formattedDate;
    }
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        showReset
      >
        <FilterBody theme={theme}>
          <Box pb={2}>
            <DatePicker
              label={"From"}
              inputFormat={props.format ?? DATE_PATTERN}
              value={dateSelectedFrom}
              maxDate={dateSelectedTo}
              onChange={(date: any) => setDateSelectedFrom(date)}
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
          </Box>
          <DatePicker
            label={"To"}
            inputFormat={props.format ?? DATE_PATTERN}
            value={dateSelectedTo}
            minDate={dateSelectedFrom}
            onChange={(date: any) => setDateSelectedTo(date)}
            views={["year", "month", "day"]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                size="small"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
        </FilterBody>
      </FilterBox>
    </div>
  );
}

const FilterBody = styled("div")<{ theme: Theme }>(({ theme }) => ({
  paddingInline: theme.spacing(2),
}));
