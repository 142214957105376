import React from "react";
import { useLocation } from "react-router-dom";
import {
  Outer,
  CustomCollapse as Collapse,
  CustomLink as Link,
} from "./SubNavComponents";
import { RoutingElement, RoutingObject } from "../../../helpers/navigation";
import List from "@mui/material/List";
import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Box, alpha } from "@mui/material";
import { useAuth } from "@akj-dev/affinity-platform";
import { darken, Theme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SubNavLogo } from "./SubNavLogo";
import { Brand, parseBrandFromUrl } from "../../../helpers/brand";

interface SubNavProps {
  children?: React.ReactNode;
  routes: RoutingObject;
  rootTitle: string;
}

const styles = {
  text: {
    color: "common.white",
  },
  listItem: {
    "&:hover": {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.action.selected, 0.25),
    },
    "&.Mui-selected,&.Mui-selected:hover": {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.action.selected, 0.25),
    },
    "&.Mui-focusVisible": {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.action.selected, 0.25),
    },
  },
  nestedListItem: {
    "&:hover": {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.action.selected, 0.25),
    },
  },
};

export const SubNav = ({ children, routes }: SubNavProps) => {
  const { externalUserName } = useAuth();
  const brand = parseBrandFromUrl();

  return (
    <Outer data-cy="SubNav">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx={2}
        mt={brand === Brand.VODAFONE ? 1 : 2}
        mb={1}
      >
        <Link to="/">
          <SubNavLogo
            width={190}
            height={50}
            style={{ pointerEvents: "none" }}
          />
        </Link>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx={2}
        mt={1}
        mb={1}
      >
        <Typography variant="h5" color="inherit" align="center">
          {externalUserName}
        </Typography>
      </Box>
      <List component="nav">
        <SubNavLevel routes={routes.hierarchy} level={0} />
      </List>

      {children}
    </Outer>
  );
};

interface SubNavLevelProps {
  routes: any;
  level: number;
}

const SubNavLevel: React.FC<SubNavLevelProps> = ({ routes, level }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);

  const indentStyle = {
    paddingLeft: `${16 + level * 16}px`,
  };

  const dividerStyle = {
    borderBottom: (theme: Theme) => {
      const brand = parseBrandFromUrl();
      switch (brand) {
        case Brand.AFFINITY:
          return `1px solid ${theme.palette.grey[400]}`;
        case Brand.DWS:
          return "1px solid #7D788C";
        case Brand.VODAFONE:
        default:
          return `1px solid ${darken(theme.palette.primary.main, 0.2)}`;
      }
    },
  };

  return (
    <>
      {routes.map((route: RoutingElement) => {
        if (route.hidden) return null;
        const hasSubmenu = Array.isArray(route.subject);
        if (hasSubmenu)
          return (
            <React.Fragment key={route.path}>
              <ListItem
                button
                onClick={() => setOpen(!open)}
                disableRipple
                disableTouchRipple
                divider
                sx={{
                  ...styles.nestedListItem,
                  ...indentStyle,
                  ...dividerStyle,
                }}
              >
                {route.icon && (
                  <ListItemIcon sx={styles.text}>{route.icon}</ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Typography variant="body2" color="inherit" noWrap>
                      {route.title}
                    </Typography>
                  }
                />
                {open ? (
                  <KeyboardArrowDown fontSize="small" />
                ) : (
                  <KeyboardArrowRight fontSize="small" />
                )}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                  <SubNavLevel routes={route.subject} level={level + 1} />
                </List>
              </Collapse>
            </React.Fragment>
          );
        return (
          <Link to={route.path} key={route.path}>
            <ListItem
              button
              disableRipple
              disableTouchRipple
              selected={pathname === route.path}
              divider
              onClick={() => route.onClick && route.onClick()}
              sx={{ ...styles.listItem, ...indentStyle, ...dividerStyle }}
            >
              {route.icon && (
                <ListItemIcon sx={styles.text}>{route.icon}</ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography variant="body2" sx={styles.text} noWrap>
                    {route.title}
                  </Typography>
                }
              />
            </ListItem>
          </Link>
        );
      })}
    </>
  );
};
