import React from "react";
import Box from "@mui/material/Box";
import AssetMeta from "./AssetMeta";

function AssetMetaBar() {
  return (
    <Box display="flex">
      <AssetMeta title="Account Name" value="Murphx1" />
      <AssetMeta title="Account ID" value="FKQ01511" />
      <AssetMeta title="Status" value="Active" variant="chip" />
    </Box>
  );
}

export default AssetMetaBar;
