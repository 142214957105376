// Copied from packages/affinity-hub-fe/src/api/filter.ts
import { UseInfiniteQueryResult } from "react-query";

export interface Filter {
  id: string;
  name?: string;
  type?: FilterType;
  resources?: string;
  from_resources?: string;
  to_resources?: string;
  options?: any;
  values?: string[];
  showToggle?: boolean;
  value?: string;
  valueDisplayFunc?: Function;
}

export enum FilterType {
  IDENTIFIER = "IDENTIFIER",
  RANGE = "RANGE",
  ENUM = "ENUM",
  AMOUNT = "AMOUNT",
  TEXT = "TEXT",
  AUTO_TEXT = "AUTO_TEXT",
  DATE_RANGE = "DATE_RANGE",
}

export interface UsersFilter {
  id?: string;
  name: string;
  filters: Filter[];
}

export interface FilterValues {
  list: string[];
  page: number;
  pageSize: number;
  total: number;
}

export type FetchResourcesHook = (
  resource: string | undefined,
  search: string,
  options: object
) => UseInfiniteQueryResult;
