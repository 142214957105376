import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { Box, Theme, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { affinityColors } from "..";
import { SxStyleSheet } from "../themes/_base";

export interface ExpandingCardProps {
  /**
   * The title of the card
   */
  title?: string;
  /**
   * Subtitle of the card
   */
  subtitle?: string | React.ReactNode;
  /**
   * Icon / Avatar displayed top left on the card header
   */
  avatar?: React.ReactNode;
  /**
   * Sets whether the card is open or not
   * Ignored when component is uncontrolled
   */
  expanded?: boolean;
  /**
   * Default expansion state
   * Ignored when component is controlled
   */
  defaultExpanded?: boolean;
  /**
   * Callback fired when header clicked. Component is uncontrolled when undefined.
   * Signature: function(expanded: boolean) => void
   */
  onHeaderClick?: () => void;
  /**
   * Optional function passed in for if wanting to add a custom action when expanding the card.
   */
  onExpand?: () => void;
  /**
   * Optional React Node for custom actions in a Header
   */
  headerAction?: JSX.Element;

  children: ReactNode;
}

/**
 * Expanding card
 * Modified from example here: https://material-ui.com/components/cards/#complex-interaction
 * Note this is different to ExpandingSection as they can exist singularly.
 * The former is designed for accordions.
 *
 * @param title
 * @param subtitle
 * @param avatar
 * @param defaultExpanded
 * @param onChange
 * @param children
 * @param headerAction
 * @param other
 * @returns {*}
 * @constructor
 */
export function ExpandingCard({
  title,
  subtitle,
  avatar,
  defaultExpanded,
  expanded: controlledExpanded,
  onHeaderClick,
  children,
  onExpand,
  headerAction,
  ...other
}: ExpandingCardProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const controlledByParent = typeof onHeaderClick === "function";

  let [expanded, setExpanded] = React.useState(false);

  if (controlledByParent) {
    // expansions is controlled by parent
    expanded = !!controlledExpanded;
    // @ts-ignore
    setExpanded = onHeaderClick;
  } else {
    // TODO: Tidy this up.
    // React Hook "React.useState" is called conditionally. React Hooks must be called in the exact same order in every component render  react-hooks/rules-of-hooks
    // [expanded, setExpanded] = React.useState(false);
  }

  React.useEffect(() => {
    if (!controlledByParent) setExpanded(!!defaultExpanded);
  }, [onHeaderClick, controlledByParent, defaultExpanded]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (onExpand) onExpand();
  };

  return (
    <Paper elevation={2} sx={styles.card} {...other}>
      <CardHeader
        avatar={avatar}
        action={
          <Box alignItems="center">
            {headerAction}
            <IconButton
              sx={!expanded ? styles.expand : styles.expandOpen}
              aria-expanded={expanded}
              aria-label="show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        }
        title={title}
        subheader={subtitle}
        onClick={handleExpandClick}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent sx={styles.cardContent}>{children}</CardContent>
      </Collapse>
    </Paper>
  );
}

ExpandingCard.propTypes = {
  /**
   * The title of the card
   */
  title: PropTypes.string,
  /**
   * Subtitle of the card
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Icon / Avatar displayed top left on the card header
   */
  avatar: PropTypes.element,
  /**
   * Sets whether the card is open or not
   * Ignored when component is uncontrolled
   */
  expanded: PropTypes.bool,
  /**
   * Default expansion state
   * Ignored when component is controlled
   */
  defaultExpanded: PropTypes.bool,
  /**
   * Callback fired when header clicked. Component is uncontrolled when undefined.
   * Signature: function(expanded: boolean) => void
   */
  onHeaderClick: PropTypes.func,
  /**
   * Optional function passed in for if wanting to add a custom action when expanding the card.
   */
  onExpand: PropTypes.func,
  /**
   * Optional React Node for custom actions in a Header
   */
  headerAction: PropTypes.element,
};

const getStyles = (theme: Theme): SxStyleSheet => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    transform: "rotate(180deg)",
  },
  cardContent: {
    backgroundColor: affinityColors.grey50.value,
  },
});
