/**
 * Test if a postcode is valid.
 * Note this regex is taken from the official UK government spec here:
 * https://www.gov.uk/government/publications/bulk-data-transfer-for-sponsors-validation-rules-for-cas-upload
 * https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
 *
 * 3. UK Postcode Regular Expression
 * ^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$
 * 3.2 Logic
 "GIR 0AA"
 OR
 One letter followed by either one or two numbers
 OR
 One letter followed by a second letter that must be one of ABCDEFGHJ
 KLMNOPQRSTUVWXY (i.e..not I) and then followed by either one or two
 numbers
 OR
 One letter followed by one number and then another letter
 OR
 A two part post code
 where the first part must be
 One letter followed by a second letter that must be one of ABCDEFGH
 JKLMNOPQRSTUVWXY (i.e..not I) and then followed by one number and
 optionally a further letter after that
 AND
 The second part (separated by a space from the first part) must be One
 number followed by two letters.
 A combination of upper and lower case characters is allowed.
 Note: the length is determined by the regular expression and is between 2 and 8
 characters.
 */
export function isValidPostcode(postcode: string) {
  return postcodeRegEx.test(postcode);
}
// const postcodeRegEx = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/;
// Or...y'know, just get any old one because data doesn't match:
export const postcodeRegEx = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/i;
