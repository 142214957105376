import React from "react";
import { Route, Switch } from "react-router-dom";
import { createRoutingObject } from "@akj-dev/shared-components";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchCustomerRoutes from "./nav/SearchCustomerRoutes";
import Customers from "./screens/Customers";
import Assets from "./screens/Assets";

export const routes = createRoutingObject(
  [
    {
      path: "/",
      title: "Customers",
      subject: (
        <>
          <Route exact path="/customers">
            <Customers />
          </Route>
          <Switch>
            <Route path="/customers/:accountId">
              <SearchCustomerRoutes />
            </Route>
          </Switch>
        </>
      ),
      icon: <DashboardIcon color="inherit" fontSize="small" />,
    },
    {
      path: "/assets",
      title: "Assets",
      subject: <Assets />,
      icon: <DashboardIcon color="inherit" fontSize="small" />,
    },
  ],
  "/customers"
);
