import React from "react";
import { Helmet } from "react-helmet";
import { getBrandMeta, getFavicon } from "../helpers/brand";

export const BrandedDocumentTitle = ({ title = "" }) => {
  const brandMeta = getBrandMeta();
  const brandFavicon = getFavicon();
  const renderTitle = title && ` | ${title}`;

  return (
    <Helmet>
      <title>
        {brandMeta.documentTitle}
        {renderTitle}
      </title>
      <meta name="description" content={`${brandMeta.name}${renderTitle}`} />
      <link rel="icon" href={brandFavicon} />
    </Helmet>
  );
};
