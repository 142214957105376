import React from "react";
import { Controller } from "react-hook-form";

import { TextField } from "@mui/material";
import { DatePicker as WrappedPicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

export interface Props {
  dataCy?: string;
  label: string;
  name: string;
  disabled?: boolean;
  inputFormat?: string;
  textfieldProps?: Object;
  [x: string]: any;
}

const DatePicker = ({
  dataCy,
  label,
  name,
  value,
  control,
  disabled,
  inputFormat,
  onChange,
  textfieldProps,
  schema,
  ...rest
}: Props) => {
  return (
    <Controller
      name={name}
      rules={{
        required: rest?.rules?.required,
        validate: (val: Date) => {
          if (!val) return true;
          if (isNaN(val.getTime())) {
            return "Date is invalid";
          }
          if (val.getFullYear() < 1000) {
            return "Date must use four-digit years";
          }
          const additionalValidationResult = rest.rules?.validate?.(val);
          if (additionalValidationResult) {
            return additionalValidationResult;
          }
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <WrappedPicker
              data-cy={dataCy}
              label={rest?.rules?.required ? label + " *" : label}
              value={value}
              disabled={disabled}
              inputFormat={inputFormat ?? "dd/MM/yyyy"}
              onChange={onChange}
              views={["year", "month", "day"]}
              renderInput={(props: any) => (
                <TextField
                  {...props}
                  variant={rest.variant || "standard"}
                  fullWidth
                  size={rest.size}
                  margin={rest.margin || "normal"}
                  helperText={error && (error.message || "Required")}
                  error={!!error}
                  inputProps={{ ...props.inputProps }}
                />
              )}
              {...rest}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DatePicker;
