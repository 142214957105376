import React, { useEffect } from "react";
import { Grid, MenuItem } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { DatePicker } from "../../../../DatePicker";

import { IndexedCardInfo } from "../../../../atoms/indexed-card-info";
import { RHFTextField } from "../../../../atoms/form-fields/RHFTextField";
import { RHFSelectField } from "../../../../atoms/form-fields/RHFSelectField";
import {
  isOptionalValidMobileNumber,
  isOptionalValidPhoneNumber,
  isValidMobileNumber,
  isValidPhoneNumber,
  isValidText,
  isValidVfEmail,
} from "../../helpers/validators";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { useIsVfDirectConsumerAccount } from "../../hooks/useIsVfDirectConsumerAccount";
import { isOver18YearsOld } from "../../../../shared/date";

interface ContactDetailsProps {
  cardIndex: string;
  accountSettings: AccountSettings;
}

export const ContactDetails = ({
  cardIndex,
  accountSettings,
}: ContactDetailsProps) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Yes, email field needs to be duplicated when Bill Options are hidden 🤷‍
      if (name === "email") setValue("ebilling_email", value.email);
    });
    return () => subscription.unsubscribe();
  });

  const isVfDirectConsumerAcc = useIsVfDirectConsumerAccount(accountSettings);

  return (
    <IndexedCardInfo index={cardIndex} title="Contact Details">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <RHFSelectField
            label="Salutation"
            name="salutation"
            rules={{ required: true }}
          >
            <MenuItem value="Dr.">Dr.</MenuItem>
            <MenuItem value="Miss">Miss</MenuItem>
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
            <MenuItem value="Prof.">Prof.</MenuItem>
          </RHFSelectField>
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="First Name"
            name="first_name"
            rules={{ required: true, validate: isValidText }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Last Name"
            name="last_name"
            rules={{ required: true, validate: isValidText }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Telephone No."
            name="phone"
            rules={{ required: true, validate: isValidPhoneNumber }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Fax No."
            name="fax"
            rules={{ validate: isOptionalValidPhoneNumber }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Mobile No."
            name="mobile"
            rules={{
              required: isVfDirectConsumerAcc,
              validate: (val) =>
                isVfDirectConsumerAcc
                  ? isValidMobileNumber(val)
                  : isOptionalValidMobileNumber(val),
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Email Address"
            name="email"
            type="email"
            rules={{
              required: true,
              validate: (val) => {
                if (accountSettings.can_access_vf_direct === "1") {
                  return isValidVfEmail(val);
                } else {
                  return true;
                }
              },
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <DatePicker
            name="birthdate"
            label={"Date Of Birth"}
            disableFuture
            margin="none"
            rules={{
              required: isVfDirectConsumerAcc,
              validate: (val: Date) => {
                if (!isOver18YearsOld(val)) {
                  return "Contact must be over 18 years of age";
                }
                if (val.getFullYear() < 1900) {
                  return "Minimum date must be greater than 01/01/1900";
                }
                return true;
              },
            }}
          />
        </Grid>
      </Grid>
    </IndexedCardInfo>
  );
};
