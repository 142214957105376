import { get } from "./";

export function accounts(query = "", limit = 10) {
  return get("Account/AutoComplete", {
    with_ban: 1,
    name: query,
    limit,
  });
}

export function clis(query = "", limit = 10) {
  return get("CLIs/AutoComplete", {
    query,
    limit,
  });
}

export function contacts(query = "", limit = 10) {
  return get("Contacts/AutoComplete", {
    limit,
    primary_contact_first: 1,
    query,
  });
}
