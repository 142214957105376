import _ from "lodash";
import { get } from "./";
import { postJSONBackgroundIdempotent } from "../helpers/idempotency";

/**
 * Create an account
 * Duplicated from CPQ.
 */
export const create = (params: any) => {
  return postJSONBackgroundIdempotent(
    `Account/Create`,
    convertBoolValues(params)
  );
};

/**
 * Convert boolean values to format expected by DC.
 * Duplicated from CPQ.
 */
export function convertBoolValues(
  obj: Record<string | number, any>,
  removeFalseValues = false
) {
  const result: Record<string | number, any> = {};
  _.forOwn(obj, (value, key) => {
    switch (value) {
      case true:
        result[key] = 1;
        break;
      case false:
        if (!removeFalseValues) result[key] = 0;
        break;
      case null:
        break;
      case undefined:
        break;
      default:
        result[key] = value;
    }
  });
  return result;
}

/**
 * Credit vet status
 * Duplicated from CPQ
 */
export const ThirdPartyBilling = (
  accountId: string,
  supplier?: ThirdPartyBillingSuppliers,
  create_review_credit_vet_case_if_required?: boolean
) =>
  get<ThirdPartyBillingResponse>(`Account/ThirdPartyBilling/${accountId}`, {
    supplier,
    create_review_credit_vet_case_if_required,
  });

// only supports one at time of writing. endpoint will default to this.
export type ThirdPartyBillingSuppliers = "VF Direct";

export enum CreditCheckStatus {
  Refer = "Refer",
  Declined = "Declined",
  Accepted = "Accepted",
  InProgress = "In Progress",
}

export type ThirdPartyBillingResponse = {
  data?: {
    vf_credit_check_reference: string;
    // Note this is superficial without other connections taken into account (advised by Dave Thomas)
    vf_credit_check_status: CreditCheckStatus;
    vf_credit_check_value: string;
    in_flight_connections_count: string;
    as_credit_check_result: CreditCheckStatus;
    as_credit_check_volume: string;
    case_id?: string;
    credit_check_date_entered: string;
  };
  status: "success" | "error";
};
