import { affinityTheme } from "./affinityTheme";
import { daisyTheme } from "./daisyTheme";
import { dwpTheme } from "./dwpTheme";
import { vodaTheme } from "./vodaTheme";
import queryString from "query-string";

export enum Brand {
  AFFINITY = "AFFINITY",
  VODAFONE = "VODAFONE",
  DAISY = "DAISY",
  DWS = "DWS",
}

export const parseBrandFromUrl = () => {
  const search = queryString.parse(window.location.search);

  if (typeof search.brand === "string") {
    const override = search.brand.toUpperCase();

    switch (override) {
      case "VODAFONE":
        return Brand.VODAFONE;
      case "DWS":
        return Brand.DWS;
      case "DAISY":
        return Brand.DAISY;
      case "AFFINITY":
      default:
        return Brand.AFFINITY;
    }
  }

  if (window.location.hostname?.includes("vodafone")) {
    return Brand.VODAFONE;
  }

  if (
    window.location.hostname?.includes("dwp") ||
    window.location.hostname?.includes("digitalwholesalesolutions")
  ) {
    return Brand.DWS;
  }

  return Brand.AFFINITY;
};

export const getThemeForBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.VODAFONE:
      return vodaTheme;
    case Brand.DAISY:
      return daisyTheme;
    case Brand.DWS:
      return dwpTheme;
    case Brand.AFFINITY:
    default:
      return affinityTheme;
  }
};

export const getTheme = () => getThemeForBrand(parseBrandFromUrl());
