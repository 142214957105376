import { useMutation, useQuery, useQueryClient } from "react-query";
import * as api from "../api/assets";
import { Asset } from "../types";
import { SearchQuery, ServiceQuery } from "../screens/Assets/types";

export function useAddProduct(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "changes.products"]);

    options.onSuccess(data, variables);
  };

  return useMutation(({ data }) => api.addProduct(asset, data), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useCancelChange(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "changes.products"]);

    options.onSuccess(data, variables);
  };

  return useMutation(({ change_id }) => api.cancelChange(asset, change_id), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useResetChange(
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const onSuccess = (data: any, variables: any) => {
    options.onSuccess(data, variables);
  };

  return useMutation((change_id) => api.resetChange(change_id), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useCancelDisconnection(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "disconnections"]);

    options.onSuccess(data, variables);
  };

  return useMutation(({ id }) => api.cancelDisconnection(asset, id), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useChangeProduct(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "changes.products"]);

    options.onSuccess(data, variables);
  };

  return useMutation(({ data }) => api.changeProduct(asset, data), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useCheckBarsCompatibilities(
  asset: Asset,
  active_bars: string[],
  options = {}
) {
  return useQuery(
    ["asset", asset.id, "bars.compatibilities", active_bars],
    () => api.barsCompatibilities(asset, active_bars),
    options
  );
}

export function useCheckSim(number: string, network: string, options = {}) {
  return useQuery(
    ["sim.validations", number, network],
    () => api.checkSim(number, network),
    options
  );
}

export function useDisconnections(asset: Asset, options = {}) {
  return useQuery(
    ["asset", asset.id, "disconnections"],
    () => api.disconnections(asset),
    options
  );
}

export function useFind(id: number, options = {}) {
  return useQuery(["assets", "" + id], () => api.show(id), options);
}

export function usePaginator(query: SearchQuery, options = {}) {
  return useQuery(
    ["assets.paginate", query],
    () => api.paginate(query),
    options
  );
}

export function useProvisionChange(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "changes.products"]);

    options.onSuccess(data, variables);
  };

  return useMutation((params) => api.provisionChange(asset, params), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useRefresh(asset: Asset, options = {}) {
  return useMutation(() => api.refresh(asset), {
    ...options,
  });
}

export function useRemoveProduct(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
    onError: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "changes.products"]);

    options.onSuccess(data, variables);
  };

  const onError = (data: any, variables: any) => {};

  return useMutation(({ service }) => api.removeProduct(asset, service), {
    ...options,
    onSuccess: onSuccess,
    onError: onError,
  });
}

export function useRequestPac(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
    onError: (error: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "disconnections"]);

    options.onSuccess(data, variables);
  };

  return useMutation(() => api.requestPac(asset), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useRequestStac(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id, "disconnections"]);

    options.onSuccess(data, variables);
  };

  return useMutation(() => api.requestStac(asset), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useSaveBars(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id]);

    options.onSuccess(data, variables);
  };

  return useMutation((bars) => api.saveBars(asset, bars), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useSaveSim(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["assets", asset.id]);

    options.onSuccess(data, variables);
  };

  return useMutation((number) => api.saveSim(asset, number), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useSaveUserName(
  asset: Asset,
  options = {
    onSuccess: (data: any, variables: any) => {},
  }
) {
  const queryClient = useQueryClient();

  const onSuccess = (data: any, variables: any) => {
    queryClient.invalidateQueries(["asset", asset.id]);

    options.onSuccess(data, variables);
  };

  return useMutation((user_name) => api.saveUsername(asset, user_name), {
    ...options,
    onSuccess: onSuccess,
  });
}

export function useSearchProducts(
  asset: Asset,
  query: ServiceQuery,
  options = {}
) {
  return useQuery(
    ["assets", asset.id, "products", query],
    () => api.paginateProducts(asset, query),
    options
  );
}

export function useSearchProductsChanges(asset: Asset, options = {}) {
  return useQuery(
    ["asset", asset.id, "changes.products"],
    () => api.productsChanges(asset),
    options
  );
}
