import React from "react";
import { useThirdPartyBilling } from "../../../hooks/customers";
import { LinearProgress, TableCell, TableRow } from "@mui/material";
import { Alert } from "@akj-dev/shared-components";
import { ReferredCreditVetWarning } from "../../../components/ReferredCreditVetWarning";

interface CreditVetStatusProps {
  accountId: string;
  companyName: string;
}

export const CreditVetStatus = ({
  accountId,
  companyName,
}: CreditVetStatusProps) => {
  const { isLoading, isError, data, error } = useThirdPartyBilling(accountId);
  const isReferred = data?.data?.as_credit_check_result === "Refer";
  const caseNumber = data?.data?.case_number;
  const hasCaseNumber = !!caseNumber;
  return (
    <>
      {isReferred && hasCaseNumber ? (
        <TableRow>
          <TableCell />
          <TableCell>
            <ReferredCreditVetWarning
              companyName={companyName}
              caseNumber={caseNumber}
            />
          </TableCell>
        </TableRow>
      ) : null}
      <TableRow>
        <TableCell>Credit check result</TableCell>
        <TableCell>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              {isError ? (
                <Alert type="error" message={error.message} />
              ) : (
                data?.data?.as_credit_check_result
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      {data?.data && (
        <>
          <TableRow>
            <TableCell>Credit check reference</TableCell>
            <TableCell>{data.data?.vf_credit_check_reference}</TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};
