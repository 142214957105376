import React, { useEffect, useState, ReactNode } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Grow,
  List,
  ListItem,
  Paper,
  Popper,
  styled,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { cardHeaderClasses } from "@mui/material/CardHeader";
import { Filter, FetchResourcesHook, UsersFilter } from "./types";
import { FilterItem } from "./FilterItem";
import MyFilters from "./MyFilters";
import { AddedFilters } from "./AddedFilters";
import MoreFilters from "./MoreFilters";
import { SxStyleSheet } from "../themes/_base";

export interface FiltersCardProps {
  fetchHook?: (usage?: string) => any;
  fetchResourcesHook: FetchResourcesHook;
  onApply?: () => any;
  myFilters?: {
    fetchHook?: (usage: string) => any;
    deleteHook?: (filter: UsersFilter) => any;
    saveHook?: (name: string) => any;
    addHook?: (filter: UsersFilter) => any;
  };
  usage?: string;
  title?: string;
  hasReset?: boolean;
  hasDownload?: boolean;
  downloadFileType?: string;
  disableDownload?: any;
  handleDownloadClick?: (selected: string) => any;
  className?: string;
  children?: ReactNode;
}

export const FiltersCard = ({
  fetchHook = () => ({ data: null, isLoading: null }),
  fetchResourcesHook,
  onApply,
  myFilters,
  usage,
  title,
  hasReset = true,
  hasDownload = false,
  downloadFileType = "",
  disableDownload = false,
  handleDownloadClick = () => null,
  className = "",
  children,
}: FiltersCardProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  // Reset filters
  const resetFilters = () => {
    const params: string[] = [];
    queryParams.forEach((value, key) => {
      // TODO: preffix check?
      params.push(key);
    });
    params.forEach((key) => {
      queryParams.delete(key);
    });
    history.replace({
      search: queryParams.toString(),
    });
    onApply?.();
  };

  const [filters, setFilters] = useState([] as any);
  const { data: filtersResponse, isLoading } = fetchHook(usage);

  // Filter toggles
  useEffect(() => {
    const queryParams: any = [];
    new URLSearchParams(location.search)?.forEach((value, key) =>
      queryParams.push({ key, value })
    );

    const output: Filter[] =
      filtersResponse?.map((f: Filter) => {
        const match = queryParams.filter((q: any) => q.key === f.id)?.[0];
        if (match) {
          return {
            ...f,
            value: match.value,
            showToggle: true,
          };
        }
        return f;
      }) || [];
    setFilters(output);
  }, [filtersResponse, location]);

  const updateFilterVisibility = (filterId: string, value: boolean) => {
    const newFilters: Filter[] = [];
    filters.forEach((filter: Filter) => {
      const newFilter: Filter = filter;
      if (filter.id === filterId) {
        newFilter.showToggle = value;
      }
      newFilters.push(newFilter);
    });
    setFilters(newFilters);
  };

  const [showLoading, setShowLoading] = useState(false);

  // Card Collapse
  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Options Button
  const [optionsOpen, setOptionsOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOptionsOpen(false);
  };

  const showHeader = !!title || !!myFilters || hasReset || hasDownload;

  return (
    // todo mui Card styles
    <Card className={className} sx={styles.ctr} elevation={2}>
      {children}
      {showHeader && (
        <CardHeader
          sx={styles.header}
          title={
            <TitleCtr>
              {Boolean(title) && <Typography variant="h3">{title}</Typography>}
              {Boolean(myFilters) && (
                <MyFilters
                  fetchHook={myFilters?.fetchHook}
                  deleteHook={myFilters?.deleteHook}
                  saveHook={myFilters?.saveHook}
                  addHook={myFilters?.addHook}
                  usage={usage}
                />
              )}
            </TitleCtr>
          }
          action={
            <>
              {hasReset && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={resetFilters}
                  data-cy="reset-filters-button"
                  sx={styles.button}
                >
                  Reset
                </Button>
              )}
              {hasDownload && (
                <>
                  <Button
                    variant="text"
                    size="small"
                    data-cy="options-button"
                    onClick={handleToggle}
                    endIcon={<MoreHorizIcon />}
                    sx={styles.button}
                    ref={anchorRef}
                  >
                    Options
                  </Button>
                  <Popper
                    open={optionsOpen}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    // disablePortal
                    placement="bottom-start"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-end"
                              ? "right top"
                              : "right bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <List>
                              <ListItem>
                                <Button
                                  variant="text"
                                  size="small"
                                  color="primary"
                                  data-cy="export-button"
                                  disabled={disableDownload}
                                  onClick={async () => {
                                    setShowLoading(true);
                                    await handleDownloadClick(downloadFileType);
                                    setShowLoading(false);
                                  }}
                                  startIcon={
                                    showLoading ? (
                                      <CircularProgress
                                        sx={styles.loadingSpinner}
                                      />
                                    ) : (
                                      <CloudDownloadIcon />
                                    )
                                  }
                                >
                                  Export result as CSV
                                </Button>
                              </ListItem>
                            </List>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
              {Boolean(myFilters) && (
                <Button
                  variant="text"
                  size="small"
                  data-cy="expand-button"
                  onClick={handleExpandClick}
                  sx={styles.button}
                  endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {expanded ? "Hide" : "Show"}
                </Button>
              )}
            </>
          }
        />
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={styles.content} data-cy="visible-filters-list">
          {isLoading && <CircularProgress color="primary" />}
          {filters.map((item: Filter) => {
            if (item.showToggle && !item.options.HIDDEN) {
              return (
                <FilterItem
                  item={item}
                  key={item.id}
                  fetchResourcesHook={fetchResourcesHook}
                />
              );
            }
            return null;
          })}
          <MoreFilters
            filters={filters}
            updateFilterVisibility={updateFilterVisibility}
          />
        </CardContent>
        <CardActions sx={styles.footer}>
          {Boolean(filters.length) && (
            <>
              <AddedFilters filters={filters} />
              {Boolean(onApply) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onApply}
                  data-cy="apply-filters-button"
                  startIcon={<Check />}
                >
                  Apply
                </Button>
              )}
            </>
          )}
        </CardActions>
      </Collapse>
    </Card>
  );
};

const getStyles = (theme: Theme): SxStyleSheet => ({
  ctr: {
    marginBottom: "24px",
  },
  loadingSpinner: {
    margin: 0,
    padding: 0,
    maxWidth: "20px",
    maxHeight: "20px",
  },
  button: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: "0.95rem",
  },
  header: {
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      [`& .${cardHeaderClasses.action}`]: {
        alignSelf: "center",
      },
    },
    [`& .${cardHeaderClasses.action}`]: {
      margin: 0,
      flex: "0 1 auto",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    alignItems: "center",
    padding: `0px ${theme.spacing(1)}`,
  },
  footer: {
    flexDirection: "column",
    alignItems: "flex-end",
    padding: `0px ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
});

const TitleCtr = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
});
