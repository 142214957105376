import React from "react";
import { Button, CircularProgress, ButtonProps } from "@mui/material";

/** Button with loading state */
export interface LoaderButtonProps extends ButtonProps {
  loading: boolean;
}

export const LoaderButton = (props: LoaderButtonProps) => {
  const { loading, children, disabled, ...otherProps } = props;
  return (
    <Button
      variant="contained"
      {...otherProps}
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size="1rem" />
        ) : (
          otherProps.startIcon
        )
      }
      disabled={loading || disabled}
    >
      {props.children}
    </Button>
  );
};
