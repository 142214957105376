import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../layout/Main";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import AssetMetaBar from "./AssetMetaBar";
import FieldTable from "./FieldTable";
import { broadbandProduct } from "../helpers/sampleData";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ActionButtons } from "./ActionButtons";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { redirectToApp } from "../helpers/redirectToApp";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  tab: {
    borderBottom: `2px solid ${theme.palette.text.disabled}`,
    fontWeight: "normal",
  },
  tabPanel: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

function createData(name: string, label: string, data: string) {
  return { name, label, data };
}

const productDetailRows = (product: any) => [
  createData("cli", "CLI/Identifier", product.cli),
  createData("name", "Product Name", product.name),
  createData("type", "Type", product.type),
  createData("careLevel", "Care Level", product.careLevel),
];

const broadbandDetailRows = (broadband: any) => [
  createData("username", "Username", broadband.username),
  createData("password", "Password", broadband.password),
  createData("iPv4", "IPV4", broadband.iPv4),
  createData("iPv4Type", "IPV4 Type", broadband.iPv4Type),
];

const siteContactDetailRows = (siteContact: any) => [
  createData("siteAddress", "Site Address", siteContact.siteAddress),
  createData("postcode", "Postcode", siteContact.postcode),
  createData("name", "Name", siteContact.name),
  createData("email", "Email", siteContact.email),
  createData("phoneNumber", "Phone Number", siteContact.phoneNumber),
];

const contractDetailRows = (contract: any) => [
  createData(
    "contractSignedDate",
    "Contract Signed Date",
    contract.contractSignedDate
  ),
  createData("contactTerm", "Contract Term", contract.contractTerm),
  createData("contractEndDate", "Contract End Date", contract.contractEndDate),
  createData("billingStatus", "Billing Status", contract.billingStatus),
  createData(
    "productCeasedDate",
    "Product Ceased Date",
    contract.productCeasedDate
  ),
  createData("costCentre1", "Cost Centre 1", contract.costCentre1),
  createData("costCentre2", "Cost Centre 2", contract.costCentre2),
  createData("costCentre3", "Cost Centre 3", contract.costCentre3),
];

export default function Asset() {
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);

  return (
    <Main
      title="Asset"
      leftComponent={<Chip label="Broadband" color="primary" />}
      rightComponent={
        <Box display="flex">
          <Button
            variant="outlined"
            onClick={() =>
              redirectToApp(
                getPathForCrossAppRedirect(AppSubdomains.SUPPORT, "/support")
              )
            }
            color="primary"
            startIcon={<WarningIcon />}
          >
            View Ticket History
          </Button>
          <Tooltip title="You can select this to view all of the tickets that have been raised against this asset previously.">
            <IconButton
              disableFocusRipple
              disableRipple
              style={{ backgroundColor: "transparent" }}
              size="large"
            >
              <HelpIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      }
    >
      <AssetMetaBar />
      <Grid container className={classes.grid}>
        <Grid item sm={12}>
          <TabContext value={"" + index}>
            <TabList
              onChange={(e, value) => setIndex(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Product Details" value={0} className={classes.tab} />
              <Tab
                label="Broadband Details"
                value={1}
                className={classes.tab}
              />
              <Tab
                label="Site Contact Details"
                value={2}
                className={classes.tab}
              />
              <Tab label="Contract Details" value={3} className={classes.tab} />
            </TabList>
            <TabPanel value="0" className={classes.tabPanel}>
              <FieldTable rows={productDetailRows(broadbandProduct)} />
            </TabPanel>
            <TabPanel value="1" className={classes.tabPanel}>
              <FieldTable
                rows={broadbandDetailRows(broadbandProduct.broadband)}
              />
            </TabPanel>
            <TabPanel value="2" className={classes.tabPanel}>
              <FieldTable
                rows={siteContactDetailRows(broadbandProduct.siteContact)}
              />
            </TabPanel>
            <TabPanel value="3" className={classes.tabPanel}>
              <FieldTable
                rows={contractDetailRows(broadbandProduct.contract)}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <ActionButtons
        leftLabel="Raise an Incident"
        leftTo={getPathForCrossAppRedirect(AppSubdomains.SUPPORT, "/support")}
        rightLabel="Raise a Service Request"
        rightTo={getPathForCrossAppRedirect(AppSubdomains.SUPPORT, "/support")}
      />
    </Main>
  );
}
