import React, { useRef, useState } from "react";
import { format } from "date-fns";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CreateAccountForm, IndexedTitle } from "@akj-dev/shared-components";
import { WLR3AddressSearch } from "../../../api/addressSearch";
import { useAuth } from "@akj-dev/affinity-platform";
import { LoaderButton } from "@akj-dev/shared-components";
import { ArrowForward, Add } from "@mui/icons-material";
import { useCreateCustomer } from "../../../hooks/customers";
import { CustomerSummary } from "./CustomerSummary";
import { CreateAccountFormValues } from "@akj-dev/shared-components";
import { useQueryClient } from "react-query";
import { CreateCustomerError } from "./CreateCustomerError";

interface Props {
  setQuery?: Function;
}

export const NewCustomer = ({ setQuery }: Props) => {
  const { accountSettings } = useAuth();
  const [open, setOpen] = useState(false);
  const formRef = useRef<any>(null);
  const mutation = useCreateCustomer();
  const queryClient = useQueryClient();
  const [params, setParams] = useState<CreateAccountFormValues>({});

  const handleOpen = () => {
    mutation.reset();
    setOpen(true);
  };
  const handleClose = () => {
    // See useCreateCustomer:
    queryClient.invalidateQueries("customers");
    setOpen(false);
  };

  const handleSubmit = async (params: any) => {
    params["bank_account_sortcode"] = params["bank_account_sortcode"].replace(
      /-/g,
      ""
    );
    if (params["birthdate"] !== "")
      params["birthdate"] = format(params["birthdate"], "yyyy-MM-dd");
    if (params["companyAddressDateFrom"] !== "")
      params["companyAddressDateFrom"] = format(
        params["companyAddressDateFrom"],
        "yyyy-MM-dd"
      );
    if (params["companyAddressDateFrom2"] !== "")
      params["companyAddressDateFrom2"] = format(
        params["companyAddressDateFrom2"],
        "yyyy-MM-dd"
      );
    if (params["companyAddressDateFrom3"] !== "")
      params["companyAddressDateFrom3"] = format(
        params["companyAddressDateFrom3"],
        "yyyy-MM-dd"
      );

    setParams(params);
    const outcome = await mutation.mutateAsync(params);
    if (outcome.status === "success" && setQuery) {
      setQuery({ sort: "createdAtTimestamp", page: 1, order: "desc" });
    }
  };

  const defaultValues = {
    ofcom_rid: "TIL",
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleOpen}
      >
        Create New Customer
      </Button>
      <Dialog open={open} maxWidth="md">
        <DialogTitle>Create New Customer</DialogTitle>
        <DialogContent>
          {mutation.isSuccess ? (
            <CustomerSummary
              accountId={mutation.data.data.id}
              customerReference={mutation.data.data.platform_customer_reference}
              companyName={params.name as string}
            />
          ) : (
            <>
              <Box my={2}>
                <IndexedTitle
                  title="Please enter new customer details"
                  index="1"
                />
              </Box>
              <CreateAccountForm
                onSubmit={handleSubmit}
                accountSettings={accountSettings}
                api={WLR3AddressSearch}
                ref={formRef}
                defaultValues={defaultValues}
              />
              {mutation.isError ? (
                <Box mb={1}>
                  <CreateCustomerError message={mutation.error.message} />
                </Box>
              ) : null}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {mutation.isSuccess ? (
            <Button color="primary" variant="contained" onClick={handleClose}>
              Finish
            </Button>
          ) : (
            <>
              <Button color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <LoaderButton
                type="submit"
                variant="contained"
                color="primary"
                loading={mutation.isLoading}
                size="large"
                endIcon={<ArrowForward />}
                onClick={() => {
                  formRef.current.dispatchEvent(
                    new Event("submit", { bubbles: true, cancelable: true })
                  );
                }}
              >
                Create Customer
              </LoaderButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
