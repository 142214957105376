import React, { useState } from "react";
import { Box, Chip, CircularProgress, TextField, Grid } from "@mui/material";
import { Autocomplete as ParentAutocomplete } from "@mui/material";
import Wrap from "./Wrap";

export interface AutocompleteProps {
  loading?: boolean;
  label: string;
  multiple?: boolean;
  onSearch?: (query: string) => void;
  onSelect: (selected: any) => void;
  options: any[];
  selected?: any;

  [key: string]: any;
}

export function Autocomplete(props: AutocompleteProps) {
  const [selected, setSelected] = useState<any>(
    props.multiple ? props.selected ?? [] : props.selected
  );

  const onDelete = (option: any) => {
    setSelected((selected: any) => selected.filter((s: any) => s !== option));
  };

  const getOptionLabel = (option: any) => {
    if (typeof option === "object") {
      if (typeof props.labelFrom === "function") {
        return props.labelFrom(option);
      }
      return option[props.labelFrom || "text"];
    }
    return option;
  };

  return (
    <Wrap {...props} onApply={() => props.onSelect(selected)}>
      <ParentAutocomplete
        autoComplete
        filterSelectedOptions
        fullWidth
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(selected, item) => {
          if (typeof selected === "object") {
            if (typeof props.valueFrom === "function") {
              return props.valueFrom(selected) === props.valueFrom(item);
            }

            return (
              selected[props.valueFrom || "value"] ===
              item[props.valueFrom || "value"]
            );
          }

          return selected === item;
        }}
        onInputChange={(event, value, reason) => {
          if (
            typeof props.onSearch === "function" &&
            (reason === "input" || reason === "clear")
          ) {
            props.onSearch(value);
          }
        }}
        multiple={props.multiple}
        onChange={(event, selected) => setSelected(selected)}
        options={props.options}
        value={selected}
        renderTags={() => props.multiple && null}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            fullWidth
            placeholder="Search"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {props.loading ?? false ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {props.multiple && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          {selected?.map((s: any) => (
            <Box paddingTop={0.5}>
              <Chip
                label={getOptionLabel(s)}
                onDelete={() => onDelete(s)}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  "& .MuiChip-label": {
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                    textOverflow: "clip",
                  },
                }}
              />
            </Box>
          ))}
        </Grid>
      )}
    </Wrap>
  );
}
