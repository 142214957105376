import React from "react";
import { Grid, Typography, Box, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Asset } from "../../../../../types";
import SIM from "./SIM";
import Username from "./Username";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
    overflow: "auto",
  },
}));

type Props = {
  asset: Asset;
  hasAdminBar?: boolean;
};

const Component = ({ asset, hasAdminBar }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={4}>
      <Box p={1.5}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs>
            <Typography paragraph variant="caption">
              Company Name
            </Typography>
            <Typography variant="h4">{asset.account?.name}</Typography>
          </Grid>
          <Grid item xs>
            <Typography paragraph variant="caption">
              Account Number
            </Typography>
            <Typography variant="h4">
              {asset.account?.billing_account_number}
            </Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography paragraph variant="caption">
              SIM
            </Typography>
            <SIM asset={asset} hasAdminBar={hasAdminBar} />
          </Grid>
          <Grid item xs>
            <Typography paragraph variant="caption">
              Username
            </Typography>
            <Username asset={asset} hasAdminBar={hasAdminBar} />
          </Grid>
          <Grid item xs>
            <Typography paragraph variant="caption">
              Status
            </Typography>
            <Typography variant="h4">{asset.status}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Component;
