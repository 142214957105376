import React from "react";
import {
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

export interface SearchFieldProps {
  /** The data attribute used for targeting this component in Cypress */
  "data-cy"?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: React.FormEventHandler;
  loading?: boolean;
  placeholder?: string;
  margin?: "none" | "dense";
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    width: "350px",
    "&:focus-within": {
      transition: "width 100ms",
      width: "450px",
    },
  },
  inputRoot: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.common.white,
    "& input::placeholder": {
      opacity: 1,
      color: "slategray",
    },
  },
  go: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

/** A combined search input */
export const SearchField = ({
  "data-cy": dataCy,
  value,
  onChange,
  onSubmit,
  loading,
  placeholder = "What are you looking for?",
  margin = "none",
}: SearchFieldProps) => {
  const classes = useStyles();
  // Note: label prop doesn't do the animation thing when combined with startAdornment
  // Hence placeholder used here.
  return (
    <form autoComplete="off" onSubmit={onSubmit} className={classes.form}>
      <FormControl fullWidth variant="outlined">
        {/* <InputLabel htmlFor="standard-adornment-amount">*/}
        {/*  What are you looking for?*/}
        {/* </InputLabel>*/}
        <OutlinedInput
          placeholder={placeholder}
          id="standard-adornment-amount"
          value={value}
          onChange={onChange}
          // labelWidth={172}
          className={classes.inputRoot}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          margin={margin}
        />
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.go}
        disabled={loading || !value}
      >
        <Typography variant="body1">
          {loading ? <CircularProgress color="inherit" size="1rem" /> : "Go"}
        </Typography>
      </Button>
    </form>
  );
};
