import React from "react";
import { Box } from "@mui/material";
import InputBase from "@mui/material/InputBase";

interface AddressFieldsProps {
  address: string | null;
  fields: any;
  onChange: (key: string, value: string) => void;
  isFormEditable: boolean;
}

export const AddressFields: React.FC<AddressFieldsProps> = ({
  address,
  fields,
  onChange,
  isFormEditable,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {address?.split("\n").map((value, key) => {
        return (
          <InputBase
            value={fields[key] || value}
            margin="dense"
            key={key.toString()}
            disabled={!isFormEditable}
            onChange={(event) => onChange(key.toString(), event.target.value)}
          />
        );
      })}
    </Box>
  );
};
