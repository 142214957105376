import React, { useState } from "react";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { SearchField } from "@akj-dev/shared-components";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PeopleIcon from "@mui/icons-material/People";
import { NewCustomer } from "./NewCustomer";
import Filters from "./Filters";
import Table from "./Table";
import { useCustomers } from "../../hooks/customers";
import { CSVDownload } from "../../shared/CSVDownload";
import { TableType } from "../../helpers/constants";
import { constants } from "../../helpers";
import { SortDirectionQueryParam } from "../../types";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.success.main,
  },
}));

export default function Customers() {
  const theme = useTheme();
  const classes = useStyles();
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const [query, setQuery] = useQueryParams({
    companies_ids: ArrayParam,
    postcodes: ArrayParam,
    primary_contacts_names: ArrayParam,
    statuses: ArrayParam,
    limit: withDefault(NumberParam, constants.limit.assets),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "createdAtTimestamp"),
    order: withDefault(SortDirectionQueryParam, "desc"),
  });

  const resetQuery = () => {
    let newQuery: { [key: string]: any } = {};

    for (let filter in query) {
      newQuery[filter] = undefined;
    }

    setQuery(newQuery);
  };

  const customersQuery = useCustomers(inputSearch, page, rowsPerPage, query);

  const handleChangeInputSearch = (event: any) => {
    setPage(1);
    setInputSearch(event.target.value || "");
  };

  const filterParams: any = {};
  Object.keys(query || {}).forEach((key) => {
    filterParams["customers." + key] = query[key as keyof typeof query];
  });

  return (
    <Box mt={2}>
      <Box alignItems="center" display="flex">
        <Box mr={1}>
          <Typography variant="h2" gutterBottom>
            Customers
          </Typography>
        </Box>

        <Box mr={1} mb={1.6}>
          <PeopleIcon className={classes.icon} fontSize="large" />
        </Box>

        <Box flexGrow={1} mr={1} mb={2}>
          {customersQuery.isLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Typography variant="h2">
              {customersQuery?.data?.meta?.totalCount}
            </Typography>
          )}
        </Box>

        <Box mb={1.5}>
          <NewCustomer setQuery={setQuery} />
        </Box>
      </Box>

      <Box
        border={1}
        borderColor={theme.palette.grey[200]}
        borderRadius="5px"
        boxShadow={2}
        display="flex"
        mb={2}
        p={1}
      >
        <Box width="100%">
          <SearchField
            onChange={handleChangeInputSearch}
            placeholder="Search"
            value={inputSearch}
          />
        </Box>
      </Box>

      <Filters query={query} setQuery={setQuery} resetQuery={resetQuery} />

      <Box mb={1} display="flex" justifyContent="flex-end">
        <CSVDownload
          filterParams={{
            search: inputSearch.length > 0 ? inputSearch : undefined,
            ...filterParams,
          }}
          table={TableType.Customers}
        />
      </Box>
      <Table paginator={customersQuery} query={query} setQuery={setQuery} />
    </Box>
  );
}
