import React from "react";
import { lighten, Paper, styled, Theme, useTheme } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SxStyleSheet } from "../themes/_base";

type AlertType = "error" | "warning" | "info" | "success";

export interface AlertProps {
  "data-cy"?: string;
  type: AlertType;
  message: React.ReactNode | string;
}

/** Communicate warnings, info and success messages to the user. */
export const Alert = ({ "data-cy": dataCy, type, message }: AlertProps) => {
  const theme = useTheme();
  const styles = getStyles(theme, type);

  return (
    <Paper data-cy={dataCy} elevation={0} sx={styles.paper}>
      <Icon theme={theme} type={type}>
        {type === "error" && (
          <ErrorOutlineIcon fontSize="inherit" color="inherit" />
        )}
        {type === "warning" && (
          <ReportProblemOutlinedIcon fontSize="inherit" color="inherit" />
        )}
        {type === "info" && (
          <InfoOutlinedIcon fontSize="inherit" color="inherit" />
        )}
        {type === "success" && (
          <CheckCircleOutlineIcon fontSize="inherit" color="inherit" />
        )}
      </Icon>
      <Message>{message}</Message>
    </Paper>
  );
};

interface IconProps {
  theme: Theme;
  type: AlertType;
}

const Icon = styled("div")<IconProps>(({ theme, type }) => ({
  marginRight: 12,
  padding: "7px 0",
  display: "flex",
  opacity: 0.9,
  fontSize: 22,
  color: theme.palette[type].main,
}));

const Message = styled("div")({
  padding: "8px 0",
  whiteSpace: "pre-line",
});

const getStyles = (theme: Theme, type: AlertType): SxStyleSheet => ({
  paper: {
    backgroundColor: lighten(theme.palette[type].main, 0.9),
    display: "flex",
    padding: "6px 16px",
  },
});
