import React, { useEffect, useState } from "react";
import { uniq } from "lodash";
import { add, format } from "date-fns";
import { Asset, Product, Service } from "../../../../../types";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  DataTable,
  DataTablePagination,
} from "@akj-dev/shared-components";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../../../../../layout/Loading";
import ButtonWithDropdown from "../../../../../components/ButtonWithDropdown";
import FreeSearch from "../../../../../components/FreeSearch";
import { findProductModification } from "../../../../../helpers/selectors";
import { formattedDate } from "../../../../../helpers/functions";
import { productCategories } from "../../../../../helpers/constants";
import {
  useAddProduct,
  useCancelChange,
  useChangeProduct,
  useProvisionChange,
  useRemoveProduct,
  useResetChange,
  useSearchProducts,
  useSearchProductsChanges,
} from "../../../../../hooks/assets";
import { useQueryClient } from "react-query";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import ChangeTariffWarning from "../components/ChangeTariffWarning";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
    overflow: "auto",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

type ShowServiceProps = {
  changes: [];
  locked: boolean;
  onEdit: (service: Service) => void;
  onRemove: (service: Service) => void;
  isLoading: boolean;
  service: Service;
  changesInvalidated: boolean;
  hasAdminBar: boolean;
  isTerminatedOrDisconnected: boolean;
};

const ShowService = ({
  changes,
  locked,
  onEdit,
  onRemove,
  isLoading,
  service,
  changesInvalidated,
  hasAdminBar,
  isTerminatedOrDisconnected,
}: ShowServiceProps) => {
  const replacement = findProductModification(service, changes);
  const removed = findProductModification(service, changes, "remove");
  const inFlight =
    (changes?.find((ch: any) => ch.network_code === service.soc_code) as any)
      ?.type === "remove";

  // This part is to override the disabling of edit and delete buttons as
  // between loading and checking /LastServiceChange the buttons would still
  // be available before the response is gathered
  const [disabled, setDisabled] = useState(inFlight);

  useEffect(() => {
    if (!isLoading && !changesInvalidated) setDisabled(inFlight);
  }, [changes, isLoading, changesInvalidated, inFlight]);

  const handleRemove = () => {
    setDisabled(true);
    onRemove(service);
  };

  return (
    <TableRow>
      <TableCell width={"30%"} sx={{ minWidth: 180 }}>
        {service.description}
      </TableCell>
      <TableCell width={"15%"} sx={{ minWidth: 130 }}>
        {service.soc_code}
      </TableCell>
      <TableCell width={"15%"} sx={{ minWidth: 160 }}>
        {formattedDate(service.start_date || "Not Set")}
      </TableCell>
      <TableCell width={"15%"} sx={{ minWidth: 160 }}>
        {formattedDate(
          replacement?.start_date ||
            removed?.end_date ||
            service.end_date ||
            "Not Set"
        )}
      </TableCell>
      <TableCell width={"10%"} sx={{ minWidth: 120 }}>
        &pound;{service.rate || 0}
      </TableCell>

      <TableCell width={"15%"} sx={{ minWidth: 124 }}>
        {!locked && (
          <>
            {replacement ? (
              <Chip label="Old" size="small" />
            ) : removed ? (
              <Chip label="Remove" size="small" />
            ) : (
              <React.Fragment>
                {service.is_active ? (
                  (service.can_delete ||
                    service.category !== productCategories.MobileBoltOn) && (
                    <React.Fragment>
                      {!service.is_world_wide_cap && (
                        <Button
                          color="primary"
                          onClick={() => onEdit(service)}
                          size="small"
                          startIcon={<EditIcon />}
                          variant="text"
                          disabled={
                            isLoading ||
                            disabled ||
                            hasAdminBar ||
                            isTerminatedOrDisconnected
                          }
                        >
                          Edit
                        </Button>
                      )}
                      {!service.is_voice && (
                        <Button
                          color="primary"
                          onClick={handleRemove}
                          size="small"
                          startIcon={
                            isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <DeleteIcon />
                            )
                          }
                          variant="text"
                          sx={{
                            ":not(&.Mui-disabled)": {
                              color: "red",
                            },
                          }}
                          disabled={
                            isLoading ||
                            disabled ||
                            hasAdminBar ||
                            isTerminatedOrDisconnected
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    {service.is_future_dated ? (
                      <Chip color="primary" label="Future Dated" size="small" />
                    ) : (
                      <Chip disabled label="Terminated" size="small" />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </>
        )}
      </TableCell>
      {replacement && (
        <React.Fragment>
          <TableCell>{replacement.new_description}</TableCell>
          <TableCell>
            {formattedDate(
              format(new Date(), "yyy-MM-dd 00:00:00")
              // Put back once Portal 4 will be implemented
              // replacement.start_date
            )}
          </TableCell>
          <TableCell>N/A</TableCell>
          <TableCell>&pound;{replacement.price}</TableCell>
          <TableCell>
            <Chip label="New" size="small" />
          </TableCell>
        </React.Fragment>
      )}
    </TableRow>
  );
};

type ProductCategoryProps = {
  category: string;
  changes: [];
  changes_are_locked: boolean;
  onEdit: (service: Service) => void;
  onRemove: (service: Service) => void;
  isLoading: boolean;
  services: Service[];
  changesInvalidated: boolean;
  hasAdminBar: boolean;
  isTerminatedOrDisconnected: boolean;
};

const ProductCategory = ({
  category,
  changes,
  changes_are_locked,
  onEdit,
  onRemove,
  isLoading,
  services,
  changesInvalidated,
  hasAdminBar,
  isTerminatedOrDisconnected,
}: ProductCategoryProps) => {
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{category}</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Cost</TableCell>
            {<TableCell>{!changes_are_locked && ""}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service, index) => (
            <ShowService
              changes={changes}
              key={index}
              locked={changes_are_locked}
              onEdit={onEdit}
              onRemove={onRemove}
              isLoading={isLoading}
              service={service}
              changesInvalidated={changesInvalidated}
              hasAdminBar={hasAdminBar}
              isTerminatedOrDisconnected={isTerminatedOrDisconnected}
            />
          ))}
        </TableBody>
      </Table>
      <Grid container>
        {category === productCategories.MobileBoltOn && (
          <Grid item xs={12}>
            <Box pt={1} pb={1}>
              <Alert
                type="info"
                message="Please note you cannot edit or delete a product if its cost is more than £0.00 and the start date is under 30 calendar days ago."
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

type Props = {
  asset: Asset;
  hasAdminBar: boolean;
};

const Component = ({ asset, hasAdminBar }: Props) => {
  const classes = useStyles();

  const initialState = {
    add: false,
    edit: false,

    query: null,

    date: null,
    end_date: null,
    product: null,
    service: null,
  };

  const [local, setLocal] = useState<any>(initialState);
  const [direction, setDirection] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isExpiryDateInvalid, setIsExpiryDateInvalid] = useState(false);
  const showDialog =
    local.service && local.service.category === productCategories.Mobile;

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setLocal(initialState);
  };

  const products = useSearchProducts(
    asset,
    { ...local.query, direction: direction },
    {
      enabled: !!local.query,
      cacheTime: 0,
    }
  );

  const changes = useSearchProductsChanges(asset);

  const addMutation = useAddProduct(asset);
  const changeMutation = useChangeProduct(asset);
  const removeMutation = useRemoveProduct(asset);

  const provisionMutation = useProvisionChange(asset);
  const resetMutation = useResetChange();
  const cancelMutation = useCancelChange(asset, {
    onSuccess: () => {
      provisionMutation.reset();
    },
  });

  const provisionError =
    provisionMutation.isError ||
    (provisionMutation.isSuccess && provisionMutation.data?.status === "error");

  const changes_are_locked =
    asset.is_disconnected ||
    !!parseInt(changes.data?.in_flight_change?.is_locked);

  const [showInactive, toggleInactive] = useState(false);

  const categories = uniq(
    (asset.evo_services ?? asset.third_party_billing_services)
      ?.filter((service) => (showInactive ? true : service.is_active === 1))
      .map((service) => service.category)
  );

  const handleButtonPress = () => {
    if (isExpiryDateInvalid) return;
    if (
      local.query?.service_type !== "bolt_on" &&
      !!changes.data?.in_flight_change?.changes?.length
    ) {
      resetMutation.mutateAsync(changes.data?.in_flight_change?.id).then(() => {
        if (local.add) {
          addMutation.mutate({
            data: {
              ...local,
              end_date: local.end_date
                ? format(local.end_date, "yyyy-MM-dd")
                : null,
              change_id: changes.data?.in_flight_change?.id,
            },
          });
        } else {
          changeMutation.mutate({
            data: {
              ...local,
              end_date: local.end_date
                ? format(local.end_date, "yyyy-MM-dd")
                : null,
              change_id: changes.data?.in_flight_change?.id,
            },
          });
        }
      });
    } else {
      if (local.add) {
        addMutation.mutate({
          data: {
            ...local,
            end_date: local.end_date
              ? format(local.end_date, "yyyy-MM-dd")
              : null,
            change_id: changes.data?.in_flight_change?.id,
          },
        });
      } else {
        changeMutation.mutate({
          data: {
            ...local,
            end_date: local.end_date
              ? format(local.end_date, "yyyy-MM-dd")
              : null,
            change_id: changes.data?.in_flight_change?.id,
          },
        });
      }
    }

    setLocal(initialState);
  };

  // For disabling edit & delete buttons - only update once this query is finished
  const changesInvalidated = useQueryClient().getQueryState([
    "asset",
    asset.id,
    "changes.products",
  ])?.isInvalidated;

  const items = [
    {
      id: "data",
      label: "Data",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "data",
          },
        }),
    },
    {
      id: "international",
      label: "International",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "international",
          },
        }),
    },
    {
      id: "roaming",
      label: "Roaming",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "roaming",
          },
        }),
    },
    {
      id: "voice",
      label: "Voice",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "voice",
          },
        }),
    },
    {
      id: "spend_caps",
      label: "Spend Caps",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "spend_caps",
          },
        }),
    },
    {
      id: "data_caps",
      label: "Data Caps",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "data_caps",
          },
        }),
    },
    {
      id: "other",
      label: "Other",
      onClick: () =>
        setLocal({
          add: true,
          query: {
            service_type: "bolt_on",
            bolt_on_type: "other",
          },
        }),
    },
  ].filter((item) => asset.bolt_on_available_to_add?.includes(item.id));

  const orderLink = (
    <div>
      {"There is an inflight change for this product. Order ID "}
      <a
        href={getPathForCrossAppRedirect(
          AppSubdomains.ORDERS,
          `/orders/status?view=detail&id=${changes.data?.in_flight_change?.bundle_id}`
        )}
        className={classes.link}
      >
        {`#${changes.data?.in_flight_change?.bundle_id}`}
      </a>
    </div>
  );

  return (
    <div data-cy="Product">
      {changes_are_locked && changes.data?.in_flight_change?.bundle_id && (
        <Box mb={1}>
          <Alert type="info" message={orderLink} />
        </Box>
      )}

      <Paper className={classes.paper} elevation={4}>
        <Box p={1.5}>
          <Box display="flex" pb={1}>
            <Box flexGrow={1}>
              <Typography variant="h4">Products</Typography>
            </Box>
            {!changes_are_locked && !(local.add || local.edit) && (
              <Box paddingLeft="40px">
                <ButtonWithDropdown
                  color="primary"
                  disabled={
                    changes.isLoading ||
                    addMutation.isLoading ||
                    changeMutation.isLoading ||
                    hasAdminBar ||
                    asset.status === "Disconnected" ||
                    asset.status === "Terminated"
                  }
                  items={items}
                  startIcon={
                    changes.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <AddIcon />
                    )
                  }
                  variant="contained"
                >
                  Add New
                </ButtonWithDropdown>
              </Box>
            )}
          </Box>

          {local.query?.bolt_on_type === "spend_caps" && (
            <Box pt={1} pb={2}>
              <Alert
                type="info"
                message="The Spend Cap will not be active until the next billing cycle so the MPN will be uncapped and may incur charges."
              />
            </Box>
          )}

          {local.add || local.edit ? (
            <Box pb={1} width="100%">
              <Box pb={1}>
                <FreeSearch
                  afterTyping={(value) => {
                    setLocal({
                      ...local,
                      query: {
                        ...local.query,
                        search_query: value,
                      },
                    });
                  }}
                  disabled={products.isLoading}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon style={{ marginRight: "14px" }} />
                    ),
                  }}
                  name="product"
                  value={local.query?.search_query ?? ""}
                  size="small"
                  variant="outlined"
                  wait={1000}
                />
              </Box>
              <DataTable
                columns={[
                  {
                    name: "code",
                    label: "Code",
                  },
                  {
                    name: "name",
                    label: "Product Name",
                  },
                  {
                    name: "price",
                    label: "Cost",
                    sortable: true,
                  },
                  {
                    name: "end_date",
                    label: "Expiry Date",
                  },
                ]}
                onChangeSort={(state: any) => {
                  setDirection(state.dir.toLowerCase());
                }}
                count={parseInt(products.data?.total ?? "0")}
                data={products.data?.products?.map((product: Product) => [
                  <React.Fragment>
                    <RadioGroup
                      onChange={() => {
                        setLocal({
                          ...local,
                          end_date: null,
                          product: product,
                        });
                        setIsExpiryDateInvalid(false);
                      }}
                    >
                      <FormControlLabel
                        control={<Radio checked={local.product === product} />}
                        label={
                          local.add
                            ? product.first_mobile_bolt_on_component?.soc_code
                            : product.first_mobile_component?.soc_code ??
                              product.code
                        }
                        value={
                          local.add
                            ? product.first_mobile_bolt_on_component?.soc_code
                            : product.first_mobile_component?.soc_code ??
                              product.code
                        }
                      />
                    </RadioGroup>
                  </React.Fragment>,
                  product.name,
                  <React.Fragment>
                    &pound;{product.recurring_price_without_promotions}
                  </React.Fragment>,
                  <React.Fragment>
                    {local.product === product ? (
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        label="Expiry Date"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="started_at"
                            variant="outlined"
                            fullWidth
                            size="small"
                            margin="none"
                            style={{ minWidth: "111px" }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: local.end_date ? (
                                <IconButton
                                  onClick={() =>
                                    setLocal({
                                      ...local,
                                      end_date: null,
                                    })
                                  }
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              ) : (
                                params.InputProps?.endAdornment
                              ),
                            }}
                            helperText={
                              local.add
                                ? isExpiryDateInvalid === true
                                  ? "Must be a valid date and for chargeable products must remain on for a minimum of 30 days."
                                  : "If no date selected, the bolt on will remain active until removed or until the CLI disconnects."
                                : null
                            }
                          />
                        )}
                        onChange={(date) =>
                          setLocal({
                            ...local,
                            end_date: date,
                          })
                        }
                        onError={(reason) => {
                          reason
                            ? setIsExpiryDateInvalid(true)
                            : setIsExpiryDateInvalid(false);
                        }}
                        value={local.end_date}
                        minDate={
                          parseFloat(
                            product.recurring_price_without_promotions
                          ) > 0
                            ? add(new Date(), { days: 30 })
                            : new Date()
                        }
                        disabled={
                          addMutation.isLoading ||
                          changeMutation.isLoading ||
                          local.edit
                        }
                        defaultCalendarMonth={
                          parseFloat(
                            product.recurring_price_without_promotions
                          ) > 0
                            ? add(new Date(), { days: 30 })
                            : new Date()
                        }
                        disablePast
                      />
                    ) : null}
                  </React.Fragment>,
                ])}
                loading={products.isLoading}
                onChangePage={(event: any, page: number) =>
                  setLocal({
                    ...local,
                    query: {
                      ...local.query,
                      id: local.service?.id,
                      page: page + 1,
                    },
                  })
                }
                rowsPerPage={local.query.limit || 10}
                onChangeRowsPerPage={(event: any) =>
                  setLocal({
                    ...local,
                    query: { ...local.query, limit: event.target.value },
                  })
                }
                page={(local?.query?.page ?? 1) - 1}
                pagination={DataTablePagination.Pages}
                title={
                  local.service
                    ? `Select an alternative product for ${local.service.description}`
                    : `Add new product - ${
                        items.find((i) => i.id === local.query.bolt_on_type)
                          ?.label
                      }`
                }
              />
            </Box>
          ) : (
            <Loading loading={changes.isLoading}>
              <Box mb={1}>
                {categories.map((category, index) => (
                  <ProductCategory
                    category={category}
                    changes={
                      changes.data?.in_flight_change?.mobile_change_details ??
                      []
                    }
                    changes_are_locked={changes_are_locked}
                    key={index}
                    onEdit={(service) =>
                      setLocal({
                        ...local,
                        edit: true,
                        query: {
                          ...local.query,
                          id: service.id,
                          service_type: service.service_type,
                          soc_code: service.soc_code,
                        },
                        service,
                      })
                    }
                    onRemove={(service) => removeMutation.mutate({ service })}
                    isLoading={
                      removeMutation.isLoading ||
                      changeMutation.isLoading ||
                      cancelMutation.isLoading
                    }
                    services={(
                      asset.evo_services ?? asset.third_party_billing_services
                    ).filter(
                      (service) =>
                        service.category === category &&
                        (showInactive ? true : service.is_active === 1)
                    )}
                    changesInvalidated={!!changesInvalidated}
                    hasAdminBar={hasAdminBar}
                    isTerminatedOrDisconnected={
                      asset.status === "Terminated" ||
                      asset.status === "Disconnected"
                    }
                  />
                ))}
                {(changes.data?.in_flight_change?.mobile_change_details?.filter(
                  (change: any) =>
                    change.type === "add" || change.type === "remove"
                ).length > 0 ||
                  (changes_are_locked &&
                    changes.data?.in_flight_change?.bundle_id)) && (
                  <Typography paragraph variant="h4" paddingTop="15px">
                    Summary of{" "}
                    {changes_are_locked &&
                      changes.data?.in_flight_change?.bundle_id &&
                      "inflight "}
                    changes:
                  </Typography>
                )}
                {changes.data?.in_flight_change?.mobile_change_details
                  ?.filter(
                    (change: any) =>
                      change.type === "add" || change.type === "remove"
                  )
                  .map((service: any, index: number) => (
                    <Grid container key={index} spacing={2} sx={{ pb: 2 }}>
                      <Grid item xs>
                        <Typography paragraph variant="caption">
                          {service.service_type === "bolt_on"
                            ? "MobileBoltOn"
                            : "Mobile"}
                        </Typography>
                        <Typography variant="h4">
                          {service.description}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography paragraph variant="caption">
                          Code
                        </Typography>
                        <Typography variant="h4">
                          {service.network_code}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography paragraph variant="caption">
                          Start Date
                        </Typography>
                        <Typography variant="h4">
                          {
                            service.type === "remove"
                              ? formattedDate(service.contract_start) ?? "N/A"
                              : formattedDate(
                                  format(new Date(), "yyy-MM-dd 00:00:00")
                                )
                            // Put back once Portal 4 will be implemented
                            // service.start_date
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography paragraph variant="caption">
                          End Date
                        </Typography>
                        <Typography variant="h4">
                          {local.add || local.edit ? (
                            <DatePicker
                              inputFormat="dd/MM/yyyy"
                              label="Date"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="started_at"
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  margin="none"
                                  style={{ minWidth: "111px" }}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                  }}
                                />
                              )}
                              onChange={(date) =>
                                setLocal({
                                  ...local,
                                  end_date: date,
                                })
                              }
                              value={local.end_date ?? ""}
                              disablePast
                              disabled={
                                addMutation.isLoading ||
                                changeMutation.isLoading
                              }
                            />
                          ) : (
                            <Typography variant="h4">
                              {service.type === "remove"
                                ? formattedDate(new Date().toISOString())
                                : formattedDate(service.end_date) ?? "N/A"}
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography paragraph variant="caption">
                          Cost
                        </Typography>
                        <Typography variant="h4">
                          &pound;
                          {service.price ??
                            service.recurring_price_without_promotions}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          margin="auto"
                        >
                          <Chip
                            label={service.type === "remove" ? "Delete" : "New"}
                            size="small"
                            sx={
                              service.type === "remove"
                                ? (theme) => ({
                                    color: theme.palette.error.main,
                                    borderColor: theme.palette.error.main,
                                  })
                                : (theme) => ({
                                    color: theme.palette.success.main,
                                    borderColor: theme.palette.success.main,
                                  })
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
              </Box>
            </Loading>
          )}

          <Box display="flex">
            {local.add ||
            local.edit ||
            addMutation.isLoading ||
            changeMutation.isLoading ? (
              <React.Fragment>
                <Box flexGrow={1}>
                  <Button
                    color="primary"
                    onClick={() => setLocal(initialState)}
                    disabled={addMutation.isLoading || changeMutation.isLoading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  {showDialog ? (
                    <Button
                      color="primary"
                      disabled={!local.product || changes_are_locked}
                      onClick={() => setOpenDialog(true)}
                      startIcon={
                        addMutation.isLoading || changeMutation.isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <DoneIcon />
                        )
                      }
                      variant="contained"
                    >
                      Apply
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      disabled={!local.product || changes_are_locked}
                      onClick={() => handleButtonPress()}
                      startIcon={
                        addMutation.isLoading || changeMutation.isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <DoneIcon />
                        )
                      }
                      variant="contained"
                    >
                      Apply
                    </Button>
                  )}
                  <ChangeTariffWarning
                    isDialogOpen={openDialog}
                    closeDialog={() => handleCloseDialog()}
                    closeDialogOnConfirm={() => setOpenDialog(false)}
                    local={local}
                    changes_are_locked={changes_are_locked}
                    addMutation={addMutation}
                    changeMutation={changeMutation}
                    onConfirmPress={() => handleButtonPress()}
                  />
                </Box>
              </React.Fragment>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                pt={2}
                width="100%"
              >
                <Box>
                  {asset.network.name !== "VF Direct" && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showInactive}
                          onChange={(event) =>
                            toggleInactive(event.target.checked)
                          }
                          name="show_inactive"
                          color="primary"
                        />
                      }
                      label="Show inactive products"
                    />
                  )}
                </Box>
                <Box display="flex">
                  {provisionError && (
                    <Box p={0.5}>
                      <Typography color="error" variant="caption">
                        Can't save the changes.
                      </Typography>
                    </Box>
                  )}
                  {!changes_are_locked &&
                    changes.data?.in_flight_change?.id > 0 && (
                      <Box display="flex" justifyContent="right">
                        <Box mr={1}>
                          <Button
                            color="primary"
                            disabled={
                              cancelMutation.isLoading ||
                              provisionMutation.isLoading
                            }
                            onClick={() =>
                              cancelMutation.mutate({
                                change_id: changes.data?.in_flight_change?.id,
                              })
                            }
                            startIcon={
                              cancelMutation.isLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                <CloseIcon />
                              )
                            }
                            variant="outlined"
                          >
                            Discard Changes
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="primary"
                            disabled={
                              !changes.data?.in_flight_change
                                ?.mobile_change_details?.length ||
                              provisionMutation.isLoading ||
                              provisionError ||
                              cancelMutation.isLoading
                            }
                            onClick={() =>
                              provisionMutation.mutate({
                                change_id: changes.data?.in_flight_change?.id,
                                back_date: format(
                                  new Date(),
                                  "yyy-MM-dd 00:00:00"
                                ),
                              })
                            }
                            startIcon={
                              provisionMutation.isLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                <DoneIcon />
                              )
                            }
                            variant="contained"
                          >
                            Save Changes
                          </Button>
                        </Box>
                      </Box>
                    )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default Component;
