import React from "react";
import { Asset } from "../../../../types";
import Header from "../Mobile/components/Header";
import Details from "../Mobile/components/Details";

type Props = {
  asset: Asset;
};

const Component = ({ asset }: Props) => {
  return (
    <React.Fragment>
      <Header asset={asset} />

      <Details asset={asset} />
    </React.Fragment>
  );
};

export default Component;
