import React from "react";
import { Alert } from "@akj-dev/shared-components";

interface CreateCustomerErrorProps {
  message: any;
}

/**
 * Display DC account error in human-readable form
 * This error shape is different from the others.
 * Dupe of Andrews Newton's packages/cpq/src/pages/StepAccount/containers/RequestState.tsx
 *
 * TODO: Make this into a DC component for handling a wider range of DC errors.
 * This is duped in packages/cpq/src/pages/StepAccount/components/RequestState.tsx
 */
export const CreateCustomerError = ({ message }: CreateCustomerErrorProps) => {
  if (typeof message === "string")
    return <Alert type="error" message={message} />;

  let errorList = message?._errors; //DC responses still a little bit of a mess, '_errors' gets VF direct errors that 'errors' doesn't, but doesn't work with most DC validation atm
  if (!!message?.errors && Object.values(message.errors).length) {
    errorList = [];
    Object.values(message.errors).forEach((error_array: any) => {
      errorList = errorList.concat(error_array);
    }); // 'errors' is a map of arrays of maps, flattening to make behave like '_errors' which is array of maps
  } else if (Array.isArray(message)) {
    errorList = message;
  }
  return (
    <Alert
      type="error"
      message={
        typeof message === "string" ? (
          message
        ) : (
          <div>
            Account creation failed with the following errors:
            <ul>
              {errorList?.map((error: any) =>
                !!error ? <li key={error.message}>{error.message}</li> : ""
              )}
            </ul>
          </div>
        )
      }
    />
  );
};
