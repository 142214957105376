import React, { ReactNode } from "react";
import { Button, Theme, useTheme } from "@mui/material";
import Warning from "@mui/icons-material/Warning";
import { SxStyleSheet } from "../themes/_base";

export interface DangerButtonProps {
  "data-cy"?: string;
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size?: "small" | "medium" | "large";
  disabled?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
}

export function DangerButton({
  children,
  size,
  "data-cy": dataCy,
  disabled,
  fullWidth,
}: DangerButtonProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Button
      startIcon={<Warning />}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      data-cy={dataCy}
      sx={styles.button}
    >
      {children}
    </Button>
  );
}

const getStyles = (theme: Theme): SxStyleSheet => ({
  button: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
});
