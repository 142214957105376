import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material/";
import { DataTable, DataTablePagination } from "@akj-dev/shared-components";
import { Customer } from "../../../types";
import { SearchQuery } from "../../Customers/types";

interface Props {
  paginator?: any;
  query: SearchQuery;
  setQuery: Function;
}

const viewButton = (id: number) => (
  <Button
    variant="text"
    color="primary"
    size="small"
    component={Link}
    to={`/customers/${id}`}
  >
    View
  </Button>
);

function customerMap(row: any) {
  return [
    row.name,
    row.thirdPartyBillingRef,
    row.postcode,
    row.primaryContact?.name,
    row.primaryContact?.phone?.work,
    row.status,
    viewButton(row.accountNumber),
  ];
}

const Component = ({ paginator, query, setQuery }: Props) => {
  const columns = [
    { name: "name", label: "Company Name", sortable: true },
    { name: "thirdPartyBillingRef", label: "Account Number" },
    { name: "postcode", label: "Postcode" },
    { name: "primaryContact.name", label: "Primary Contact" },
    {
      name: "primaryContact.phone.work",
      label: "Contact Number",
    },
    { name: "status", label: "Status" },
    { name: "actions", label: "Actions" },
  ];

  const getCustomersData = () =>
    paginator?.data?.customers?.results?.map((customer: Customer) =>
      customerMap(customer)
    );

  return (
    <DataTable
      title="Results"
      noDataCTA="Sorry, no customers found."
      columns={columns}
      count={paginator?.data?.meta?.totalCount ?? "0"}
      data={getCustomersData()}
      error={
        paginator.isError
          ? "There was a problem fetching customers. Please try again."
          : ""
      }
      loading={paginator.isLoading}
      onChangePage={(event: any, page: number) => setQuery({ page: page + 1 })}
      onChangeRowsPerPage={(event: any) =>
        setQuery({ limit: event.target.value })
      }
      onChangeSort={(state: any) =>
        setQuery({
          sort: columns[state.columnIndex]?.name,
          order: state.dir.toLocaleLowerCase(),
        })
      }
      pagination={DataTablePagination.Pages}
      page={query.page - 1}
      rowsPerPage={query.limit}
      rowsPerPageOptions={[5, 10, 25, 100]}
    />
  );
};

export default Component;
