import { constants } from "../helpers";

export interface Account {
  id: string;
  name: string;
  billing_account_number: string;
}

export interface Asset {
  account: Account;
  account_id: string;
  id: number;
  billing_system_pin: string;
  is_mobile: boolean;
  network: Network;
  sim_prefix: string;
  type: string;
  evo_services: Service[];
  third_party_billing_services: Service[];
  status: string;

  [key: string]: any;
}

export interface AttributeGroup {
  name: string;
  attributes: [];
}

export interface Bar {
  [key: string]: any;
}

export interface Customer {
  name: string;
  postcde: string;
  status: string;

  [key: string]: any;
}

export interface Disconnection {
  [key: string]: any;
}

export interface Discount {
  [key: string]: any;
}

export interface Network {
  id: number;
  name: string;
}

export interface PACSTAC {
  requesting: boolean;
  error: string;

  end_user_etf: number;
  etf: number;
  creation_date: string;
  expiry_date: string;
  pac_expiry_date: string;
  port_date: string;
  port_out_date: string;
  pac: string;
  request_id: string;
  mnp_id: string;
}

export interface Product {
  [key: string]: any;
}

export interface RootState {
  [key: string]: any;
}

export interface Service {
  [key: string]: any;
}

export type SortDirection = typeof constants.sortDirections[number];

export const SortDirectionQueryParam = {
  encode: (value: SortDirection) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as SortDirection,
};

export enum PACErrorCodes {
  INVALID_REQUEST = "INVALID_REQUEST",
  CANNOT_CREATE_REQUEST = "CANNOT_CREATE_REQUEST",
  ACCOUNT_NOT_FOUND = "ACCOUNT_NOT_FOUND",
  FAILED_TO_PROCESS = "FAILED_TO_PROCESS",
  NO_LIVE_CLI = "NO_LIVE_CLI",
}
