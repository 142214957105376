import React from "react";
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { getTheme } from "../helpers/brand";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const generateClassName = createGenerateClassName({ productionPrefix: "wrap" });

//@ts-ignore
//window.theme = THEME; // uncomment this for debugging only - DO NOT ACCESS THIS VARIABLE IN YOUR CODE :-)

/**
 * This is the wrapper for any app launched in standalone mode
 * i.e. Outside of the Affinity Platform
 *
 * Note the extra SCThemeProvider is so styled components can access the MUI
 * theme easily. See:
 * https://github.com/mui-org/material-ui/issues/10098#issuecomment-574162533
 *
 * @param children
 * @returns {*}
 * @constructor
 */
export function MUIProviders({ children }: React.PropsWithChildren<any>) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <StylesProvider generateClassName={generateClassName}>
          <MuiThemeProvider theme={getTheme()}>
            <SCThemeProvider theme={getTheme()}>
              <CssBaseline />
              {children}
            </SCThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}
