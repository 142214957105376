import { getJSON } from "./";

/**
 * Address search by postcode
 * TODO: Move this stuff to shared API. Wait for OpenAPI work I guess
 * Note: UPRNs aren't available for a lot of addresses on DCT10. @mike says it's a 10G database table...
 * Rob says it comes from SI.
 * BB9 5SR works.
 * All others: see live endpoint
 *
 * Basically PAF:
 * https://www.poweredbypaf.com/wp-content/uploads/2017/07/Latest-Programmers_guide_Edition-7-Version-6.pdf
 *
 * @param postcode
 * @param include_uprn - a more precise address identifier used by Virgin
 * @param account
 * @returns {Promise<*>}
 * @constructor
 */
export const WLR3AddressSearch = (
  postcode: string,
  include_uprn: boolean,
  account?: string
) =>
  getJSON("AddressSearch/WLR3AddressSearch", {
    postcode,
    include_uprn: include_uprn ? "1" : "0",
    account,
  });

export type WLR3Address = {
  organisationName: string | null;
  premisesName: string | null;
  subPremises: string | null;
  thoroughfareNumber: string | null;
  dependentThoroughfareName: string | null;
  thoroughfareName: string | null;
  doubleDependentLocality: string | null;
  locality: string | null;
  postTown: string | null;
  county: string | null;
  country: string;
  postcode: string;
  poBox: string | null;
  addressCategory: "GOLD" | "SILVER" | "BRONZE";
  exchangeGroupCode: string | null;
  addressReference: string | null;
  cssDatabaseCode: string; // enum?
  UPRNs?: UPRN[];
  listOfTechnologies: {
    AddressTechnology: string; // enum?
  };
};

export type UPRN = {
  address: string;
  uprn: string;
};

export type WLR3AddressSearchResponse = {
  messages?: string;
  addressMatches?: WLR3Address[];
  addresses?: {
    Address: WLR3Address[]; // why there are two, I'm not sure...
  };
  status: string;
};
